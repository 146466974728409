import { ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({ background, shadow }: Theme): ViewStyle => ({
  position: 'absolute',
  top: '100%',
  transform: [{ translateY: -10 }],
  right: 8,
  backgroundColor: background.cardLight,
  ...shadow.card,
  borderRadius: 8,
  paddingVertical: 8,
  width: 80,
  opacity: 1
})

export const closed = (): ViewStyle => ({
  opacity: 0
})
