import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { API } from '../types'

import { createStorage } from './storage'

const USER_STORE_VERSION = 2

export type UserStore = {
  user: API.User | null
  tokens: API.Tokens | null
  setUserResponse: (args: API.LoginResponse) => void
  setUser: (user: API.User) => void
  setTokens: (tokens: API.Tokens) => void
  updateUser: (newUser: Partial<API.User>) => void
  logout: () => void
  isNewUser: boolean
  setIsNewUser: (isNewUser: boolean) => void
}

export const useUserStore = create<UserStore>()(
  persist<UserStore>(
    (set, get) => ({
      user: null,
      tokens: null,
      setUserResponse: (args: API.LoginResponse) => {
        set({
          user: args.user,
          tokens: args.tokens,
          isNewUser: true
        })
      },
      setUser: (user: API.User) => {
        set({
          user: user
        })
      },
      setTokens: (tokens: API.Tokens) => {
        set({ tokens })
      },
      logout: () => {
        set({ tokens: null, user: null })
      },
      updateUser: (newUser: Partial<API.User>) => {
        const oldUser = get().user
        if (!oldUser) return
        set({ user: { ...oldUser, ...newUser } })
      },
      isNewUser: true,
      setIsNewUser: (isNewUser: boolean) => {
        set({ isNewUser })
      }
    }),
    {
      name: 'user-storage',
      storage: createStorage<UserStore>(),
      version: USER_STORE_VERSION
    }
  )
)
