import { Collection } from '../collection/models'
import { SchedulerTiming } from '../scheduler/types'

export const studiedToday = async (
  collection: Collection,
  timing: SchedulerTiming,
  deckIds: string[]
) => {
  const todayStats = await collection.storage.review.todaysStats(timing.previousDayAt, deckIds)
  return {
    totalReviews: todayStats?.studied_today ?? 0,
    timeTaken: todayStats?.time_taken ?? 0
  }
}
