import { Kysely } from 'kysely'
import { Config } from '../../config/models'
import { Database } from '../types'

export class ConfigStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async createIfDoesNotExist(config: Config): Promise<void> {
    await this.db.transaction().execute(async (trx) => {
      const existingConfig = await trx.selectFrom('config').selectAll().executeTakeFirst()
      if (existingConfig) {
        throw new Error('Config already exists.')
      }
      const results = await trx.insertInto('config').values(config.toDTO()).executeTakeFirst()
      if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
        throw new Error('Could not insert config.')
      }
    })
  }

  async update(config: Config): Promise<void> {
    const results = await this.db
      .updateTable('config')
      .set(config.toDTO())
      .where('id', '=', config.id)
      .executeTakeFirst()
    if (Number(results.numUpdatedRows) === 0) {
      throw new Error('Could not update card')
    }
  }

  async exists(): Promise<boolean> {
    const config = await this.db.selectFrom('config').selectAll().executeTakeFirst()
    return !!config
  }

  async get(): Promise<Config> {
    const config = await this.db.selectFrom('config').selectAll().executeTakeFirst()
    if (!config) {
      throw new Error('Config does not exist.')
    }
    return new Config(config)
  }
}
