import dayjs from 'dayjs'
import { CardFactory } from '../../card/factories'
import { Card } from '../../card/models'
import { CardQueue } from '../../card/types'
import { ReviewFactory } from '../../review/factories'
import { Rating } from '../../study-queue/types'
import { DEFAULT_STEPS } from './constants'
import { ReviewLog } from './types'

export class CardScheduler {
  card: Card
  incorrect: Card
  correct: Card

  constructor(card: Card) {
    this.card = CardFactory.clone(card)
    this.card.reps += 1
    this.incorrect = CardFactory.clone(this.card)
    this.correct = CardFactory.clone(this.card)
  }

  updateType(type: CardQueue) {
    if (type === CardQueue.New) {
      this.incorrect.type = CardQueue.Learning
      this.resetLearningSteps(this.incorrect)
      this.correct.type = CardQueue.Learning
      this.updateSteps(this.correct)
    } else if (type === CardQueue.Learning) {
      this.incorrect.type = type
      this.resetLearningSteps(this.incorrect)
      this.updateSteps(this.correct)
    } else if (type === CardQueue.Relearning) {
      this.incorrect.type = type
      this.resetRelearningSteps(this.incorrect)
      this.updateSteps(this.correct)
    } else if (type === CardQueue.Review) {
      this.incorrect.type = CardQueue.Relearning
      this.resetRelearningSteps(this.incorrect)
      this.incorrect.lapses += 1
      this.correct.type = CardQueue.Review
    }
    return this
  }

  private updateDue(card: Card, interval: number, unit: 'minutes' | 'days') {
    card.due = dayjs().add(interval, unit).unix()
  }

  private graduateCard(card: Card) {
    card.type = CardQueue.Review
    card.left = 0
  }

  private updateSteps(card: Card) {
    if (card.left > 0) {
      card.left -= 1
    }
    if (card.left <= 0) {
      this.graduateCard(card)
    }
  }

  private resetLearningSteps(card: Card) {
    card.left = DEFAULT_STEPS.learning.length
  }

  private resetRelearningSteps(card: Card) {
    card.left = DEFAULT_STEPS.relearning.length
  }

  private calculateDue(card: Card, interval: number) {
    if (card.left > 0) {
      if (card.type === CardQueue.Learning) {
        const stepInterval = DEFAULT_STEPS.learning[card.left - 1]
        this.updateDue(card, stepInterval, 'minutes')
      } else if (card.type === CardQueue.Relearning) {
        const stepInterval = DEFAULT_STEPS.relearning[card.left - 1]
        this.updateDue(card, stepInterval, 'minutes')
      }
    } else {
      this.updateDue(card, interval, 'days')
    }
  }

  schedule(correctInterval: number) {
    // If incorrect card is in learning or relearning, reset the interval
    this.incorrect.interval = 0
    // If there are steps left for the correct card, reset interval
    if (this.correct.left > 0) {
      this.correct.interval = 0
    } else {
      this.correct.interval = correctInterval
    }
    this.calculateDue(this.incorrect, correctInterval)
    this.calculateDue(this.correct, correctInterval)
    return this
  }

  hasFSRSState() {
    return (
      this.incorrect.fsrsState.difficulty &&
      this.incorrect.fsrsState.stability &&
      this.correct.fsrsState.difficulty &&
      this.correct.fsrsState.stability
    )
  }

  reviewLog(rating: Rating, timeTaken: number): ReviewLog {
    let reviewLog: ReviewLog
    switch (rating) {
      case Rating.Correct:
        reviewLog = {
          review: ReviewFactory.create({
            card_id: this.card.id,
            interval: this.card.interval,
            time_taken: timeTaken,
            type: this.card.type,
            rating,
            fsrs_difficulty: this.card.fsrsState.difficulty,
            fsrs_stability: this.card.fsrsState.stability,
            due: this.card.due,
            left: this.card.left
          }),
          card: this.correct
        }
        break
      case Rating.Incorrect:
        reviewLog = {
          review: ReviewFactory.create({
            card_id: this.card.id,
            interval: this.card.interval,
            time_taken: timeTaken,
            type: this.card.type,
            rating,
            fsrs_difficulty: this.card.fsrsState.difficulty,
            fsrs_stability: this.card.fsrsState.stability,
            due: this.card.due,
            left: this.card.left
          }),
          card: this.incorrect
        }
        break
    }
    return reviewLog
  }
}
