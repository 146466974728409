import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import {
  ActivityIndicator,
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View
} from 'react-native'
import { SvgProps } from 'react-native-svg'
import { useStyles } from '../../hooks'
import type { LoggedOutNavigationProp, LoggedOutParamsList } from '../../types'
import * as _styles from './Button.styles'

export type ButtonPropsStyles = {
  icon?: StyleProp<TextStyle>
  text?: StyleProp<TextStyle>
}

export type ButtonProps = TouchableOpacityProps & {
  children?: JSX.Element | JSX.Element[] | string | string[]
  color?: 'primary' | 'contrast' | 'error' | 'success'
  variant?: 'contained' | 'outlined' | 'icon_contained'
  size?: 'default' | 'compact' | 'large'
  to?: keyof LoggedOutParamsList
  icon?: (props: SvgProps) => JSX.Element
  loading?: boolean
  iconPosition?: 'left' | 'right'
  styles?: ButtonPropsStyles
}

const isChildrenString = (children?: ButtonProps['children']): boolean => {
  if (!children || (Array.isArray(children) && children.length === 0)) return false
  if (typeof children === 'string') return true
  if (Array.isArray(children)) {
    if ((children as string[]).every((child: ButtonProps['children']) => isChildrenString(child)))
      return true
  }
  return false
}

const getTextColorFromStyles = (styles: (StyleProp<{}> | undefined)[]): string | undefined => {
  return styles.reduce(
    (acc: string | undefined, currStyle) =>
      acc ??
      (currStyle && typeof currStyle === 'object'
        ? (currStyle as { color?: string })?.color
        : undefined),
    undefined
  )
}

const Button = ({
  children,
  color = 'primary',
  variant = 'contained',
  to,
  icon,
  loading,
  iconPosition = 'left',
  size = 'default',
  styles: propStyles,
  ...others
}: ButtonProps) => {
  const styles = useStyles(_styles)

  const navigation = useNavigation<LoggedOutNavigationProp>()
  const hasChildren = !!children || (Array.isArray(children) && children.length > 0)
  const [pressing, setPressing] = useState(false)

  const textColor = getTextColorFromStyles([
    styles[`text_${color}`],
    styles[`text_${variant}_${color}`]
  ])

  return (
    <TouchableOpacity
      {...others}
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      activeOpacity={1}
      style={[
        styles.button,
        styles[`button_${variant}`],
        styles[`button_${variant}_${color}`],
        styles[`button_size_${size}`],
        styles[`button_${variant}_${size}`],
        icon && !variant.includes('icon') && !loading && styles[`buttonWithIcon_${iconPosition}`],
        !variant.includes('icon') && !hasChildren && styles.buttonIconOnly,
        others.disabled && styles.disabled,
        others.disabled && styles[`button_${variant}_disabled`],
        others.style
      ]}
      onPress={(e) => {
        if (loading) return
        if (to) navigation.navigate(to)
        others.onPress?.(e)
      }}
    >
      {pressing && <View style={styles.pressing} />}
      {loading ? (
        <>
          <ActivityIndicator size={27} color={textColor} />
        </>
      ) : (
        <>
          {icon && iconPosition === 'left' && (
            <ButtonIcon
              icon={icon}
              iconOnly={!hasChildren}
              color={color}
              variant={variant}
              style={propStyles?.icon}
            />
          )}
          {isChildrenString(children) ? (
            <Text
              style={[
                styles.buttonText,
                styles[`text_${color}`],
                styles[`text_${variant}_${color}`],
                styles[`text_size_${size}`],
                icon && styles[`buttonTextWithIcon_${iconPosition}`],
                propStyles?.text,
                others.disabled && styles[`text_${variant}_disabled`]
              ]}
            >
              {children}
            </Text>
          ) : (
            children
          )}
          {icon && iconPosition === 'right' && (
            <ButtonIcon
              icon={icon}
              iconOnly={!hasChildren}
              color={color}
              variant={variant}
              style={propStyles?.icon}
            />
          )}
        </>
      )}
    </TouchableOpacity>
  )
}

export default Button

export type ButtonIconProps = SvgProps & {
  icon: (props: SvgProps) => JSX.Element
  iconOnly: boolean
  color: ButtonProps['color']
  variant: ButtonProps['variant']
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon: Icon,
  iconOnly,
  color,
  variant,
  ...others
}) => {
  const styles = useStyles(_styles)

  return (
    <Icon
      {...others}
      style={[
        styles.icon,
        styles[`text_${color}`],
        styles[`text_${variant}_${color}`],
        styles[`icon_${variant}_${color}`],
        styles[`icon_${variant}`],
        styles.icon_right,
        iconOnly && styles.iconOnly,
        others.style
      ]}
    />
  )
}
