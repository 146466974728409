import { Link } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, Platform, Text } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useToast } from 'react-native-toast-notifications'
import { URL } from 'react-native-url-polyfill'
import { Button, Input, OAuthList, Screen, FormLabel } from '../../components'
import { useOAuthStore } from '../../state/oauth'
import { useTheme } from '../../state/theme'
import { useUserStore } from '../../state/user'
import { api, base64Decode } from '../../util'
import { useRequest } from '../../util/api.util'

const Login = () => {
  const { fonts } = useTheme()
  const { t } = useTranslation(['login', 'common'])

  const { lastUsedProvider, setLastUsedProvider } = useOAuthStore()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [sendLoginOauth] = useRequest(api.loginOauth)
  const [sendLogin, loginRequest] = useRequest(api.login)

  const [loginOauthFetching, setLoginOauthFetching] = useState(false)

  const userStore = useUserStore()

  const toast = useToast()

  const login = async () => {
    if (!email) return toast.show(t('error.no-email'))
    if (!password) return toast.show(t('error.no-password'))
    try {
      const res = await sendLogin({
        email,
        password
      })
      userStore.setUserResponse(res.data)
    } catch (err) {
      const error = api.getApiErrorMessage(err, t('common:error.logging-in'))
      toast.show(error)
    }
  }

  const validateOauth = async (url: string) => {
    if (url.startsWith('langki://login')) {
      url = url.replace('langki://login', 'langki://app.langki.io/login')
    }
    const parsed = new URL(url)
    let path = parsed.pathname
    path = path.replace(/\/$/, '')
    if (path !== '/login') return
    if (!lastUsedProvider) return

    const error = parsed.searchParams.get('error')
    if (error) return toast.show(error)

    const code = parsed.searchParams.get('code')
    if (!code) return

    try {
      const stateB64 = parsed.searchParams.get('state')
      if (!stateB64) throw t('error.error-logging-in-oauth')
      const stateJson = base64Decode(stateB64)
      const stateObj = JSON.parse(stateJson) as { platform: string }
      if (stateObj.platform !== Platform.OS) throw new Error()
    } catch (e) {
      const msg = typeof e === 'string' ? e : t('error.error-logging-in-oauth')
      toast.show(msg)
      return
    }

    try {
      const res = await sendLoginOauth(lastUsedProvider, code)
      userStore.setUserResponse(res.data)
    } catch (err) {
      toast.show(api.getApiErrorMessage(err, t('error.error-logging-in-oauth')))
    }
    setLoginOauthFetching(false)
  }

  const onLinkCallback = async ({ url }: { url: string }) => {
    validateOauth(url)
  }

  useEffect(() => {
    const listener = Linking.addEventListener('url', onLinkCallback)
    return () => {
      listener.remove()
    }
  }, [])

  const initialOauth = async () => {
    const initialUrl = await Linking.getInitialURL()
    if (!initialUrl) return
    validateOauth(initialUrl)
  }

  useEffect(() => {
    initialOauth()
  }, [])

  return (
    <Screen requiresLoggedOut>
      <ScrollView
        contentContainerStyle={{
          paddingVertical: 60,
          paddingHorizontal: 24,
          gap: 24,
          minHeight: '100%'
        }}
      >
        <Text style={[fonts.titleRegular, { textAlign: 'center' }]}>{t('title')}</Text>
        <Text style={[fonts.caption, { marginTop: -16, textAlign: 'center' }]}>{t('caption')}</Text>
        <FormLabel label={t('common:input.email')}>
          <Input
            placeholder={t('placeholder.email', { ns: 'common' })}
            value={email}
            onChange={(e) => setEmail(e.nativeEvent.text)}
          />
        </FormLabel>
        <FormLabel label={t('common:input.password')} style={{ marginBottom: 'auto' }}>
          <Input
            placeholder={t('placeholder.password', { ns: 'common' })}
            value={password}
            onChange={(e) => setPassword(e.nativeEvent.text)}
            secureTextEntry
          />
        </FormLabel>
        <Button onPress={login} loading={loginRequest.isFetching}>
          {t('common:button.login')}
        </Button>
        <OAuthList
          onPress={(oauth) => {
            setLastUsedProvider(oauth.id)
            setLoginOauthFetching(true)
          }}
          loadingItem={loginOauthFetching ? lastUsedProvider ?? undefined : undefined}
          onCancel={() => setLoginOauthFetching(false)}
        />
        <Link
          to="/register"
          style={[fonts.textMain, { textAlign: 'center', padding: 10, marginTop: -10 }]}
        >
          {t('dont-have-account')} <Text style={{ fontWeight: 'bold' }}>{t('register')}</Text>
        </Link>
        <Link
          to="/forgot-password"
          style={[
            fonts.textMain,
            { textAlign: 'center', padding: 10, marginTop: -25, fontWeight: 'bold' }
          ]}
        >
          {t('forgot-password')}
        </Link>
      </ScrollView>
    </Screen>
  )
}

export default Login
