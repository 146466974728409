import { CombinedDeck } from '../hooks'

let idCount = 0

const getId = () => (idCount++).toString()

export const defaultCombinedDeck: CombinedDeck = {
  config_id: getId(),
  created_at: Date.now(),
  description: 'XX',
  dueCounts: {
    id: getId(),
    learning: 20,
    new: 10,
    review: 10,
    total: 40
  },
  id: getId(),
  name: 'XX',
  updated_at: 10
}
