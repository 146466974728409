import 'react-native-gesture-handler'
import 'react-native-mmkv'
import './translations'
import { Buffer } from 'buffer'
import { Platform } from 'react-native'
import { getArrayBufferForBlob } from 'react-native-blob-jsi-helper'

class NewBlob extends Blob {
  async arrayBuffer(): Promise<ArrayBuffer> {
    return getArrayBufferForBlob(this)
  }
  slice(
    start?: number | undefined,
    end?: number | undefined,
    contentType?: string | undefined
  ): Blob {
    const blob = super.slice(start, end, contentType)
    blob.arrayBuffer = async () => getArrayBufferForBlob(blob)
    return blob
  }
}

if (Platform.OS !== 'web') {
  globalThis.Blob = NewBlob
  globalThis.Buffer = Buffer
}
