import { Kysely } from 'kysely'
import { DatabaseSchema15 } from '../types'

export const upgradeNotetypesToSchema15 = async (db: Kysely<DatabaseSchema15>): Promise<void> => {
  const collection = await db.selectFrom('col').select('models').executeTakeFirst()
  if (!collection) {
    throw new Error('col table empty')
  }
  const noteTypes: Record<string, Record<string, any>> = JSON.parse(collection.models)
  const names = new Set<string>()
  for (let [ntid, ntSchema] of Object.entries(noteTypes)) {
    let noteTypeId = Number(ntid)
    if (noteTypeId === 0) {
      noteTypeId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    }
    ntSchema.name.normalize('NFC')
    ntSchema.flds.forEach((f: any) => {
      f.name = f.name.normalize('NFC')
    })
    ntSchema.flds.forEach((f: any) => {
      f.name = f.name.normalize('NFC')
    })
    ntSchema.tmpls.forEach((t: any) => {
      let name = t.name.toLowerCase()
      while (names.has(name)) {
        t.name += '+'
        name = t.name.toLowerCase()
      }
      names.add(name)
    })
    names.clear()
    ntSchema.flds.forEach((f: any) => {
      let name = f.name.toLowerCase()
      while (names.has(name)) {
        f.name += '+'
        name = f.name.toLowerCase()
      }
      names.add(name)
    })
    while (names.has(ntSchema.name.toLowerCase())) {
      ntSchema.name += '_'
    }
    names.add(ntSchema.name.toLowerCase())
    await db
      .insertInto('notetypes')
      .values({
        id: noteTypeId,
        name: ntSchema.name,
        mtime_secs: ntSchema.mod,
        usn: ntSchema.usn,
        config: Buffer.from(JSON.stringify(ntSchema))
      })
      .onConflict((oc) =>
        oc.doUpdateSet({
          id: noteTypeId,
          name: ntSchema.name,
          mtime_secs: ntSchema.mod,
          usn: ntSchema.usn,
          config: Buffer.from(JSON.stringify(ntSchema))
        })
      )
      .execute()

    for (const [ord, field] of ntSchema.flds.entries()) {
      await db
        .insertInto('fields')
        .values({
          ntid: noteTypeId,
          ord,
          name: field.name,
          config: Buffer.from(JSON.stringify(field))
        })
        .execute()
    }
    for (const [ord, template] of ntSchema.tmpls.entries()) {
      await db
        .insertInto('templates')
        .values({
          ntid: noteTypeId,
          ord,
          name: template.name,
          mtime_secs: ntSchema.mod,
          usn: 0,
          config: Buffer.from(JSON.stringify(template))
        })
        .execute()
    }
  }
  await db.updateTable('col').set({ models: '' }).execute()
}
