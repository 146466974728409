import { SvgProps } from 'react-native-svg'
import { AppleIcon, DiscordIcon, FacebookIcon, GithubIcon, GoogleIcon, SpotifyIcon } from '../svg'

export type OAuthItem = {
  id: string
  name: string
  icon: (props: SvgProps) => JSX.Element
  alwaysDisplay: boolean
}

const oauths = [
  {
    id: 'google',
    name: 'Google',
    icon: GoogleIcon,
    alwaysDisplay: true
  },
  { id: 'apple', name: 'Apple', icon: AppleIcon, alwaysDisplay: true },
  {
    id: 'discord',
    name: 'Discord',
    icon: DiscordIcon,
    alwaysDisplay: false
  },
  {
    id: 'spotify',
    name: 'Spotify',
    icon: SpotifyIcon,
    alwaysDisplay: false
  },
  {
    id: 'github',
    name: 'Github',
    icon: GithubIcon,
    alwaysDisplay: false
  },
  {
    id: 'facebook',
    name: 'Facebook',
    icon: FacebookIcon,
    alwaysDisplay: false
  }
] as const

export default oauths
