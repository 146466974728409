import { CardFactory } from '../../card/factories'
import { Card } from '../../card/models'
import { CardQueue } from '../../card/types'
import { Review } from '../../review/models'
import { Rating } from '../../study-queue/types'
import { FSRSAlgorithm } from './algorithm'
import { CardScheduler } from './scheduler'
import { ReviewLog } from './types'

export class FSRS extends FSRSAlgorithm {
  private setSeed(card: Card) {
    this.seed = card.id + card.reps
  }

  review(card: Card, rating: Rating, timeTaken: number): ReviewLog {
    const schedule = new CardScheduler(card).updateType(card.type)
    this.setSeed(card)
    let correctInterval: number
    switch (card.type) {
      case CardQueue.New:
      case CardQueue.Learning:
      case CardQueue.Relearning:
        if (!schedule.hasFSRSState()) {
          this.initState(schedule)
        }
        correctInterval = this.nextInterval(schedule.correct.fsrsState.stability!)
        schedule.schedule(correctInterval)
        break
      case CardQueue.Review:
        const interval = card.interval
        const lastDifficulty = card.fsrsState.difficulty!
        const lastStability = card.fsrsState.stability!
        const retrievability = this.forgettingCurve(interval, lastStability)
        this.nextState(schedule, lastDifficulty, lastStability, retrievability)
        correctInterval = this.nextInterval(schedule.correct.fsrsState.stability!)
        schedule.schedule(correctInterval)
        break
    }
    return schedule.reviewLog(rating, timeTaken)
  }

  undo(card: Card, review: Review) {
    const oldCard = CardFactory.createFromCardAndReview(card, review)
    return oldCard
  }
}
