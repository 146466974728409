import { AnkiTemplate } from '../../anki/template/types'
import { BaseFactory } from '../models'
import { AnkiTemplateAdapter } from './adapters'
import { Template } from './models'

export class TemplateFactory extends BaseFactory {
  static createFromAnkiDeck(data: AnkiTemplate, noteTypeIdMap: Record<number, string>): Template {
    const adapter = new AnkiTemplateAdapter(data)
    const adaptedData = adapter.adapt(noteTypeIdMap)
    return new Template(this.createBase(adaptedData))
  }
}
