import { Link } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useToast } from 'react-native-toast-notifications'
import { Button, Input, Screen, FormLabel } from '../../components'
import { useTheme } from '../../state/theme'
import { useRequest, api } from '../../util'

const ForgotPassword: React.FC = () => {
  const { fonts } = useTheme()
  const { t } = useTranslation(['forgot-password', 'common', 'register'])

  const [email, setEmail] = useState('')

  const [sendEmailRequest, emailRequest] = useRequest(api.sendPasswordResetEmail)
  const toast = useToast()

  const sendEmail = async () => {
    try {
      await sendEmailRequest(email)
      toast.show(t('email-sent'))
    } catch (err) {
      const error = api.getApiErrorMessage(err, t('common:error.sending-email'))
      toast.show(error)
    }
  }

  return (
    <Screen requiresLoggedOut>
      <ScrollView
        contentContainerStyle={{
          paddingVertical: 60,
          paddingHorizontal: 24,
          gap: 24,
          minHeight: '100%',
          justifyContent: 'center'
        }}
      >
        <Text style={[fonts.titleRegular, { textAlign: 'center' }]}>{t('title')}</Text>
        <Text style={[fonts.caption, { marginTop: -16, textAlign: 'center' }]}>{t('caption')}</Text>
        <FormLabel label={t('common:input.email')}>
          <Input
            placeholder={t('common:placeholder.email')}
            value={email}
            onChange={(e) => setEmail(e.nativeEvent.text)}
          />
        </FormLabel>
        <Button onPress={sendEmail} loading={emailRequest.isFetching}>
          {t('common:button.reset-password')}
        </Button>
        <Link
          to="/login"
          style={[fonts.textMain, { textAlign: 'center', padding: 10, marginTop: -10 }]}
        >
          {t('remember-password')}{' '}
          <Text style={{ fontWeight: 'bold' }}>{t('common:button.login')}</Text>
        </Link>
      </ScrollView>
    </Screen>
  )
}

export default ForgotPassword
