import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import oauths from '../constants/oauths'
import { createStorage } from './storage'

const OAUTH_STORE_VERSION = 1

export type OAuthStore = {
  lastUsedProvider: (typeof oauths)[number]['id'] | null
  setLastUsedProvider: (provider: (typeof oauths)[number]['id']) => void
}

export const useOAuthStore = create<OAuthStore>()(
  persist<OAuthStore>(
    (set) => ({
      lastUsedProvider: null,
      setLastUsedProvider: (provider: (typeof oauths)[number]['id']) => {
        set({ lastUsedProvider: provider })
      }
    }),
    {
      name: 'oauth-storage',
      storage: createStorage<OAuthStore>(),
      version: OAUTH_STORE_VERSION
    }
  )
)
