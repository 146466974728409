import { FieldValueFactory as LangkiFieldValueFactory } from '../../field-value/factories'
import { FieldValue } from '../../field-value/models'
import { LangkiStorage } from '../../storage/models'

export class FieldValueConverter {
  private langkiDb: LangkiStorage

  constructor(langkiDb: LangkiStorage) {
    this.langkiDb = langkiDb
  }

  async insert(fieldValuesBatch: FieldValue[]) {
    await this.langkiDb.fieldValue.createMultiple(fieldValuesBatch)
  }

  convert(
    noteId: string,
    noteTypeId: string,
    fieldValues: string[],
    noteTypeOrdMap: Record<string, Record<number, string>>
  ): FieldValue[] {
    const fieldValuesBatch = []
    for (const [index, fieldValue] of fieldValues.entries()) {
      const langkiFieldValue = LangkiFieldValueFactory.createFromAnkiDeck({
        note_id: noteId,
        field_id: noteTypeOrdMap[noteTypeId][index],
        value: fieldValue
      })
      fieldValuesBatch.push(langkiFieldValue)
    }
    return fieldValuesBatch
  }
}
