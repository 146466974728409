import { Kysely } from 'kysely'
import { CardQueue } from '../../card/types'
import { Review } from '../../review/models'
import { Database } from '../types'

export class ReviewStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async getLatestReviewForCard(
    cardId: string,
    executor: Kysely<Database> = this.db
  ): Promise<Review> {
    const row = await executor
      .selectFrom('review')
      .selectAll()
      .where('review.card_id', '=', cardId)
      .orderBy('review.created_at', 'desc')
      .limit(1)
      .executeTakeFirst()
    if (!row) {
      throw new Error('Review not found')
    }
    return new Review(row)
  }

  async getNewCardsStudiedToday(newDayStart: number, executor: Kysely<Database> = this.db) {
    const rows = executor
      .selectFrom('review')
      .select((eb) => eb.fn.countAll<number>().as('new_cards_studied_today'))
      .rightJoin('card', 'card.id', 'review.card_id')
      .select('deck_id')
      .where('review.created_at', '>=', newDayStart)
      .where('review.type', '=', CardQueue.New)
      .groupBy('deck_id')
      .execute()
    return rows
  }

  async create(review: Review, executor: Kysely<Database> = this.db): Promise<void> {
    const results = await executor.insertInto('review').values(review.toDTO()).executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert review.')
    }
  }

  async createMultiple(reviews: Review[], executor: Kysely<Database> = this.db): Promise<void> {
    const results = await executor
      .insertInto('review')
      .values(reviews.map((r) => r.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert reviews.')
    }
  }

  async delete(id: string, executor: Kysely<Database> = this.db): Promise<void> {
    const results = await executor.deleteFrom('review').where('id', '=', id).executeTakeFirst()
    if (!results.numDeletedRows || results.numDeletedRows < 1) {
      throw new Error('Could not delete review.')
    }
  }

  async todaysStats(newDayStart: number, deckIds: string[], executor: Kysely<Database> = this.db) {
    const rows = await executor
      .selectFrom('review')
      .select((eb) => eb.fn.countAll<number>().as('studied_today'))
      .select((eb) => eb.fn.sum<number>('review.time_taken').as('time_taken'))
      .where('review.created_at', '>=', newDayStart)
      .rightJoin('card', 'card.id', 'review.card_id')
      .where('card.deck_id', 'in', deckIds)
      .executeTakeFirst()
    return rows
  }
}
