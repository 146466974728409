import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { Login, Register, ForgotPassword, PasswordReset, AppIntro, Study } from '../screens'
import ImportDeckScreen from '../screens/ImportDeckScreen'
import VerifyEmail from '../screens/VerifyEmail'
import { useInitialUrl } from '../state/initialUrl'
import { useTheme } from '../state/theme'
import { useUserStore } from '../state/user'
import BackIcon from '../svg/BackIcon'
import { LoggedOutParamsList } from '../types'
import LoggedInNavigator from './LoggedIn'

const Stack = createStackNavigator<LoggedOutParamsList>()

// @refresh reset
const MainNavigator = () => {
  const { user } = useUserStore()
  const { initialUrl } = useInitialUrl()
  const theme = useTheme()

  const getInitialRouteName = () => {
    if (!user) return 'login'
    if (user.is_email_verified === 0) return 'verify-email'
    if (initialUrl?.pathname === '/verify') return 'verify-email'
    return 'user'
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        // Needs this because the headerBackImage isn't used like a component
        // just as a function that returns an element
        // eslint-disable-next-line react/no-unstable-nested-components
        headerBackImage: () => <BackIcon style={{ width: 24, height: 24, color: 'white' }} />,
        headerStyle: { backgroundColor: theme.background.card },
        headerBackTitle: ' '
      }}
      initialRouteName={getInitialRouteName()}
    >
      <Stack.Screen name="login" component={Login} />
      <Stack.Screen name="register" component={Register} />
      <Stack.Screen name="user" component={LoggedInNavigator} />
      <Stack.Screen name="study" component={Study} />
      <Stack.Screen
        options={{ headerShown: true, title: '' }}
        name="import-deck"
        component={ImportDeckScreen}
      />
      <Stack.Screen name="verify-email" component={VerifyEmail} />
      <Stack.Screen name="forgot-password" component={ForgotPassword} />
      <Stack.Screen name="password-reset" component={PasswordReset} />
      <Stack.Screen name="intro" component={AppIntro} />
    </Stack.Navigator>
  )
}

export default MainNavigator
