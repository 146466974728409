// TODO: Make thread safe with singleton pattern
import { Kysely } from 'kysely'
import { SQLiteDatabaseHandler } from '../../interfaces/sqlite/types'
import { CollectionFactory } from '../collection/factories'
import { ConfigFactory } from '../config/factories'
import { CardStorage } from './card/models'
import { CollectionStorage } from './collection/models'
import { ConfigStorage } from './config/models'
import { DeckStorage } from './deck/models'
import { FieldStorage } from './field/models'
import { FieldValueStorage } from './field-value/models'
import { NoteStorage } from './note/models'
import { NoteTypeStorage } from './note-type/models'
import { ReviewStorage } from './review/models'
import { TemplateStorage } from './template/model'
import { Database } from './types'
import { migrate, openOrCreateCollectionDb } from './utils'

let storage: Storage

export class Storage {
  private db: Kysely<Database>
  public collection: CollectionStorage
  public config: ConfigStorage
  public deck: DeckStorage
  public noteType: NoteTypeStorage
  public template: TemplateStorage
  public field: FieldStorage
  public fieldValue: FieldValueStorage
  public note: NoteStorage
  public card: CardStorage
  public review: ReviewStorage

  static async fromDbName(dbName: string, dbHandler: SQLiteDatabaseHandler): Promise<Storage> {
    const db = await openOrCreateCollectionDb(dbName, dbHandler)
    return new Storage(db)
  }

  constructor(database: Kysely<Database>) {
    this.db = database
    this.collection = new CollectionStorage(this.db, this)
    this.config = new ConfigStorage(this.db)
    this.deck = new DeckStorage(this.db)
    this.noteType = new NoteTypeStorage(this.db)
    this.template = new TemplateStorage(this.db)
    this.field = new FieldStorage(this.db)
    this.fieldValue = new FieldValueStorage(this.db)
    this.note = new NoteStorage(this.db)
    this.card = new CardStorage(this.db)
    this.review = new ReviewStorage(this.db)
  }

  async initialiseDatabase(): Promise<void> {
    await this.migrateToLatest()

    if (!(await this.collection.exists())) {
      const newCollection = CollectionFactory.createDefault(this)
      await this.collection.createIfDoesNotExist(newCollection)
    }
    if (!(await this.config.exists())) {
      const newConfig = ConfigFactory.createDefault()
      await this.config.createIfDoesNotExist(newConfig)
    }
  }

  async migrateToLatest(): Promise<void> {
    await migrate(this.db)
  }

  async close(): Promise<void> {
    await this.db.destroy()
  }

  async withTransaction<T>(operation: (trx: Kysely<Database>) => Promise<T>): Promise<T> {
    return this.db.transaction().execute(async (trx) => {
      return await operation(trx)
    })
  }
}

let gettingStoragePromise: Promise<Storage> | null = null
export const getStorageInstance = async (
  dbName: string,
  dbHandler: SQLiteDatabaseHandler,
  forceNewInstance = false
): Promise<Storage> => {
  if (storage && !forceNewInstance) return storage
  if (!storage && gettingStoragePromise && !forceNewInstance) return await gettingStoragePromise
  gettingStoragePromise = new Promise(async (resolve) => {
    if (forceNewInstance || !storage) {
      if (storage) {
        await storage.close()
      }
      storage = await Storage.fromDbName(dbName, dbHandler)
      await storage.initialiseDatabase()
    }
    resolve(storage)
  })
  storage = await gettingStoragePromise
  return storage
}

export type LangkiStorage = Storage
