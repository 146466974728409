import React from 'react'
import { View } from 'react-native'

import * as Animatable from 'react-native-animatable'
import Logo from '../../svg/Logo'
import SteamWiggle from '../../svg/SteamWiggle'

export const Brand = () => {
  const animation: Animatable.CustomAnimation = {
    easing: 'linear',
    from: {
      transform: [{ translateY: -20 }]
    },
    to: {
      transform: [{ translateY: -78 }]
    }
  }

  return (
    <View style={{ gap: 10, alignItems: 'stretch' }}>
      <View style={{ height: 70, width: 230, paddingHorizontal: 10, overflow: 'hidden' }}>
        <Animatable.View
          iterationCount={'infinite'}
          animation={animation}
          duration={1200}
          style={{ height: 80, flexDirection: 'row', justifyContent: 'space-evenly' }}
        >
          <SteamWiggle style={{ width: 30, height: 240 }} />
          <SteamWiggle style={{ width: 30, height: 240 }} />
          <SteamWiggle style={{ width: 30, height: 240 }} />
        </Animatable.View>
      </View>
      <Logo style={{ height: 200, width: 230 }} />
    </View>
  )
}

export default Brand
