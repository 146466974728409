import React from 'react'

export type UseStateRefReturn<T> = [
  value: T,
  setValue: React.Dispatch<React.SetStateAction<T>>,
  ref: React.MutableRefObject<T>
]

export const useStateRef = <T>(defaultValue: T): UseStateRefReturn<T> => {
  const [value, _setValue] = React.useState<T>(defaultValue)
  const ref = React.useRef<T>(defaultValue)

  const setValue = (setter: React.SetStateAction<T>) => {
    if (typeof setter === 'function') {
      _setValue((oldValue) => {
        const newValue = (setter as (oldValue: T) => T)(oldValue)
        ref.current = newValue
        return newValue
      })
    } else {
      ref.current = setter
      _setValue(setter)
    }
  }

  return [value, setValue, ref]
}
