import dayjs from 'dayjs'
import { AnkiCard } from '../../anki/card/types'
import { CardQueue as AnkiCardQueue } from '../../anki/card/types'
import { CardQueue as LangkiCardQueue } from '../card/types'
import { FSRSAlgorithm } from '../scheduler/fsrs/algorithm'
import { DEFAULT_FSRS_PARAMS, DEFAULT_STEPS } from '../scheduler/fsrs/constants'
import { CardQueue, CreateAnkiCardType } from './types'

export class AnkiCardAdapter {
  private ankiCard: AnkiCard

  constructor(ankiCard: AnkiCard) {
    this.ankiCard = ankiCard
  }

  private async adaptCardDue(schedulerInitDateSeconds: number): Promise<number> {
    if (this.ankiCard.type === AnkiCardQueue.Review) {
      const due = dayjs.unix(schedulerInitDateSeconds).add(this.ankiCard.due, 'days').unix()
      return due
    }
    return this.ankiCard.due
  }

  private adaptEaseFactor() {
    return this.ankiCard.factor / 1000 // TODO: safe decimals
  }

  private initFSRSState(easeFactor: number, interval: number) {
    let fsrsStability: number | null = null
    let fsrsDifficulty: number | null = null
    if (this.ankiCard.type === CardQueue.Review) {
      const algorithm = new FSRSAlgorithm(
        DEFAULT_FSRS_PARAMS.weights,
        DEFAULT_FSRS_PARAMS.retention,
        true
      )
      const fsrsState = algorithm.convertState(easeFactor, interval)
      fsrsStability = fsrsState.stability
      fsrsDifficulty = fsrsState.difficulty
    }
    return { fsrsStability, fsrsDifficulty }
  }

  private adaptSteps(type: AnkiCardQueue) {
    switch (type) {
      case AnkiCardQueue.Review:
        return 0
      case AnkiCardQueue.DayLearn:
      case AnkiCardQueue.Learn:
      case AnkiCardQueue.New:
      case AnkiCardQueue.PreviewRepeat:
      case AnkiCardQueue.Suspended:
      case AnkiCardQueue.SchedBuried:
      case AnkiCardQueue.UserBuried:
        return DEFAULT_STEPS.learning.length
    }
  }

  private adaptType(type: AnkiCardQueue) {
    switch (type) {
      case AnkiCardQueue.New:
        return LangkiCardQueue.New
      case AnkiCardQueue.Learn:
        return LangkiCardQueue.Learning
      case AnkiCardQueue.Review:
        return LangkiCardQueue.Review
      case AnkiCardQueue.DayLearn:
        return LangkiCardQueue.Learning
      case AnkiCardQueue.PreviewRepeat:
      case AnkiCardQueue.Suspended:
      case AnkiCardQueue.SchedBuried:
      case AnkiCardQueue.UserBuried:
        return LangkiCardQueue.Learning
    }
  }

  public async adapt(
    newDeckId: string,
    newCardId: string,
    schedulerInitDateSeconds: number
  ): Promise<CreateAnkiCardType> {
    const easeFactor = this.adaptEaseFactor()
    const fsrsState = this.initFSRSState(easeFactor, this.ankiCard.ivl)
    return {
      due: await this.adaptCardDue(schedulerInitDateSeconds),
      lapses: this.ankiCard.lapses,
      left: this.adaptSteps(this.ankiCard.type),
      reps: this.ankiCard.reps,
      type: this.adaptType(this.ankiCard.type),
      note_id: newCardId,
      deck_id: newDeckId,
      ordinal: this.ankiCard.ord,
      interval: this.ankiCard.ivl,
      fsrs_stability: fsrsState.fsrsStability,
      fsrs_difficulty: fsrsState.fsrsDifficulty,
      created_at: this.ankiCard.id, // Anki card ID is a unix timestamp in milliseconds
      updated_at: this.ankiCard.id
    }
  }
}
