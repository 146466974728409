import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DeckListScreen from '../screens/DeckListScreen'
import { useTheme } from '../state/theme'
import BackIcon from '../svg/BackIcon'
import { HomeNavigatorParamsList } from '../types'

const Stack = createStackNavigator<HomeNavigatorParamsList>()

// @refresh reset
const MainNavigator = () => {
  const theme = useTheme()
  const [t] = useTranslation(['deck-list'])

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        // Needs this because the headerBackImage isn't used like a component
        // just as a function that returns an element
        // eslint-disable-next-line react/no-unstable-nested-components
        headerBackImage: () => (
          <BackIcon style={{ width: 24, height: 24, color: 'white', margin: 16 }} />
        ),
        headerTitleStyle: { fontSize: 24 },
        headerStyle: { height: 52, backgroundColor: theme.background.card },
        headerBackTitle: ' '
      }}
    >
      <Stack.Screen
        name="deck-list"
        component={DeckListScreen}
        options={{ title: t('deck-list:title') }}
      />
    </Stack.Navigator>
  )
}

export default MainNavigator
