import { Platform } from 'react-native'
import { FileSystemHandler } from './types'

export const getFilesystemHandler = async (): Promise<FileSystemHandler> => {
  if (Platform.OS === 'web') {
    const { WebFileSystemHandler } = await import('./web')
    return new WebFileSystemHandler()
  } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
    const { reactNativeFs } = await import('./react-native')
    return reactNativeFs
  } else {
    const { NodeFileSystem } = await import('./node')
    return new NodeFileSystem(__dirname)
  }
}
