import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 2.646 21.167" {...props}>
    <Path
      fill="#999"
      d="M.9 0 .158.753s.375.366.746.846.684 1.106.684 1.329c0 .223-.238.513-.635.9C.556 4.217 0 4.703 0 5.48s.556 1.264.953 1.652c.397.388.635.678.635.9 0 .224-.238.512-.635.9C.556 9.319 0 9.805 0 10.583s.556 1.264.953 1.652c.397.388.635.678.635.9 0 .224-.238.512-.635.9-.397.387-.953.873-.953 1.651s.556 1.266.953 1.654c.397.388.635.676.635.899s-.313.848-.684 1.329c-.371.48-.746.846-.746.846l.742.753s.42-.41.842-.956c.422-.545.904-1.194.904-1.972s-.556-1.264-.953-1.652c-.397-.388-.635-.678-.635-.9s.238-.512.635-.9c.397-.387.953-.873.953-1.651s-.556-1.264-.953-1.652c-.397-.388-.635-.678-.635-.9 0-.223.238-.512.635-.9.397-.387.953-.873.953-1.651s-.556-1.266-.953-1.654c-.397-.387-.635-.676-.635-.899s.238-.51.635-.898.953-.876.953-1.654c0-.778-.482-1.427-.904-1.973C1.32.41.9 0 .9 0z"
    />
  </Svg>
)
export default SvgComponent
