import { AnkiTemplate } from '../../anki/template/types'
import { arrayBufferToUtf8 } from '../utils'
import { CreateAnkiTemplateType } from './types'

export class AnkiTemplateAdapter {
  private ankiTemplate: AnkiTemplate

  constructor(ankiTemplate: AnkiTemplate) {
    this.ankiTemplate = ankiTemplate
  }

  public adapt(noteTypeIdMap: Record<number, string>): CreateAnkiTemplateType {
    const config = JSON.parse(arrayBufferToUtf8(this.ankiTemplate.config))
    return {
      note_type_id: noteTypeIdMap[this.ankiTemplate.ntid],
      name: this.ankiTemplate.name,
      question_format: config.qfmt,
      answer_format: config.afmt
    }
  }
}
