import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({}: Theme): ViewStyle => ({})

export const inner = (): ViewStyle => ({
  gap: 8
})

export const emptyLabel = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 20
})
