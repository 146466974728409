import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { Button, FormLabel, Input, Screen } from '../../components'
import { useInitialUrl } from '../../state/initialUrl'
import { useTheme } from '../../state/theme'
import { useUserStore } from '../../state/user'
import type { LoggedOutNavigationProp } from '../../types'
import { api, useRequest } from '../../util'

const PasswordReset: React.FC = () => {
  const { fonts } = useTheme()
  const { t } = useTranslation(['password-reset', 'common'])
  const [newPassword, setNewPassword] = useState('')
  const [sendResetPassword, resetPasswordRequest] = useRequest(api.resetPassword)

  const { initialUrl } = useInitialUrl()
  const { user } = useUserStore()
  const navigation = useNavigation<LoggedOutNavigationProp>()
  const toast = useToast()

  useEffect(() => {
    if (!toast.show) return
    const token = initialUrl?.searchParams.get('token')
    if (!token) {
      toast.show(t('common:error.no-token'))
      return navigation.navigate(user ? 'user' : 'login')
    }
  }, [toast, initialUrl, navigation, user])

  const resetPassword = async () => {
    const token = initialUrl?.searchParams.get('token')
    if (!token) {
      return toast.show(t('common:error.no-token'))
    }
    try {
      await sendResetPassword(token, newPassword)
      toast.show(t('common:success.reset-password'))
      return navigation.navigate(user ? 'user' : 'login')
    } catch (err) {
      const error = api.getApiErrorMessage(err, t('common:error.resetting-password'))
      toast.show(error)
    }
  }

  return (
    <Screen>
      <View style={{ gap: 24, justifyContent: 'center', paddingHorizontal: 24, flex: 1 }}>
        <Text style={[fonts.titleRegular, { textAlign: 'center' }]}>{t('title')}</Text>
        <Text style={[fonts.caption, { marginTop: -16, textAlign: 'center' }]}>{t('caption')}</Text>
        <FormLabel label={t('common:input.password')}>
          <Input
            placeholder={t('common:placeholder.password')}
            value={newPassword}
            onChange={(e) => {
              const { text } = e.nativeEvent
              setNewPassword(text)
            }}
            secureTextEntry
          />
        </FormLabel>
        <Button loading={resetPasswordRequest.isFetching} onPress={resetPassword}>
          {t('common:button.reset-password')}
        </Button>
      </View>
    </Screen>
  )
}

export default PasswordReset
