import React from 'react'
import { Platform, StyleProp, TextInputProps, View, ViewStyle } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { useTheme } from '../../state/theme'

export type InputProps = {
  style?: StyleProp<ViewStyle>
  childrenPosition?: 'left' | 'right'
} & Omit<TextInputProps, 'style'>

const Input = ({ style, childrenPosition = 'right', children, ...others }: InputProps) => {
  const { colors, radius, fonts, shadow } = useTheme()

  return (
    <View
      style={[
        {
          borderRadius: radius.input,
          backgroundColor: colors.input.background,
          padding: Platform.OS === 'web' ? 0 : 16,
          height: 60,
          alignItems: 'center',
          flexDirection: 'row'
        },
        shadow.input,
        style
      ]}
    >
      {childrenPosition === 'left' && children}
      <TextInput
        {...others}
        style={[
          fonts.textMain,
          {
            borderRadius: 8,
            padding: Platform.OS === 'web' ? 16 : 0,
            fontSize: 18,
            flex: 1,
            minWidth: 0
          }
        ]}
        placeholderTextColor={colors.input.placeholder}
        autoCapitalize={others.autoCapitalize ?? 'none'}
      />
      {childrenPosition === 'right' && children}
    </View>
  )
}

export default Input
