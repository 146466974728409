import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const modal = ({}: Theme): ViewStyle => ({
  maxWidth: 250
})

export const text = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain
})

export const buttonContainer = ({}: Theme): ViewStyle => ({
  flexDirection: 'row',
  gap: 8,
  flexWrap: 'wrap'
})

export const button = ({}: Theme): ViewStyle => ({
  flex: 1
})
