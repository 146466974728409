import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import sha1 from 'js-sha1'
import prand from 'pure-rand'
import { TextDecoder } from 'text-encoding'
import { DEFAULT_ID_LENGTH } from './constants'

const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export const nanoid = (length: number): string => {
  let str = ''
  for (let i = 0; i < length; i++) {
    const randIndex = Math.floor(Math.random() * alphabet.length)
    str += alphabet[randIndex]
  }

  return str
}

dayjs.extend(utc)

export const generateUniqueId = () => nanoid(DEFAULT_ID_LENGTH)

export const numberToBoolean = (value: number): boolean => Boolean(value)

export const booleanToNumber = (value: boolean): number => (value ? 1 : 0)

export const stringToSHA1EightDigitInteger = (value: string): number => {
  const hash = sha1.hex(value)
  const first8Digits = hash.substring(0, 8)
  const intValue = parseInt(first8Digits, 16)
  return intValue
}

export const daysElapsedSinceEpochMilliseconds = (startEpoch: number, endEpoch: number): number => {
  const start = dayjs.utc(startEpoch)
  const end = dayjs.utc(endEpoch)
  const daysElapsed = Math.abs(start.diff(end, 'day'))
  return daysElapsed
}

export const arrayBufferToUtf8 = (arr: ArrayBuffer): string => {
  return new TextDecoder().decode(arr)
}

export const hashStringToNumber = (str: string): number => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char // Bitwise operations to mix up the bits
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export class RandomGenerator {
  private generator: prand.RandomGenerator
  constructor(seed: string | undefined) {
    let seedNum: number

    if (seed) seedNum = hashStringToNumber(seed)
    else seedNum = Date.now() ^ (Math.random() * 0x100000000)

    this.generator = prand.xoroshiro128plus(seedNum)
  }
  public generate(): number {
    const [num, generator] = this.generator.next()
    this.generator = generator
    return num
  }
}
