import dayjs from 'dayjs'
import { Kysely } from 'kysely'
import { Card } from '../../card/models'
import { CardQueue } from '../../card/types'
import { BaseStorage } from '../base/models'
import { Database } from '../types'
import { CardSortFunction } from './types'

export class CardStorage extends BaseStorage {
  async create(card: Card, executor: Kysely<Database> = this.db): Promise<void> {
    const results = await executor.insertInto('card').values(card.toDTO()).executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert card.')
    }
  }

  async createMultiple(cards: Card[], executor: Kysely<Database> = this.db): Promise<void> {
    const results = await executor
      .insertInto('card')
      .values(cards.map((c) => c.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert cards.')
    }
  }

  async get(id: string, executor: Kysely<Database> = this.db): Promise<Card> {
    const row = await executor
      .selectFrom('card')
      .selectAll()
      .where('card.id', '=', id)
      .executeTakeFirstOrThrow()
    if (!row) {
      throw new Error('Card not found')
    }
    return new Card(row)
  }

  async getDueCardsForDecks(
    type: CardQueue[] | CardQueue,
    cutoff: number,
    deckId: string[],
    sort: CardSortFunction,
    executor: Kysely<Database> = this.db
  ): Promise<Card[]> {
    let query = executor.selectFrom('card').selectAll()
    if (Array.isArray(type)) {
      query = query.where('card.type', 'in', type)
    } else {
      query = query.where('card.type', '=', type)
    }
    query = query.where('card.due', '<=', cutoff).where('card.deck_id', 'in', deckId)
    const sortedQuery = sort(query)
    const rows = await sortedQuery.execute()
    const cards = rows.map((row) => new Card(row))
    return cards
  }

  async getAllCardsInDeck(deckId: string, executor: Kysely<Database> = this.db): Promise<Card[]> {
    const rows = await executor
      .selectFrom('card')
      .selectAll()
      .where('card.deck_id', '=', deckId)
      .execute()
    const cards = rows.map((row) => new Card(row))
    return cards
  }

  async getCountPerDay(executor: Kysely<Database> = this.db) {
    const rows = await executor
      .selectFrom('card')
      .select(['card.due', this.db.fn.countAll().as('count')])
      .where('card.type', '=', CardQueue.Review)
      .groupBy('card.due')
      .execute()
    return rows
  }

  async update(card: Card, executor: Kysely<Database> = this.db): Promise<void> {
    card.updatedAt = dayjs().valueOf()
    const rows = await executor
      .updateTable('card')
      .set(card.toDTO())
      .where('card.id', '=', card.id)
      .executeTakeFirstOrThrow()
    if (Number(rows.numUpdatedRows) === 0) {
      throw new Error('Could not update card')
    }
  }
}
