import {
  Kysely,
  QueryCompiler,
  DatabaseIntrospector,
  Dialect,
  DialectAdapter,
  Driver
} from 'kysely'
import { SqliteAdapter } from './adapter'
import { SqliteDialectConfig } from './dialect-config'
import { SqliteDriver } from './driver'
import { SqliteIntrospector } from './introspector'
import { SqliteQueryCompiler } from './query-compiler'

export class SqliteDialect implements Dialect {
  readonly #config: SqliteDialectConfig

  constructor(config: SqliteDialectConfig) {
    this.#config = Object.freeze({ ...config })
  }

  createDriver(): Driver {
    return new SqliteDriver(this.#config)
  }

  createQueryCompiler(): QueryCompiler {
    return new SqliteQueryCompiler()
  }

  createAdapter(): DialectAdapter {
    return new SqliteAdapter()
  }

  createIntrospector(db: Kysely<any>): DatabaseIntrospector {
    return new SqliteIntrospector(db)
  }
}
