import { Kysely, sql } from 'kysely'
import { upgradeConfigToSchema14 } from '../config/upgrades'
import { upgradeDecksToSchema15 } from '../deck/upgrades'
import {
  upgradeDeckConfigToSchema15,
  upgradeDeckConfigToSchema14,
  upgradeDeckConfigToSchema16
} from '../deck-config/upgrades'
import { upgradeNotetypesToSchema15 } from '../note-type/upgrades'
import { upgradeTagsToSchema14 } from '../tag/upgrades'

// Important to use any as per docs
// https://www.kysely.dev/docs/migrations
export async function up(db: Kysely<any>) {
  const schemaVersion = await db.selectFrom('col').select('ver').executeTakeFirst()
  if (!schemaVersion) {
    throw new Error('Issue with Anki export: schema version missing')
  }
  if (schemaVersion.ver < 14) {
    await db.schema
      .createTable('deck_config')
      .addColumn('id', 'integer', (col) => col.primaryKey())
      .addColumn('name', 'text', (col) => col.notNull())
      .addColumn('mtime_secs', 'integer', (col) => col.notNull())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addColumn('config', 'blob', (col) => col.notNull())
      .execute()

    await db.schema
      .createTable('config')
      .addColumn('key', 'text', (col) => col.primaryKey())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addColumn('mtime_secs', 'integer', (col) => col.notNull())
      .addColumn('val', 'blob', (col) => col.notNull())
      .modifyEnd(sql`without rowid`)
      .execute()

    await db.schema
      .createTable('tags')
      .addColumn('tag', 'text', (col) => col.notNull().primaryKey())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .modifyEnd(sql`without rowid`)
      .execute()

    await db.updateTable('col').set({ ver: 14 }).execute()
    await upgradeDeckConfigToSchema14(db)
    await upgradeTagsToSchema14(db)
    await upgradeConfigToSchema14(db)
  }
  if (schemaVersion.ver < 15) {
    await db.schema
      .createTable('fields')
      .addColumn('ntid', 'integer', (col) => col.notNull())
      .addColumn('ord', 'integer', (col) => col.notNull())
      .addColumn('name', 'text', (col) => col.notNull())
      .addColumn('config', 'blob', (col) => col.notNull())
      .addPrimaryKeyConstraint('pkey_ntid_ord', ['ntid', 'ord'])
      .addUniqueConstraint('idx_fields_name_ntid', ['name', 'ntid'])
      .modifyEnd(sql`without rowid`)
      .execute()

    await db.schema
      .createTable('templates')
      .addColumn('ntid', 'integer', (col) => col.notNull())
      .addColumn('ord', 'integer', (col) => col.notNull())
      .addColumn('name', 'text', (col) => col.notNull())
      .addColumn('mtime_secs', 'integer', (col) => col.notNull())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addColumn('config', 'blob', (col) => col.notNull())
      .addPrimaryKeyConstraint('pkey_ntid_ord', ['ntid', 'ord'])
      .addUniqueConstraint('idx_templates_name_ntid', ['name', 'ntid'])
      .modifyEnd(sql`without rowid`)
      .execute()

    await db.schema.createIndex('idx_templates_usn').on('templates').column('usn').execute()

    await db.schema
      .createTable('notetypes')
      .addColumn('id', 'integer', (col) => col.notNull().primaryKey())
      .addColumn('name', 'text', (col) => col.notNull())
      .addColumn('mtime_secs', 'integer', (col) => col.notNull())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addColumn('config', 'blob', (col) => col.notNull())
      .addUniqueConstraint('idx_notetypes_name', ['name'])
      .execute()

    await db.schema.createIndex('idx_notetypes_usn').on('notetypes').column('usn').execute()

    await db.schema
      .createTable('decks')
      .addColumn('id', 'integer', (col) => col.notNull().primaryKey())
      .addColumn('name', 'text', (col) => col.notNull())
      .addColumn('mtime_secs', 'integer', (col) => col.notNull())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addColumn('common', 'blob', (col) => col.notNull())
      .addColumn('kind', 'blob', (col) => col.notNull())
      .addUniqueConstraint('idx_decks_name', ['name'])
      .execute()

    await db.schema.createIndex('idx_notes_mid').on('notes').column('mid').execute()
    await db.schema
      .createIndex('idx_cards_odid')
      .on('cards')
      .column('odid')
      .where('odid', '!=', 0)
      .execute()

    await db.updateTable('col').set({ ver: 15 }).execute()
    await upgradeNotetypesToSchema15(db)
    await upgradeDecksToSchema15(db)
    await upgradeDeckConfigToSchema15(db)
  }
  if (schemaVersion.ver < 16) {
    await upgradeDeckConfigToSchema16(db)
    await db.updateTable('col').set({ ver: 16 }).execute()
  }
  if (schemaVersion.ver < 17) {
    const oldTags = await db.selectFrom('tags').select('tag').execute()
    await db.schema.dropTable('tags').execute()
    await db.schema
      .createTable('tags')
      .addColumn('tag', 'text', (col) => col.notNull().primaryKey())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addColumn('collapsed', 'boolean', (col) => col.notNull())
      .addColumn('config', 'blob')
      .modifyEnd(sql`without rowid`)
      .execute()
    if (oldTags.length > 0) {
      await db
        .insertInto('tags')
        .values(
          oldTags.map((tag) => ({
            tag: tag.tag,
            usn: 0,
            collapsed: false
          }))
        )
        .execute()
    }
    await db.updateTable('col').set({ ver: 17 }).execute()
  }
  if (schemaVersion.ver < 18) {
    await db.schema.alterTable('graves').renameTo('graves_old').execute()
    await db.schema
      .createTable('graves')
      .addColumn('oid', 'integer', (col) => col.notNull())
      .addColumn('type', 'integer', (col) => col.notNull())
      .addColumn('usn', 'integer', (col) => col.notNull())
      .addPrimaryKeyConstraint('pkey_oid_type', ['oid', 'type'])
      .modifyEnd(sql`without rowid`)
      .execute()

    const query = sql`
      INSERT OR IGNORE INTO graves (oid, type, usn)
      SELECT oid, type, usn FROM graves_old;
    `.compile(db)
    await db.executeQuery(query)

    await db.schema.dropTable('graves_old').execute()
    await db.schema.createIndex('idx_graves_pending').on('graves').column('usn').execute()
    await db.updateTable('col').set({ ver: 18 }).execute()
  }
}
