import { FolderReadResItem } from '../interfaces/filesystem/types'
import { ANKI_COLLECTION_FILE_NAMES } from './constants'

export const getAnkiCollectionPath = async (files: FolderReadResItem[]): Promise<string> => {
  for (const collectionFileName of ANKI_COLLECTION_FILE_NAMES) {
    const collectionEntry = files.find((file) => file.name === collectionFileName)
    if (collectionEntry) return collectionEntry.name
  }
  throw new Error('Could not find collection in Anki package')
}
