// Explanation https://github.com/open-spaced-repetition/fsrs4anki/wiki/The-Algorithm
import { RandomGenerator } from '../../utils'
import { CardScheduler } from './scheduler'
import { CompatabilityRating, FSRSState } from './types'

export class FSRSAlgorithm {
  enableFuzz: boolean = true
  weights: number[]
  retention: number
  maxInterval: number = 36500
  seed?: string
  private readonly DECAY = -0.5
  private readonly FACTOR = 0.9 ** (1 / this.DECAY) - 1

  constructor(weights: number[], retention: number, enableFuzz: boolean = true) {
    this.weights = weights
    this.retention = retention
    this.enableFuzz = enableFuzz
  }

  applyFuzz(interval: number) {
    if (!this.enableFuzz || interval < 2.5) return interval
    const generator = new RandomGenerator(this.seed)
    const fuzzFactor = generator.generate()
    interval = Math.round(interval)
    const minInterval = Math.max(2, Math.round(interval * 0.95 - 1))
    const maxInterval = Math.round(interval * 1.05 + 1)
    return Math.floor(fuzzFactor * (maxInterval - minInterval + 1) + minInterval)
  }

  convertState = (ease: number, interval: number) => {
    const oldStability = +Math.max(interval, 0.1).toFixed(2)
    const oldDifficulty = this.constrainDifficulty(
      11 -
        (ease - 1) /
          (Math.exp(this.weights[8]) *
            Math.pow(oldStability, -this.weights[9]) *
            (Math.exp(0.1 * this.weights[10]) - 1))
    )
    return {
      stability: oldStability,
      difficulty: oldDifficulty
    } as FSRSState
  }

  initState = (scheduler: CardScheduler) => {
    scheduler.incorrect.fsrsState.difficulty = this.initDifficulty(CompatabilityRating.Incorrect)
    scheduler.incorrect.fsrsState.stability = this.initStability(CompatabilityRating.Incorrect)
    scheduler.correct.fsrsState.difficulty = this.initDifficulty(CompatabilityRating.Correct)
    scheduler.correct.fsrsState.stability = this.initStability(CompatabilityRating.Correct)
  }

  nextState = (
    scheduler: CardScheduler,
    lastDifficulty: number,
    lastStability: number,
    retrievability: number
  ) => {
    scheduler.incorrect.fsrsState.difficulty = this.nextDifficulty(
      lastDifficulty,
      CompatabilityRating.Incorrect
    )
    scheduler.incorrect.fsrsState.stability = this.nextForgetStability(
      lastDifficulty,
      lastStability,
      retrievability
    )
    scheduler.correct.fsrsState.difficulty = this.nextDifficulty(
      lastDifficulty,
      CompatabilityRating.Correct
    )
    scheduler.correct.fsrsState.stability = this.nextRecallStability(
      lastDifficulty,
      lastStability,
      retrievability
    )
  }

  constrainDifficulty = (difficulty: number) => {
    return Math.min(Math.max(+difficulty.toFixed(2), 1), 10)
  }

  initDifficulty = (rating: CompatabilityRating) => {
    return +this.constrainDifficulty(this.weights[4] - this.weights[5] * (rating - 3)).toFixed(2)
  }

  initStability = (rating: CompatabilityRating) => {
    return +Math.max(this.weights[rating - 1], 0.1).toFixed(2)
  }

  nextInterval(stability: number) {
    const newInterval = this.applyFuzz(
      (stability / this.FACTOR) * (Math.pow(this.retention, 1 / this.DECAY) - 1)
    )
    return Math.min(Math.max(Math.round(newInterval), 1), this.maxInterval)
  }

  forgettingCurve(interval: number, stability: number) {
    return Math.pow(1 + (this.FACTOR * interval) / stability, this.DECAY)
  }

  nextDifficulty(difficulty: number, rating: CompatabilityRating) {
    let nextDifficulty = difficulty - this.weights[6] * (rating - 3)
    return this.constrainDifficulty(this.meanReversion(this.weights[4], nextDifficulty))
  }

  meanReversion(init: number, current: number) {
    return this.weights[7] * init + (1 - this.weights[7]) * current
  }

  nextForgetStability(difficulty: number, stability: number, retrievability: number) {
    return +Math.min(
      this.weights[11] *
        Math.pow(difficulty, -this.weights[12]) *
        (Math.pow(stability + 1, this.weights[13]) - 1) *
        Math.exp((1 - retrievability) * this.weights[14]),
      stability
    ).toFixed(2)
  }

  nextRecallStability(difficulty: number, stability: number, retrievability: number) {
    return +(
      stability *
      (1 +
        Math.exp(this.weights[8]) *
          (11 - difficulty) *
          Math.pow(stability, -this.weights[9]) *
          (Math.exp((1 - retrievability) * this.weights[10]) - 1))
    ).toFixed(2)
  }
}
