import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { APP_API_BASE_URL } from 'env'
import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import oauths from '../constants/oauths'
import { useStateRef } from '../hooks'
import { useUserStore } from '../state/user'
import { API } from '../types'

export type Method = 'get' | 'post' | 'delete' | 'put' | 'patch'

export type RequestComponentConfig = {
  onRefreshTokens: (tokens: API.Tokens) => void
  refreshToken: API.Token | null
  authToken: API.Token | null
}

let tokenRefreshPromise: Promise<AxiosResponse<API.Tokens>> | null = null

export const apiFetch = async <T>(
  method: Method,
  url: string,
  config: AxiosRequestConfig,
  componentConfig: RequestComponentConfig,
  canRefreshTokens = true
): Promise<AxiosResponse<T>> => {
  let token = componentConfig.authToken
  if (tokenRefreshPromise) {
    const tokenRes = await tokenRefreshPromise.catch(() => {})
    if (tokenRes) token = tokenRes?.data.access
  }

  const newConfig: AxiosRequestConfig = {
    ...(config || {}),
    headers: {
      ...config?.headers,
      ...(token
        ? {
            Authorization: 'Bearer ' + token.token
          }
        : {})
    }
  }

  try {
    const res = await axios.request({
      ...newConfig,
      url: APP_API_BASE_URL + url,
      method
    })

    return res
  } catch (err: unknown) {
    const axiosError = err as AxiosError
    const error = getApiError(axiosError)
    if (!canRefreshTokens) throw err
    if (error.code !== 401) throw err
    if (error.message.toLowerCase() !== 'please authenticate') throw err
    if (!componentConfig.refreshToken) throw err
    if (!tokenRefreshPromise)
      tokenRefreshPromise = refreshTokens(componentConfig.refreshToken.token, componentConfig)
    let refreshErr: unknown = null
    const tokensRes = await tokenRefreshPromise.catch((newErr) => {
      refreshErr = newErr
      tokenRefreshPromise = null
    })
    if (!tokensRes) throw refreshErr
    tokenRefreshPromise = null
    componentConfig.onRefreshTokens(tokensRes.data)
    return apiFetch(
      method,
      url,
      config,
      { ...componentConfig, authToken: tokensRes.data.access },
      false
    )
  }
}

export type RemoveTupleLabels<T extends any[]> = T extends [infer A]
  ? [A]
  : T extends [infer A, ...infer Rest]
  ? [A, ...RemoveTupleLabels<Rest>]
  : []

export type GetRequestArgs<Args extends [...unknown[], RequestComponentConfig]> = Args extends [
  ...infer RequestArgs,
  RequestComponentConfig
]
  ? RequestArgs
  : never

export type UseRequestReturn<Args extends [...unknown[], RequestComponentConfig], Res> = [
  (...args: GetRequestArgs<Args>) => Promise<AxiosResponse<Res>>,
  {
    data: Res | null
    requestData: GetRequestArgs<Args> | null
    isFetching: boolean
    isInitialized: boolean
    isSuccess: boolean
    isError: boolean
    response: AxiosResponse<Res> | null
    error: API.Error | null
    requestedAt: number | null
  }
]

export const useRequestConfig = (): RequestComponentConfig => {
  const userStore = useUserStore()

  return {
    onRefreshTokens: (tokens) => {
      userStore.setTokens(tokens)
    },
    refreshToken: userStore.tokens?.refresh ?? null,
    authToken: userStore.tokens?.access ?? null
  }
}

export const useRequest = <Args extends [...unknown[], RequestComponentConfig], Res>(
  requestFunc: (...params: Args) => Promise<AxiosResponse<Res>>
): UseRequestReturn<Args, Res> => {
  const [mounted, setMounted] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [data, setData] = useState<Res | null>(null)
  const [response, setResponse] = useState<AxiosResponse<Res> | null>(null)
  const [error, setError] = useState<API.Error | null>(null)
  const [requestData, setRequestData] = useState<GetRequestArgs<Args> | null>(null)
  const [requestedAt, setRequestedAt, requestedAtRef] = useStateRef<number | null>(null)

  const requestConfig = useRequestConfig()

  const request = async (...args: GetRequestArgs<Args>): Promise<AxiosResponse<Res>> => {
    const newRequestedAt = Date.now()
    try {
      setIsFetching(true)
      setIsSuccess(false)
      setIsError(false)
      setIsInitialized(true)
      setRequestData(args)
      setRequestedAt(newRequestedAt)
      const res = await requestFunc(...([...args, requestConfig] as unknown as Args))
      if (requestedAtRef.current !== newRequestedAt || !mounted) return res
      setResponse(res)
      setIsSuccess(true)
      setIsError(false)
      setIsFetching(false)
      setData(res.data)
      return res
    } catch (err) {
      if (!mounted) throw err
      if (requestedAtRef.current !== newRequestedAt) throw err
      setResponse(null)
      setIsSuccess(false)
      setIsError(true)
      setIsFetching(false)
      setData(null)
      setError(getApiError(err))
      throw err
    }
  }

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return [
    request,
    {
      isError,
      isSuccess,
      data,
      error,
      isFetching,
      isInitialized,
      response,
      requestData,
      requestedAt
    }
  ]
}

export const login = (args: API.LoginArgs, componentConfig: RequestComponentConfig) => {
  return apiFetch<API.LoginResponse>(
    'post',
    '/auth/login',
    {
      data: args
    },
    componentConfig
  )
}

export const loginOauth = (
  provider: (typeof oauths)[number]['id'],
  code: string,
  componentConfig: RequestComponentConfig
) => {
  return apiFetch<API.LoginResponse>(
    'post',
    `/auth/${provider}/callback`,
    {
      data: { code, platform: Platform.OS }
    },
    componentConfig
  )
}

export const register = (args: API.RegisterArgs, componentConfig: RequestComponentConfig) => {
  return apiFetch<API.LoginResponse>(
    'post',
    `/auth/register`,
    {
      data: args
    },
    componentConfig
  )
}

export const refreshTokens = (refreshToken: string, componentConfig: RequestComponentConfig) => {
  return apiFetch<API.Tokens>(
    'post',
    '/auth/refresh-tokens',
    {
      data: { refresh_token: refreshToken }
    },
    componentConfig,
    false
  )
}

export const verifyEmail = (token: string, componentConfig: RequestComponentConfig) => {
  return apiFetch<null>('post', '/auth/verify-email', { params: { token } }, componentConfig)
}

export const sendVerificationEmail = (componentConfig: RequestComponentConfig) => {
  return apiFetch<null>('post', '/auth/send-verification-email', {}, componentConfig)
}

export const getUser = (userId: number, componentConfig: RequestComponentConfig) => {
  return apiFetch<API.User>('get', '/users/' + userId, {}, componentConfig)
}

export const sendPasswordResetEmail = (email: string, componentConfig: RequestComponentConfig) => {
  return apiFetch<null>('post', '/auth/forgot-password', { data: { email } }, componentConfig)
}

export const resetPassword = (
  token: string,
  password: string,
  componentConfig: RequestComponentConfig
) => {
  return apiFetch<null>(
    'post',
    '/auth/reset-password',
    { data: { password }, params: { token } },
    componentConfig
  )
}

export const validateOneTimeCode = (code: string, componentConfig: RequestComponentConfig) => {
  return apiFetch<API.LoginResponse>('post', '/auth/validate', { data: { code } }, componentConfig)
}

export const getApiError = (err: unknown): API.Error => {
  const apiErr = (err as AxiosError<API.Error>)?.response?.data
  return (
    apiErr ?? {
      code: 500,
      message: 'Internal server error'
    }
  )
}

export const getApiErrorMessage = (e: unknown, defaultMsg?: string) => {
  if (e instanceof AxiosError) {
    e = getApiError(e)
  }
  if (typeof e === 'string') return e
  if (e && typeof e === 'object') {
    if ('message' in e && typeof (e as { message: string }).message === 'string') {
      const errors = (e as { message: string }).message.split(' | ')
      const error = errors[0]
      if (typeof error === 'string' && !error.includes('Message: ')) return error
      const msg = error.split('Message: ')[1] ?? defaultMsg
      let path: string = (error.split('Path: ')[1] ?? '').split(' ~ ')[0]
      path = path.split('body.')[1] ?? path
      path = path
        .split('_')
        .map((str) => (str ? str[0].toUpperCase() + str.slice(1) : ''))
        .join(' ')
      if (path) return `${path} - ${msg}`
      return msg ?? defaultMsg
    }
  }
  return defaultMsg ?? 'Internal server error'
}
