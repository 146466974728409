import { BaseFactory } from '../models'
import { CURRENT_SCHEMA_VERSION } from '../storage/constants'
import { LangkiStorage } from '../storage/models'
import { Collection } from './models'

export class CollectionFactory extends BaseFactory {
  static createDefault(storage: LangkiStorage): Collection {
    const data = this.createBase({})
    return new Collection(
      {
        last_synced_at: null,
        schema_updated_at: data.created_at,
        schema_version: CURRENT_SCHEMA_VERSION,
        scheduler_init_date: data.created_at,
        ...data
      },
      storage
    )
  }
}
