import { AnkiReview } from '../../anki/review/types'
import { CreateAnkiReviewType, Platform } from './types'

export class AnkiReviewAdapter {
  private ankiReview: AnkiReview

  constructor(ankiReview: AnkiReview) {
    this.ankiReview = ankiReview
  }

  public adapt(cardIdMap: Record<number, string>): CreateAnkiReviewType {
    const cardId = cardIdMap[this.ankiReview.cid]
    return {
      card_id: cardId,
      due: -1, // Missing
      interval: this.ankiReview.ivl,
      time_taken: this.ankiReview.time,
      platform: Platform.Anki,
      left: -1, // Missing
      type: this.ankiReview.type,
      rating: this.ankiReview.ease,
      fsrs_difficulty: null, // Missing
      fsrs_stability: null, // Missing
      created_at: this.ankiReview.id,
      updated_at: this.ankiReview.id
    }
  }
}
