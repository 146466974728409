import { FSRSState } from '../scheduler/fsrs/types'
import { Rating } from '../study-queue/types'
import { Platform, ReviewType } from './types'

export class Review {
  public readonly id: string
  public readonly cardId: string
  public interval: number
  public due: number
  public left: number
  public type: number
  public timeTaken: number
  public platform: Platform
  public fsrsState: FSRSState
  public rating: Rating
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: ReviewType) {
    this.id = data.id
    this.cardId = data.card_id
    this.interval = data.interval
    this.timeTaken = this.parseTimeTaken(data.time_taken)
    this.type = data.type
    this.platform = data.platform
    this.fsrsState = {
      stability: data.fsrs_stability,
      difficulty: data.fsrs_difficulty
    }
    this.rating = data.rating
    this.due = data.due
    this.left = data.left
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  parseTimeTaken(timeTaken: number): number {
    const maxMilliseconds = 60 * 1000
    if (timeTaken > maxMilliseconds) return maxMilliseconds
    return timeTaken
  }

  toDTO(): ReviewType {
    return {
      id: this.id,
      card_id: this.cardId,
      interval: this.interval,
      time_taken: this.timeTaken,
      type: this.type,
      platform: this.platform,
      fsrs_stability: this.fsrsState.stability,
      fsrs_difficulty: this.fsrsState.difficulty,
      rating: this.rating,
      due: this.due,
      left: this.left,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
