import React, { useMemo } from 'react'

export type UseMemoRefReturn<T> = [value: T, ref: React.MutableRefObject<T>]

export const useMemoRef = <T>(factory: () => T, deps: unknown[]): UseMemoRefReturn<T> => {
  const ref = React.useRef<T>(factory())
  const value = useMemo(() => {
    const val = factory()
    ref.current = val
    return val
  }, deps)

  return [value, ref]
}
