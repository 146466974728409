import { AnkiField } from '../../anki/field/types'
import { arrayBufferToUtf8 } from '../utils'
import { CreateAnkiFieldType } from './types'

export class AnkiFieldAdapter {
  private ankiField: AnkiField

  constructor(ankiField: AnkiField) {
    this.ankiField = ankiField
  }

  public adapt(noteTypeIdMap: Record<number, string>): CreateAnkiFieldType {
    const config = JSON.parse(arrayBufferToUtf8(this.ankiField.config))
    return {
      note_type_id: noteTypeIdMap[this.ankiField.ntid],
      name: this.ankiField.name,
      ordinal: this.ankiField.ord,
      description: config.description ?? '',
      font: config.font,
      size: config.size
    }
  }
}
