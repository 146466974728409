import { Kysely } from 'kysely'
import { AnkiTemplate } from '../../../anki/template/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { LangkiStorage } from '../../storage/models'
import { TemplateFactory as LangkiTemplateFactory } from '../../template/factories'
import { Template } from '../../template/models'

export class TemplateConverter {
  private langkiDb: LangkiStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(langkiDb: LangkiStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.langkiDb = langkiDb
    this.ankiDb = ankiDb
  }

  async insert(langkiTemplatesBatch: Template[]) {
    await this.langkiDb.template.createMultiple(langkiTemplatesBatch)
  }

  private async processBatch(
    batch: AnkiTemplate[],
    noteTypeIdMap: Record<number, string>
  ): Promise<void> {
    const langkiTemplatesBatch: Template[] = []
    for (const template of batch) {
      const langkiTemplate = LangkiTemplateFactory.createFromAnkiDeck(template, noteTypeIdMap)
      langkiTemplatesBatch.push(langkiTemplate)
    }
    await this.insert(langkiTemplatesBatch)
  }

  async convert(noteTypeIdMap: Record<number, string>): Promise<void> {
    const batchSize = 500
    const ankiTemplates = await this.ankiDb.selectFrom('templates').selectAll().execute()
    for (let i = 0; i < ankiTemplates.length; i += batchSize) {
      const batch = ankiTemplates.slice(i, i + batchSize)
      await this.processBatch(batch, noteTypeIdMap)
    }
  }
}
