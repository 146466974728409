import { Kysely } from 'kysely'
import { AnkiDeck } from '../../../anki/deck/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { DeckFactory as LangkiDeckFactory } from '../../deck/factories'
import { Deck } from '../../deck/models'
import { DeckConfigFactory as LangkiDeckConfigFactory } from '../../deck-config/factories'
import { DeckConfig } from '../../deck-config/models'
import { LangkiStorage } from '../../storage/models'

export class DeckConverter {
  private langkiDb: LangkiStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(langkiDb: LangkiStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.langkiDb = langkiDb
    this.ankiDb = ankiDb
  }

  async insert(langkiDecksBatch: Deck[], langkiDeckConfigsBatch: DeckConfig[]) {
    await this.langkiDb.deck.createMultiple(langkiDecksBatch, langkiDeckConfigsBatch)
  }

  private async processBatch(batch: AnkiDeck[]): Promise<Record<number, string>> {
    const langkiDecksBatch: Deck[] = []
    const langkiDeckConfigsBatch: DeckConfig[] = []
    const deckIdMap: Record<number, string> = {}

    for (const deck of batch) {
      const deckConfig = LangkiDeckConfigFactory.createDefault()
      const langkiDeck = LangkiDeckFactory.createFromAnkiDeck(deck, deckConfig.id)
      langkiDecksBatch.push(langkiDeck)
      langkiDeckConfigsBatch.push(deckConfig)
      deckIdMap[deck.id] = langkiDeck.id
    }
    await this.insert(langkiDecksBatch, langkiDeckConfigsBatch)
    return deckIdMap
  }

  async convert(): Promise<Record<number, string>> {
    const ankiDecks = await this.ankiDb.selectFrom('decks').selectAll().execute()
    let deckIdMap: Record<number, string> = {}
    const batchSize = 250
    for (let i = 0; i < ankiDecks.length; i += batchSize) {
      const batch = ankiDecks.slice(i, i + batchSize)
      deckIdMap = { ...deckIdMap, ...(await this.processBatch(batch)) }
    }
    return deckIdMap
  }
}
