import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Card } from '../card/models'
import { DueCountsQuery } from '../deck/types'
import { DeckLimits } from '../limits/models'
import { Review } from '../review/models'
import { Scheduler } from '../scheduler/models'
import { SchedulerTiming } from '../scheduler/types'
import { studiedToday } from '../stats/today'
import { LangkiStorage } from '../storage/models'
import { StudyQueueFactory } from '../study-queue/factories'
import { StudyQueue } from '../study-queue/models'
import { CardRenderData, CollectionType } from './types'

dayjs.extend(utc)

export class Collection {
  public readonly id: string
  public schemaUpdatedAt: number
  public schemaVersion: number
  public lastSyncedAt: number | null
  public schedulerInitDate: number
  public readonly createdAt: number
  public updatedAt: number
  public readonly storage: LangkiStorage

  constructor(data: CollectionType, storage: LangkiStorage) {
    this.id = data.id
    this.schemaUpdatedAt = data.schema_updated_at
    this.schemaVersion = data.schema_version
    this.lastSyncedAt = data.last_synced_at
    this.schedulerInitDate = data.scheduler_init_date
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
    this.storage = storage
  }

  async getSchedulerTimingToday(): Promise<SchedulerTiming> {
    const config = await this.storage.config.get()
    const timing = Scheduler.getTodaysSchedule(config.timezone, config.learnAheadSeconds)
    return timing
  }

  async getDeckDueCountsToday(): Promise<DueCountsQuery[]> {
    const timing = await this.getSchedulerTimingToday()
    const dueCounts = await this.storage.deck.dueCounts(timing.nextDayAt, timing.learnCutoff)
    const deckLimits = new DeckLimits(this)
    return deckLimits.applyNewCardLimitsToCounts(dueCounts)
  }

  async saveReviewData(card: Card, review: Review): Promise<void> {
    await this.storage.withTransaction(async (trx) => {
      await this.storage.review.create(review, trx)
      await this.storage.card.update(card, trx)
    })
  }

  async getCardRenderData(card: Card): Promise<CardRenderData> {
    const note = await this.storage.note.get(card.noteId)
    const noteType = await this.storage.noteType.get(note.noteTypeId)
    const fields = await this.storage.field.getForNoteType(noteType.id)
    const fieldValues = await this.storage.fieldValue.getForNote(note.id)
    return { card, note, noteType, fields, fieldValues }
  }

  async getStudyQueueForAllDecks(): Promise<StudyQueue> {
    return await StudyQueueFactory.create(this)
  }

  async getStudyQueueForDeck(deckId: string): Promise<StudyQueue> {
    return await StudyQueueFactory.createForDeck(this, deckId)
  }

  async saveUndo(card: Card, review: Review): Promise<void> {
    this.storage.withTransaction(async (trx) => {
      await this.storage.review.delete(review.id, trx)
      await this.storage.card.update(card, trx)
    })
  }

  async deleteDeck(id: string): Promise<void> {
    await this.storage.deck.delete(id)
  }

  async getTodaysStats(deckIds: string[]): Promise<{ totalReviews: number; timeTaken: number }> {
    const timing = await this.getSchedulerTimingToday()
    return studiedToday(this, timing, deckIds)
  }

  toDTO(): CollectionType {
    return {
      id: this.id,
      schema_updated_at: this.schemaUpdatedAt,
      schema_version: this.schemaVersion,
      last_synced_at: this.lastSyncedAt,
      scheduler_init_date: this.schedulerInitDate,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
