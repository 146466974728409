import { booleanToNumber, numberToBoolean } from '../utils'
import { DEFAULT_NEW_CARDS_PER_DECK_PER_DAY } from './constants'
import { DeckConfigType } from './types'

export class DeckConfig {
  public readonly id: string
  public active: boolean
  public maxNewCardsPerDay: number
  public maxReviewCardsPerDay: number | null
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: DeckConfigType) {
    this.id = data.id
    this.active = numberToBoolean(data.active)
    this.maxNewCardsPerDay = data.max_new_cards_per_day ?? DEFAULT_NEW_CARDS_PER_DECK_PER_DAY
    this.maxReviewCardsPerDay = data.max_review_cards_per_day
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): DeckConfigType {
    return {
      id: this.id,
      active: booleanToNumber(this.active),
      max_new_cards_per_day: this.maxNewCardsPerDay,
      max_review_cards_per_day: this.maxReviewCardsPerDay,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
