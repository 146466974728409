export type Counts = {
  new: number
  learning: number
  review: number
}

export enum Rating {
  Incorrect = 1,
  Correct = 2
}
