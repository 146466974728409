import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const languageSelectContainer = ({}: Theme): StyleProp<ViewStyle> => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 12,
  flexGrow: 1,
  alignContent: 'center'
})

export const languageSelect = ({}: Theme): StyleProp<ViewStyle> => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingHorizontal: 16,
  gap: 8,
  flex: 1,
  minWidth: 150
})

export const languageFlagContainer = (): StyleProp<ViewStyle> => ({
  aspectRatio: 1,
  width: 36,
  height: 36,
  alignSelf: 'center',
  borderWidth: 2,
  borderColor: '#ccc',
  borderStyle: 'solid',
  borderRadius: 9999,
  overflow: 'hidden'
})

export const languageFlag = (): StyleProp<ViewStyle> => ({
  transform: [{ scale: 1.1 }]
})

export const languageName = ({}: Theme): StyleProp<ViewStyle & TextStyle> => ({
  fontWeight: 'bold',
  fontSize: 18
})

export const languageLevelSlide = ({}: Theme): StyleProp<ViewStyle> => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
  gap: 12
})
