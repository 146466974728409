import { Kysely } from 'kysely'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { ProgressHandler } from '../../../interfaces/progress-handler/types'
import { LangkiStorage } from '../../storage/models'
import { CardConverter } from './card'
import { CollectionConverter } from './collection'
import { DeckConverter } from './deck'
import { FieldConverter } from './field'
import { NoteConverter } from './note'
import { NoteTypeConverter } from './note-type'
import { ReviewConverter } from './review'
import { TemplateConverter } from './template'

export class AnkiConverter {
  private ankiDb: Kysely<AnkiDatabase>
  private langkiDb: LangkiStorage
  private cardConverter: CardConverter
  private collectionConverter: CollectionConverter
  private deckConverter: DeckConverter
  private fieldConverter: FieldConverter
  private noteConverter: NoteConverter
  private noteTypeConverter: NoteTypeConverter
  private reviewConverter: ReviewConverter
  private templateConverter: TemplateConverter

  constructor(ankiDb: Kysely<AnkiDatabase>, langkiDb: LangkiStorage) {
    this.ankiDb = ankiDb
    this.langkiDb = langkiDb
    this.cardConverter = new CardConverter(langkiDb, ankiDb)
    this.collectionConverter = new CollectionConverter(ankiDb)
    this.deckConverter = new DeckConverter(langkiDb, ankiDb)
    this.fieldConverter = new FieldConverter(langkiDb, ankiDb)
    this.noteConverter = new NoteConverter(langkiDb, ankiDb)
    this.noteTypeConverter = new NoteTypeConverter(langkiDb, ankiDb)
    this.reviewConverter = new ReviewConverter(langkiDb, ankiDb)
    this.templateConverter = new TemplateConverter(langkiDb, ankiDb)
  }

  public static CONVERT_TOTAL_PROGRESS = 8
  public async convert(progressHandler?: ProgressHandler) {
    if (!progressHandler?.hasTotal())
      progressHandler?.setTotal(AnkiConverter.CONVERT_TOTAL_PROGRESS)

    progressHandler?.setText('Converting collections', 'converting.collection')
    const schedulerInitDateSeconds = await this.collectionConverter.convert()
    progressHandler?.addProgress()

    progressHandler?.setText('Converting decks', 'converting.decks')
    const deckIdMap = await this.deckConverter.convert()
    progressHandler?.addProgress()

    progressHandler?.setText('Converting note types', 'converting.note-types')
    const noteTypeIdMap = await this.noteTypeConverter.convert()
    progressHandler?.addProgress()

    progressHandler?.setText('Converting templates', 'converting.templates')
    await this.templateConverter.convert(noteTypeIdMap)
    progressHandler?.addProgress()

    progressHandler?.setText('Converting note fields', 'converting.note-fields')
    const noteTypeOrdMap = await this.fieldConverter.convert(noteTypeIdMap)
    progressHandler?.addProgress()

    progressHandler?.setText('Converting notes', 'converting.notes')
    const noteIdMap = await this.noteConverter.convert(noteTypeIdMap, noteTypeOrdMap)
    progressHandler?.addProgress()

    progressHandler?.setText('Converting cards', 'converting.cards')
    const cardIdMap = await this.cardConverter.convert(
      schedulerInitDateSeconds,
      deckIdMap,
      noteIdMap
    )
    progressHandler?.addProgress()

    progressHandler?.setText('Converting reviews', 'converting.reviews')
    await this.reviewConverter.convert(cardIdMap)
    progressHandler?.addProgress()
  }
}
