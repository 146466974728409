import React, { useState } from 'react'
import { ScrollView, ScrollViewProps, Text, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { defaultCombinedDeck } from '../../defaults'
import { CombinedDeck, useIsMounted, useLangkiStorage, useStyles } from '../../hooks'
import { Component } from '../../types'
import ConfirmationModal from '../ConfirmationModal'
import { DeckPreview } from '../DeckPreview'
import { Loader } from '../Loader'
import * as _styles from './DeckList.styles'

export type DeckListProps = ScrollViewProps & {
  combinedDecks: CombinedDeck[]
  loading: boolean
  onDelete?: (deck: CombinedDeck) => void
}

const DeckList: Component<DeckListProps> = ({ combinedDecks, onDelete, loading, ...others }) => {
  const mounted = useIsMounted()
  const styles = useStyles(_styles)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deckToDelete, setDeckToDelete] = useState<CombinedDeck | null>(null)

  const { collection } = useLangkiStorage()
  const [deleteFetching, setDeleteFetching] = useState(false)

  const toast = useToast()

  const deleteDeck = async () => {
    if (!collection || !deckToDelete) return
    try {
      setDeleteFetching(true)
      await collection.deleteDeck(deckToDelete.id)
      if (!mounted.current) return
      toast.show('Successfully deleted deck', { type: 'success' })
      onDelete?.(deckToDelete)
      setDeleteConfirmationOpen(false)
    } catch (err) {
      if (!mounted.current) return
      toast.show('Error deleting deck', { type: 'danger' })
    }
    if (!mounted.current) return
    setDeleteFetching(false)
  }

  return (
    <Loader loading={loading}>
      <ScrollView {...others} style={[styles.container, others.style]}>
        <View style={styles.inner}>
          {loading || combinedDecks.length > 0 ? (
            (loading
              ? Array.from({ length: 5 }).map(() => defaultCombinedDeck)
              : combinedDecks
            ).map((deck, i) => (
              <DeckPreview
                key={i}
                deck={deck}
                dueCounts={deck.dueCounts}
                onDelete={() => {
                  setDeckToDelete(deck)
                  setDeleteConfirmationOpen(true)
                }}
              />
            ))
          ) : (
            <Text style={styles.emptyLabel}>No decks found</Text>
          )}
        </View>
        <ConfirmationModal
          open={deleteConfirmationOpen}
          onClose={() => setDeleteConfirmationOpen(false)}
          confirmationText="Are you sure you want to delete that deck?"
          loading={deleteFetching}
          onConfirm={deleteDeck}
          buttonText="Delete"
        />
      </ScrollView>
    </Loader>
  )
}

export default DeckList
