import { StyleProp } from 'react-native'
import { useTheme } from '../state/theme'
import { Theme } from '../themes'

export const useStyles = <T extends Record<string, (theme: Theme) => StyleProp<{}>>>(
  styles: T
): {
  [key in keyof T]: {}
} & { [key: string]: {} } => {
  const theme = useTheme()

  return Object.entries(styles)
    .filter(([_, val]) => typeof val === 'function')
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: val(theme)
      }),
      {} as {
        [key in keyof T]: {}
      } & { [key: string]: {} }
    )
}
