import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, Text, TransformsStyle, ViewProps, ViewStyle } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { WebView, WebViewMessageEvent } from 'react-native-webview'
import { CardRenderData } from '../../../packages/langki/collection/types'
import { Button } from '../../components'
import IconButton from '../../components/IconButton'
import { useDelayed, useMemoRef, useStateRef, useStyles } from '../../hooks'
import DropdownIcon from '../../svg/Dropdown'
import UndoIcon from '../../svg/Undo'
import { Component } from '../../types'
import { minMax } from '../../util'
import * as _styles from './StudyCard.styles'
import { useCardRenderHtml } from './StudyCard.util'

const duration = 400

export type StudyCardProps = ViewProps & {
  cardRender: CardRenderData | null
  onPress?: () => void
  direction: 'front' | 'back'
  initialLoad: boolean
  style?: ViewStyle
  position: 'left' | 'middle' | 'right'
  draggable?: boolean
  onDragRight?: () => void
  onDragLeft?: () => void
  onUndo?: () => void
  canUndo?: boolean
  doesAnimate?: boolean
  behind?: boolean
}

export const StudyCard: Component<StudyCardProps> = ({
  cardRender,
  onPress,
  initialLoad,
  direction,
  position,
  onDragLeft,
  onDragRight,
  draggable = true,
  onUndo,
  canUndo,
  doesAnimate = true,
  behind = false,
  ...others
}) => {
  const styles = useStyles(_styles)
  const cardTransformStyle: ViewStyle = {
    transform: direction === 'front' ? [{ rotateY: '180deg' }] : []
  }

  const cardInnerTransformStyle: ViewStyle = {
    transform: direction === 'front' ? [{ rotateY: '-180deg' }] : []
  }

  const [_, setDragging, draggingRef] = useStateRef(false)
  const [dragStartX, setDragStartX, dragStartXRef] = useStateRef(0)
  const [dragCurrentX, setDragCurrentX, dragCurrentXRef] = useStateRef(0)
  const [__, setLastDragDiff, lastDragDiffRef] = useStateRef(0)
  const [___, setDragStartTime, dragStartTimeRef] = useStateRef(0)

  const onDragStart = (x: number) => {
    if (!draggable) return
    setDragging(true)
    setDragStartX(x)
    setDragCurrentX(x)
    setLastDragDiff(0)
    setDragStartTime(Date.now())
  }

  const completeDragRef = useRef(true)

  const onDragEnd = useCallback(() => {
    if (!draggable || !draggingRef.current) return
    completeDragRef.current = true
    let timeout = setTimeout(() => {
      setDragging(false)
      setDragStartX(0)
      setDragCurrentX(0)
      const dragDiff = dragStartXRef.current - dragCurrentXRef.current
      const shortPress = Date.now() - dragStartTimeRef.current < 200
      if (!completeDragRef.current) return
      if (lastDragDiffRef.current > 30 || dragDiff > 100) onDragLeft?.()
      if (lastDragDiffRef.current < -30 || dragDiff < -100) onDragRight?.()
      if (Math.abs(dragDiff) < 10 && shortPress) onPress?.()
      setDragStartTime(0)
    }, 30)
    return () => clearTimeout(timeout)
  }, [draggable, onDragLeft, onDragRight, onPress])

  const onDragMove = (x: number) => {
    if (!draggingRef.current) return
    setLastDragDiff(dragCurrentXRef.current - x)
    setDragCurrentX(minMax(x, dragStartXRef.current - 150, dragStartXRef.current + 150))
  }

  const dragOffset = useMemo(() => {
    if (position === 'left') return -500
    if (position === 'right') return 500

    const diff = dragCurrentX - dragStartX
    return diff
  }, [dragCurrentX, dragStartX, position])

  const onDisableEvents = () => {
    setDragging(false)
    completeDragRef.current = false
    setTimeout(() => {
      setDragging(false)
      completeDragRef.current = false
    }, 25)
  }

  useEffect(() => {
    if (Platform.OS !== 'web') return
    const listener = (e: PointerEvent) => {
      onDragMove(e.screenX)
    }
    document.body.addEventListener('pointermove', listener)
    return () => document.body.removeEventListener('pointermove', listener)
  }, [])

  useEffect(() => {
    if (Platform.OS !== 'web') return
    const listener = () => {
      onDragEnd()
    }
    document.body.addEventListener('pointerup', listener)
    return () => {
      document.body.removeEventListener('pointerup', listener)
    }
  }, [onDragEnd])

  const [transformation, transformationRef] = useMemoRef<
    Exclude<TransformsStyle['transform'], string>
  >(() => {
    return [
      { translateX: dragOffset },
      { translateY: -Math.cos(Math.abs(dragOffset / 60)) * 10 + 10 },
      { rotate: `${dragOffset / 8}deg` },
      ...((others.style?.transform as []) ?? [])
    ]
  }, [dragOffset, others.style?.transform])

  return (
    <Animatable.View
      onPointerDown={(e) => Platform.OS === 'web' && onDragStart(e.nativeEvent.screenX)}
      onPointerCancel={() => Platform.OS === 'web' && onDragEnd()}
      onPointerUp={() => Platform.OS === 'web' && onDragEnd()}
      onTouchStart={(e) => {
        if (Platform.OS === 'web') return
        onDragStart(e.nativeEvent.pageX)
      }}
      onTouchMove={(e) => {
        if (Platform.OS === 'web') return
        onDragMove(e.nativeEvent.pageX)
      }}
      onTouchEnd={() => {
        if (Platform.OS === 'web') return
        onDragEnd()
      }}
      onTouchCancel={() => setDragging(false)}
      duration={200}
      transition={doesAnimate ? ['translateX', 'translateY', 'rotate', 'scale'] : []}
      style={[
        styles.cardContainer,
        others.style,
        {
          transform: transformation
        }
      ]}
    >
      <Animatable.View
        easing="linear"
        duration={duration}
        transition="rotateY"
        style={[styles.card, cardTransformStyle, behind && { pointerEvents: 'none' }]}
      >
        <Animatable.View
          easing="linear"
          duration={duration}
          transition={'rotateY'}
          style={[styles.cardInner, cardInnerTransformStyle, initialLoad && { opacity: 0 }]}
        >
          <CardInner
            cardRender={cardRender}
            direction={direction}
            onUndo={() => {
              onDisableEvents()
              setTimeout(() => {
                onUndo?.()
              }, 30)
            }}
            canUndo={canUndo}
            onDisableEvents={onDisableEvents}
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
            onDragStart={onDragStart}
            onPress={onPress}
            behind={behind}
            transformationRef={transformationRef}
          />
        </Animatable.View>
      </Animatable.View>
    </Animatable.View>
  )
}

export default StudyCard

export type CardInnerProps = {
  direction: 'front' | 'back'
  cardRender: CardRenderData | null
  onUndo?: () => void
  canUndo?: boolean
  onDisableEvents?: () => void
  onDragStart?: (x: number) => void
  onDragMove?: (x: number) => void
  onDragEnd?: (x: number) => void
  onPress?: () => void
  behind?: boolean
  transformationRef?: MutableRefObject<Exclude<TransformsStyle['transform'], string>>
}

export const CardInner: Component<CardInnerProps> = (props) => {
  const delayedDir = useDelayed(props.direction, duration / 2)

  return <CardRender {...props} direction={delayedDir} />
}

export const CardRender: Component<CardInnerProps> = ({
  cardRender,
  direction,
  canUndo,
  onUndo,
  onDisableEvents,
  onDragEnd,
  onDragMove,
  onDragStart,
  onPress,
  behind = false,
  transformationRef
}) => {
  const styles = useStyles(_styles)
  const [loadingFront, setLoadingFront] = useState(true)
  const [loadingBack, setLoadingBack] = useState(true)
  const mouseLocationRef = useRef({ x: 0, y: 0 })
  const renderedHtml = useCardRenderHtml({ cardRender })
  const [frontFrame, setFrontFrame] = useState<unknown>()
  const [backFrame, setBackFrame] = useState<unknown>()

  const hiddenStyles: ViewStyle = {
    display: 'none',
    pointerEvents: 'none'
  }

  const reverseTransformation = (x: number): number => {
    let transforms = transformationRef?.current
    if (!transforms) return x
    let num = x
    for (const transform of transforms) {
      if (transform.translateX) num -= transform.translateX as number
    }
    return num
  }

  const getMessageHandler = (dir: 'front' | 'back') => {
    return (e: WebViewMessageEvent) => {
      if (e.nativeEvent.data === 'loaded') {
        setTimeout(() => {
          if (dir === 'front') setLoadingFront(false)
          else setLoadingBack(false)
        }, 100)
      }
      if (behind) return
      if (dir !== direction) return
      console.log('GOT NATIVE EVENT', dir, e.nativeEvent.data)
      if (e.nativeEvent.data === 'disable_events') {
        if (Platform.OS === 'web') return
        onDisableEvents?.()
      } else if (e.nativeEvent.data === 'drag_start')
        Platform.OS === 'web' && onDragStart?.(reverseTransformation(mouseLocationRef.current.x))
      else if (e.nativeEvent.data === 'drag_move')
        Platform.OS === 'web' && onDragMove?.(reverseTransformation(mouseLocationRef.current.x))
      else if (e.nativeEvent.data === 'drag_end')
        Platform.OS === 'web' && onDragEnd?.(reverseTransformation(mouseLocationRef.current.x))
      else if (e.nativeEvent.data === 'press') {
        Platform.OS === 'web' && onPress?.()
      }
    }
  }

  useEffect(() => {
    if (Platform.OS !== 'web') return
    const listener = (e: MouseEvent) => {
      mouseLocationRef.current = {
        x: e.x,
        y: e.y
      }
    }
    document.addEventListener('pointermove', listener)
    return () => document.removeEventListener('pointermove', listener)
  }, [])

  useEffect(() => {
    if (!frontFrame || !backFrame || Platform.OS !== 'web') return
    let listeners: [HTMLElement, string, (e: PointerEvent) => unknown][] = []
    let unmounted = false

    let frames = [(frontFrame as any).frameRef, (backFrame as any).frameRef] as HTMLIFrameElement[]
    frames.forEach((frame) => {
      const target = frame.contentWindow?.document.body
      if (!target) return
      frame.onload = () => {
        if (unmounted || !frame.contentDocument) return
        // const startListener = (e: PointerEvent) => {
        //   console.log('DRAG START', e)
        //   onDragStart?.(e.pageX)
        // }
        // target.addEventListener('pointerdown', startListener)
        // listeners.push([frame, 'pointerdown', startListener])

        // const endListener = (e: PointerEvent) => {
        //   console.log('DRAG END', e)
        //   onDragEnd?.(e.pageX)
        // }
        // target.addEventListener('pointerup', endListener)
        // listeners.push([frame, 'pointerup', endListener])

        // const pressListener = (e: Event) => {
        //   console.log('PRESSED', e)
        //   onPress?.()
        // }
        // target.addEventListener('click', pressListener)
        // listeners.push([frame, 'click', pressListener])
      }
    })
    return () => {
      unmounted = true
      listeners.forEach(([frame, event, callback]) => {
        frame.removeEventListener(event, callback as any)
      })
    }
  }, [frontFrame, backFrame, renderedHtml.backHtml, renderedHtml.frontHtml])

  return (
    <>
      <Button
        onPress={onUndo}
        variant="icon_contained"
        color="contrast"
        size="compact"
        disabled={canUndo === false}
        icon={UndoIcon}
        style={styles.undoButton}
      />

      <IconButton icon={DropdownIcon} style={styles.menuButton} />
      {cardRender && (
        <>
          <Animatable.View
            style={[
              styles.cardRender,
              loadingFront && { opacity: 0 },
              direction === 'back' && hiddenStyles
            ]}
          >
            <WebView
              style={styles.webview}
              ref={setFrontFrame}
              overScrollMode="never"
              source={{ html: renderedHtml.frontHtml }}
              setBuiltInZoomControls={false}
              setDisplayZoomControls={false}
              allowsInlineMediaPlayback={true}
              mediaPlaybackRequiresUserAction={false}
              allowsProtectedMedia
              onMessage={getMessageHandler('front')}
              onPointerMove={(e) => {
                mouseLocationRef.current = {
                  x: e.nativeEvent.x,
                  y: e.nativeEvent.y
                }
              }}
            />
          </Animatable.View>
          <Animatable.View
            style={[
              styles.cardRender,
              loadingBack && { opacity: 0 },
              direction === 'front' && hiddenStyles
            ]}
          >
            <WebView
              ref={setBackFrame}
              style={styles.webview}
              overScrollMode="never"
              source={{ html: renderedHtml.backHtml }}
              setBuiltInZoomControls={false}
              setDisplayZoomControls={false}
              allowsInlineMediaPlayback={true}
              mediaPlaybackRequiresUserAction={false}
              allowsProtectedMedia
              onMessage={getMessageHandler('back')}
              onScroll={onDisableEvents}
              onPointerMove={(e) => {
                mouseLocationRef.current = {
                  x: e.nativeEvent.x,
                  y: e.nativeEvent.y
                }
              }}
            />
          </Animatable.View>
        </>
      )}
      {direction === 'front' && <Text style={styles.revealText}>Press to Reveal</Text>}
    </>
  )
}
