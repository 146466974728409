import { AnkiField } from '../../anki/field/types'
import { BaseFactory } from '../models'
import { AnkiFieldAdapter } from './adapters'
import { Field } from './models'
import { CreateFieldType } from './types'

export class FieldFactory extends BaseFactory {
  static create(data: CreateFieldType): Field {
    return new Field(this.createBase(data))
  }

  static createFromAnkiDeck(data: AnkiField, noteTypeIdMap: Record<number, string>): Field {
    const adapter = new AnkiFieldAdapter(data)
    const adaptedData = adapter.adapt(noteTypeIdMap)
    return new Field(this.createBase(adaptedData))
  }
}
