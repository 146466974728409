import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({ background, radius }: Theme): ViewStyle => ({
  display: 'flex',
  backgroundColor: background.card,
  borderRadius: radius.card,
  padding: 16,
  gap: 4
})

export const nameLabel = ({ fonts }: Theme): TextStyle => ({
  ...fonts.titleRegular,
  fontSize: 22,
  marginRight: 24
})

export const description = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textSecondary,
  fontSize: 18,
  marginBottom: 8
})

export const cardCount = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 16
})

export const dropdownContainer = (): ViewStyle => ({
  position: 'absolute',
  top: 8,
  right: 8
})

export const dropdownButton = (): ViewStyle => ({
  width: 36,
  height: 36,
  padding: 6
})

export const studyButton = (): ViewStyle => ({
  position: 'absolute',
  bottom: 12,
  right: 12,
  width: 100
})
