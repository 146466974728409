import { Kysely } from 'kysely'
import { AnkiNote } from '../../../anki/note/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { FieldValue } from '../../field-value/models'
import { NoteFactory as LangkiNoteFactory } from '../../note/factories'
import { Note } from '../../note/models'
import { LangkiStorage } from '../../storage/models'
import { FieldValueConverter } from './field-value'

export class NoteConverter {
  private langkiDb: LangkiStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(langkiDb: LangkiStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.langkiDb = langkiDb
    this.ankiDb = ankiDb
  }

  async insert(langkiNotesBatch: Note[]) {
    await this.langkiDb.note.createMultiple(langkiNotesBatch)
  }

  private async processBatch(
    batch: AnkiNote[],
    noteTypeIdMap: Record<number, string>,
    noteTypeOrdMap: Record<string, Record<number, string>>
  ): Promise<Record<number, string>> {
    const noteIdMap: Record<number, string> = {}
    const langkiNotesBatch: Note[] = []
    let fieldValuesBatch: FieldValue[] = []
    const fieldConverter = new FieldValueConverter(this.langkiDb)

    for (const note of batch) {
      const noteTypeId = noteTypeIdMap[note.mid]
      const langkiNote = LangkiNoteFactory.create({ note_type_id: noteTypeId })
      langkiNotesBatch.push(langkiNote)
      const fieldValues = note.flds.split('\x1f')
      fieldValuesBatch = [
        ...fieldValuesBatch,
        ...fieldConverter.convert(langkiNote.id, noteTypeId, fieldValues, noteTypeOrdMap)
      ]
      if (!note.id) {
        throw new Error('Note ID unexpectedly null')
      }
      noteIdMap[note.id] = langkiNote.id
    }
    await this.insert(langkiNotesBatch)
    await fieldConverter.insert(fieldValuesBatch)
    return noteIdMap
  }

  async convert(
    noteTypeIdMap: Record<number, string>,
    noteTypeOrdMap: Record<string, Record<number, string>>
  ): Promise<Record<number, string>> {
    const ankiNotes = await this.ankiDb.selectFrom('notes').selectAll().execute()
    let noteIdMap: Record<number, string> = {}
    const batchSize = 250
    for (let i = 0; i < ankiNotes.length; i += batchSize) {
      const batch = ankiNotes.slice(i, i + batchSize)
      noteIdMap = {
        ...noteIdMap,
        ...(await this.processBatch(batch, noteTypeIdMap, noteTypeOrdMap))
      }
    }
    return noteIdMap
  }
}
