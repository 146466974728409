import { StyleProp, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  flexDirection: 'row',
  height: 16,
  borderRadius: 9999,
  backgroundColor: colors.component.background,
  overflow: 'hidden'
})

export const bar = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  height: 16,
  backgroundColor: colors.primary.main,
  borderRadius: 9999
})
