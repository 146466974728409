const labelMap = {
  second: 1,
  minute: 60,
  hour: 60 * 60,
  day: 24 * 60 * 60
}
const labelEntries = Object.entries(labelMap).reverse()

export const getDurationFromSecs = (secs: number): string => {
  for (const [label, divider] of labelEntries) {
    let amount = Math.floor((secs / divider) * 10) / 10
    if (secs / divider > 1) return `${amount} ${label + (amount === 1 ? '' : 's')}`
  }
  return secs + ' seconds'
}
