import './setup'
import React from 'react'
import { Platform, UIManager } from 'react-native'
import ApplicationNavigator from './navigators/Application'
import '../packages/interfaces/sqlite/web'

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }
}

const App = () => <ApplicationNavigator />

export default App
