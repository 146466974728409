import { Kysely } from 'kysely'
import { Database } from '../types'

export abstract class BaseStorage {
  protected db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }
}
