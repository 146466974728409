import { join } from 'path'
import { FileSystemHandler } from '../../interfaces/filesystem/types'
import { LangkiRendererTransformer, LangkiRendererTransformerArgs } from './langki-renderer'

const mediaRegexp = /\[(sound|audio):.+?\]/
const imgRegexp = /<img .*?src="(?!(data:image|http)\/).+?"\/?.*?>/
const srcRegexp = /src=".+?"/

export type MediaTransformerState = {
  reversedMediaMap: Record<string, string>
}

export type GetBase64UrlFromFileArgs = {
  originalFileName: string
  reversedMediaMap: Record<string, string>
  mediaFolder: string
  fsHandler: FileSystemHandler
  mediaType: string
  defaultExtension?: string
}

export const getBase64UrlFromFile = async (
  args: GetBase64UrlFromFileArgs
): Promise<string | null> => {
  const fileName = args.reversedMediaMap[args.originalFileName]
  if (!fileName) return null
  const filePath = join(args.fsHandler.getBasePath(), args.mediaFolder, 'files', fileName)
  const fileData = await args.fsHandler.readFile(filePath, 'base64')
  return `data:${args.mediaType}/${
    args.originalFileName.split('.')[1] ?? args.defaultExtension ?? ''
  };base64,${fileData}`
}

export const mediaHandlers: [
  string[],
  (
    str: string,
    mediaPath: string,
    args: LangkiRendererTransformerArgs,
    state: MediaTransformerState
  ) => string | Promise<string>
][] = [
  [
    ['audio', 'sound'],
    async (str, mediaPath, args, state) => {
      const extension: string | undefined = mediaPath.split('.')?.[1]
      if (['mp4'].includes(extension)) {
        const base64Url = await getBase64UrlFromFile({
          fsHandler: args.fsHandler,
          mediaFolder: args.mediaFolder,
          originalFileName: mediaPath,
          reversedMediaMap: state.reversedMediaMap,
          mediaType: 'video',
          defaultExtension: 'mp4'
        }).catch((err) => console.error(err))
        if (!base64Url) return ''
        return `<div class="video-container"><video autoplay controls><source src="${base64Url}" /></video></div>`
      }

      const base64Url = await getBase64UrlFromFile({
        fsHandler: args.fsHandler,
        mediaFolder: args.mediaFolder,
        originalFileName: mediaPath,
        reversedMediaMap: state.reversedMediaMap,
        mediaType: 'audio',
        defaultExtension: 'mp3'
      }).catch((err) => console.error(err))
      if (!base64Url) return ''
      return `<div class="audio-container"><audio><source src="${base64Url}" /></audio></div>`
    }
  ]
]

export const mediaTransformer: LangkiRendererTransformer<MediaTransformerState> = {
  getTransformTarget(html) {
    const match = mediaRegexp.exec(html)
    if (!match) {
      const imgMatch = imgRegexp.exec(html)
      if (!imgMatch) return null
      const srcMatch = srcRegexp.exec(imgMatch[0])
      if (!srcMatch) return null
      const srcString = srcMatch[0].substring('src="'.length, srcMatch[0].length - 1)
      return {
        index: imgMatch.index + srcMatch.index + 'src="'.length + 1,
        str: srcString,
        type: 'src'
      }
    }
    return {
      index: match.index,
      str: match[0],
      type: 'tagged'
    }
  },
  async transform(transformTarget, html, args, state) {
    if (transformTarget.type === 'tagged') {
      const parsed = transformTarget.str.substring(1, transformTarget.str.length - 1)
      const split = parsed.split(':')
      const mediaType = split[0]
      const mediaPath = split[1]
      const handler = mediaHandlers.find((val) => val[0].includes(mediaType))
      if (!handler) return html
      const transformed = await handler[1](transformTarget.str, mediaPath, args, state)
      return (
        html.substring(0, transformTarget.index - 1) +
        transformed +
        html.substring(transformTarget.index + transformTarget.str.length)
      )
    } else if (transformTarget.type === 'src') {
      const base64Url = await getBase64UrlFromFile({
        fsHandler: args.fsHandler,
        mediaFolder: args.mediaFolder,
        originalFileName: transformTarget.str,
        reversedMediaMap: state.reversedMediaMap,
        mediaType: 'image',
        defaultExtension: 'jpg'
      }).catch((err) => console.error(err))
      let newSrc = ''
      if (base64Url) newSrc = base64Url + '"'
      let newHtml =
        html.substring(0, transformTarget.index - 1) +
        newSrc +
        html.substring(transformTarget.index + transformTarget.str.length)
      return newHtml
    }
    return html
  },
  setup: async (html, args) => {
    try {
      const mediaMapStr = await args.fsHandler.readFile(
        join(args.fsHandler.getBasePath(), args.mediaFolder, 'media.json'),
        'utf8'
      )
      const mediaMapJson: Record<string, string> = JSON.parse(mediaMapStr)
      let reversedMediaMap: Record<string, string> = {}
      for (const [key, val] of Object.entries(mediaMapJson)) {
        reversedMediaMap[val] = key
      }
      return {
        reversedMediaMap
      }
    } catch (err) {}
    return {
      reversedMediaMap: {}
    }
  }
}
