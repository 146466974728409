import { Card } from '../card/models'
import { CardQueue } from '../card/types'
import { Collection } from '../collection/models'
import { DeckLimits } from '../limits/models'
import { cardSort } from '../storage/card/sort'
import { StudyQueue } from './models'
import { intersperse } from './utils'

export class StudyQueueFactory {
  static async createForDeck(collection: Collection, deckId: string) {
    const dueCards = await this.gatherDueCardsForDecks(collection, [deckId])
    const counts = {
      new: dueCards.new.length,
      learning: dueCards.learning.length,
      review: dueCards.review.length
    }
    const mixedCards = this.mixCards(dueCards.new, dueCards.review)
    return new StudyQueue(mixedCards, dueCards.learning, counts, collection)
  }

  static async create(collection: Collection): Promise<StudyQueue> {
    const dueCards = await this.gatherDueCards(collection)
    const counts = {
      new: dueCards.new.length,
      learning: dueCards.learning.length,
      review: dueCards.review.length
    }
    const mixedCards = this.mixCards(dueCards.new, dueCards.review)
    return new StudyQueue(mixedCards, dueCards.learning, counts, collection)
  }

  private static async gatherDueCardsForDecks(collection: Collection, deckIds: string[]) {
    const timing = await collection.getSchedulerTimingToday()
    let newCards = await collection.storage.card.getDueCardsForDecks(
      CardQueue.New,
      timing.learnCutoff,
      deckIds,
      cardSort.new.oldestFirst
    )

    const deckLimits = new DeckLimits(collection)
    newCards = await deckLimits.applyNewCardLimitsToCards(newCards)
    const reviewCards = await collection.storage.card.getDueCardsForDecks(
      CardQueue.Review,
      timing.nextDayAt,
      deckIds,
      cardSort.review.dueFirst
    )

    const learningCards = await collection.storage.card.getDueCardsForDecks(
      [CardQueue.Learning, CardQueue.Relearning],
      timing.learnCutoff,
      deckIds,
      cardSort.learning.dueFirst
    )
    return {
      new: newCards,
      review: reviewCards,
      learning: learningCards
    }
  }

  private static async gatherDueCards(collection: Collection) {
    const activeDeckIds = await collection.storage.deck.getActiveDeckIds()
    return this.gatherDueCardsForDecks(collection, activeDeckIds)
  }

  private static mixCards(newCards: Card[], reviewCards: Card[]) {
    const finalMix = Array.from(intersperse(reviewCards, newCards))
    return finalMix
  }
}
