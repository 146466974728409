import React, { createContext, useContext } from 'react'
import { Text, View } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { useRandom, useStyles } from '../../hooks'
import { Component, ComponentConstraint, GetPropsFromComponent } from '../../types'
import * as _styles from './Loader.styles'

export interface LoaderProps {
  loading: boolean
}

export const LoaderContext = createContext<boolean>(false)

export const Loader: Component<LoaderProps> = ({ loading, children }) => {
  return <LoaderContext.Provider value={loading}>{children}</LoaderContext.Provider>
}

export interface BaseLoadableProps {
  variant?: 'text' | 'block' | 'none'
  loadStyle?: Record<string, any>
  loadElement?: JSX.Element
  length?: number
  minLength?: number
  maxLength?: number
}

export type LoadableProps<Comp extends ComponentConstraint> = Omit<
  GetPropsFromComponent<Comp>,
  keyof BaseLoadableProps | 'component'
> &
  BaseLoadableProps & {
    component?: Comp
    children?: React.ReactNode | React.ReactNode[]
    class?: string
  }

export const Loadable = <Comp extends ComponentConstraint>({
  variant: _variant,
  loadStyle,
  loadElement,
  component,
  minLength = 6,
  maxLength = 9,
  length: _length,
  children: _children,
  ...others
}: LoadableProps<Comp>): JSX.Element => {
  const styles = useStyles(_styles)
  const loading = useContext(LoaderContext)
  const Comp = loading ? Animatable.View : component
  const variant = _variant ?? ((component as any) === Text ? 'text' : 'block')
  const randLength = useRandom(minLength, maxLength)
  const length = _length ?? randLength

  const loadStyles = [
    variant === 'text'
      ? {
          height: others.style?.lineHeight ?? others.style?.fontSize,
          backgroundColor: others.style?.color
        }
      : {},
    styles.loadable,
    styles[`loadable_${variant}`],
    { width: 10 * length }
  ]

  const props: LoadableProps<Comp> = {
    style: loading ? [...loadStyles, others.style, loadStyle] : others.style,
    iterationCount: 'infinite',
    direction: 'alternate',
    duration: 800,
    easing: 'ease-in-out',
    animation: {
      from: { opacity: 0.3 },
      to: { opacity: 0.6 }
    }
  } as unknown as LoadableProps<Comp>

  let children = _children
  if (
    loading &&
    (typeof _children || (Array.isArray(_children) && _children.some((a) => typeof a === 'string')))
  )
    children = undefined

  if (loadElement !== undefined && loading) return loadElement
  if (loading && Comp)
    return (
      <Comp {...others} {...props}>
        {children}
      </Comp>
    )
  if (!loading && Comp)
    return (
      <Comp {...others} {...props}>
        {children}
      </Comp>
    )
  if (!loading) return <>{children}</>
  return <></>
}

export type BaseLoadableParagraphProps = {
  minWords?: number
  maxWords?: number
  words?: number
}

export type LoadableParagraphProps<Comp extends ComponentConstraint> = Omit<
  GetPropsFromComponent<Comp>,
  keyof BaseLoadableProps | 'component'
> &
  LoadableProps<Comp> &
  BaseLoadableParagraphProps

export const LoadableParagraph = <Comp extends ComponentConstraint>({
  minWords = 4,
  maxWords = 7,
  words: _words,
  minLength = 5,
  maxLength = 8,
  ...others
}: LoadableParagraphProps<Comp>): JSX.Element => {
  const loading = useContext(LoaderContext)
  const styles = useStyles(_styles)
  const randNumWords = useRandom(minWords, maxWords)

  let words: number
  if (_words === undefined) words = randNumWords
  else words = _words

  if (loading)
    return (
      <View style={[styles.paragraph, others.style]}>
        {Array.from({ length: words }).map((_, i) => (
          <Loadable
            key={i}
            minLength={minLength}
            maxLength={maxLength}
            {...(others as any)}
            style={{ color: others.style?.color, fontSize: others.style?.fontSize }}
          />
        ))}
      </View>
    )
  return <Loadable {...(others as any)} />
}
