import { useState } from 'react'

export const useRandoms = (amount: number, min: number, max: number): number[] => {
  const getRandom = (): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  const [nums] = useState<number[]>(Array.from({ length: amount }).map(getRandom))

  return nums
}

export const useRandom = (min: number, max: number) => {
  return useRandoms(1, min, max)[0]
}
