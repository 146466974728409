import { Kysely } from 'kysely'
import { FieldValue } from '../../field-value/models'
import { Database } from '../types'

export class FieldValueStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }
  async getForNote(noteId: string, executor: Kysely<Database> = this.db): Promise<FieldValue[]> {
    const rows = await executor
      .selectFrom('field_value')
      .selectAll()
      .where('field_value.note_id', '=', noteId)
      .execute()
    if (!rows) {
      throw new Error('Field values not found')
    }
    return rows.map((row) => new FieldValue(row))
  }

  async create(fieldValue: FieldValue): Promise<void> {
    const results = await this.db
      .insertInto('field_value')
      .values(fieldValue.toDTO())
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert field value.')
    }
  }

  async createMultiple(fieldValues: FieldValue[]): Promise<void> {
    const results = await this.db
      .insertInto('field_value')
      .values(fieldValues.map((fv) => fv.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert field values.')
    }
  }
}
