import { Kysely } from 'kysely'
import { DatabaseSchema14 } from '../types'

export const upgradeConfigToSchema14 = async (db: Kysely<DatabaseSchema14>) => {
  const collection = await db.selectFrom('col').select('conf').executeTakeFirst()
  if (!collection) {
    throw new Error('Failed to get config')
  }
  const config: Record<string, any> = JSON.parse(collection.conf)
  for (const [key, val] of Object.entries(config)) {
    await db
      .insertInto('config')
      .values({
        key: key,
        usn: 0,
        mtime_secs: 0,
        val: Buffer.from(JSON.stringify(val), 'utf8')
      })
      .onConflict((oc) =>
        oc.column('key').doUpdateSet({
          usn: 0,
          mtime_secs: 0,
          val: Buffer.from(JSON.stringify(val), 'utf8')
        })
      )
      .execute()
  }
  await db.updateTable('col').set({ conf: '' }).execute()
}
