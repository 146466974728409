import React from 'react'
import { View, ViewProps } from 'react-native'
import { usePressAway, useStyles } from '../../hooks'
import { Component } from '../../types'
import * as _styles from './Dropdown.styles'

export type DropdownProps = ViewProps & {
  open: boolean
  onClose: () => void
}

const Dropdown: Component<DropdownProps> = ({ open, onClose, ...others }) => {
  const styles = useStyles(_styles)
  usePressAway(onClose)

  return (
    <View
      {...others}
      style={[styles.container, !open && styles.closed, others.style]}
      pointerEvents={!open ? 'none' : 'auto'}
    >
      {others.children}
    </View>
  )
}

export default Dropdown
