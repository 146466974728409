import { Card } from '../../card/models'
import { Review } from '../../review/models'

export interface FSRSParameters {
  weights: number[]
  retention: number
  maxInterval: number
}

export enum CompatabilityRating {
  Incorrect = 1,
  Correct = 3
}

export type FSRSState = {
  difficulty: number | null
  stability: number | null
}

export type ReviewLog = {
  review: Review
  card: Card
}
