import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import languages from '../../data/languages'
import { useStyles } from '../../hooks'
import { useUserStore } from '../../state/user'
import { Component, LoggedOutNavigationProp } from '../../types'
import Button from '../Button'
import { ScreenSlider, ScreenSlide } from '../ScreenSlider'
import Slider from '../Slider'

import * as _styles from './IntroSlider.styles'

const IntroSlider: React.FC = () => {
  return (
    <ScreenSlider>
      <TimeSlide />
      <LanguageSlide />
      <LanguageLevelSlide />
      <DeckChoiceSlide />
    </ScreenSlider>
  )
}

export default IntroSlider

const TimeSlide: Component = () => {
  const [numMinutes, setNumMinutes] = useState(15)
  const [t] = useTranslation('intro')

  return (
    <ScreenSlide
      title={t('questions.how-many-minutes-study')}
      style={{ justifyContent: 'center', flex: 1 }}
      disableBack
    >
      <Slider
        minimumValue={5}
        maximumValue={30}
        step={5}
        animationType="timing"
        value={numMinutes}
        onValueChange={(value) => setNumMinutes(value[0])}
      />
    </ScreenSlide>
  )
}

const LanguageSlide: Component = () => {
  const styles = useStyles(_styles)
  const { t } = useTranslation('intro')
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)

  return (
    <ScreenSlide
      title={t('questions.what-language')}
      disableContinue={!selectedLanguage}
      style={{ flex: 1 }}
    >
      <View style={styles.languageSelectContainer}>
        {languages.map((lang) => {
          const selected = selectedLanguage === lang.code
          return (
            <Button
              color={selected ? 'primary' : 'contrast'}
              style={styles.languageSelect}
              key={lang.code}
              onPress={() => setSelectedLanguage(lang.code)}
            >
              <View style={[styles.languageFlagContainer]}>
                <lang.flagIcon style={styles.languageFlag} />
              </View>
              <Text style={styles.languageName}>{t(lang.code as 'en', { ns: 'languages' })}</Text>
            </Button>
          )
        })}
      </View>
    </ScreenSlide>
  )
}

const languageLevels = ['beginner', 'intermediate', 'advanced'] as const

const LanguageLevelSlide: Component = () => {
  const styles = useStyles(_styles)
  const [selectedLevel, setSelectedLevel] = useState<string | null>(null)
  const [t] = useTranslation('intro')

  return (
    <ScreenSlide
      title={t('questions.what-level')}
      disableContinue={!selectedLevel}
      style={styles.languageLevelSlide}
    >
      {languageLevels.map((level) => {
        const selected = selectedLevel === level

        return (
          <Button
            color={selected ? 'primary' : 'contrast'}
            onPress={() => setSelectedLevel(level)}
            key={level}
          >
            {t(('levels.' + level) as any)}
          </Button>
        )
      })}
    </ScreenSlide>
  )
}

const deckChoices = ['recommended', 'create', 'import'] as const

const DeckChoiceSlide = () => {
  const styles = useStyles(_styles)
  const [selectedDeck, setSelectedDeck] = useState<'recommended' | 'create' | 'import' | null>(null)
  const navigation = useNavigation<LoggedOutNavigationProp>()
  const userStore = useUserStore()
  const [t] = useTranslation('intro')

  return (
    <ScreenSlide
      title={t('questions.what-deck')}
      disableContinue={selectedDeck === null}
      onContinue={() => {
        if (!selectedDeck) return false
        if (selectedDeck === 'import') return navigation.push('import-deck')
        userStore.setIsNewUser(false)
        return navigation.push('user')
      }}
      style={styles.languageLevelSlide}
    >
      {deckChoices.map((deck) => {
        const selected = selectedDeck === deck
        return (
          <Button
            color={selected ? 'primary' : 'contrast'}
            key={deck}
            onPress={() => setSelectedDeck(deck)}
          >
            {t(('deck-types.' + deck) as any)}
          </Button>
        )
      })}
    </ScreenSlide>
  )
}
