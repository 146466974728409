export type WorkerMessage = {
  call: string
  callId: number
  params: unknown[]
}

export type WorkerResponse<T = unknown> =
  | {
      callId: number
      error: unknown
      data: undefined
    }
  | {
      callId: number
      error: undefined
      data: T
    }

type AnyTuple = [any] | [any, any] | [any, any, any] | [any, any, any, any]

// Because workers run on posting events between worker and main thread
// we have to wrap that behaviour in order to use them like functions
export const wrapWorker = <WorkerFunctions extends Record<string, [AnyTuple, unknown]>>(
  worker: Worker
) => {
  let callId = 0

  type CallbackItem = {
    callId: number
    callback: (res: WorkerResponse) => void
  }
  let callbacks: CallbackItem[] = []

  worker.onmessage = (e: MessageEvent) => {
    let res = e.data as WorkerResponse

    for (const { callId, callback } of callbacks) {
      if (callId !== res.callId) continue
      callback(res)
      callbacks = callbacks.filter((cbItem) => cbItem.callId !== callId)
      break
    }
  }

  const executeWorkerFunction = <Func extends keyof WorkerFunctions & string>(
    func: Func,
    params: WorkerFunctions[Func][0]
  ): Promise<WorkerFunctions[Func][1]> => {
    const id = callId++
    const msg: WorkerMessage = {
      call: func,
      callId: id,
      params
    }
    worker.postMessage(msg)
    return new Promise((resolve, reject) => {
      callbacks.push({
        callId: id,
        callback: (res) => {
          if (res.error) return reject(res.error)
          resolve(res.data as WorkerFunctions[Func][1])
        }
      })
    })
  }
  return executeWorkerFunction
}
