import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const minimumTrack = (): StyleProp<ViewStyle> => ({
  height: 6
})

export const maximumTrack = (): StyleProp<ViewStyle> => ({
  height: 6
})

export const label = ({ background }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: background.card,
  paddingHorizontal: 4,
  paddingVertical: 4,
  borderRadius: 4,
  marginLeft: -20,
  width: 40
})

export const labelText = ({ fonts }: Theme): StyleProp<TextStyle> => ({
  ...fonts.textMain,
  textAlign: 'center'
})
