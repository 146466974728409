import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Navigation from '../components/Navigation'
import Settings from '../screens/Settings'
import { useTheme } from '../state/theme'
import { useUserStore } from '../state/user'
import { LoggedInParamsList, LoggedOutNavigationProp } from '../types'
import HomeNavigator from './HomeNavigator'

const Tab = createBottomTabNavigator<LoggedInParamsList>()

const LoggedInNavigator = () => {
  const { user } = useUserStore()
  const theme = useTheme()
  const navigation = useNavigation<LoggedOutNavigationProp>()
  const [t] = useTranslation(['common'])

  useEffect(() => {
    if (!user) setTimeout(() => navigation.navigate('login'), 1)
  }, [user])

  return (
    <Tab.Navigator
      initialRouteName="home"
      // Needs this because the tabBar isn't used like a component
      // just as a function that returns an element
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={(props) => <Navigation {...props} />}
      screenOptions={{
        headerTitleStyle: { fontSize: 24 },
        headerStyle: { height: 52, backgroundColor: theme.background.card }
      }}
    >
      <Tab.Screen
        name="home"
        options={{ title: t('button.home'), headerShown: false }}
        component={HomeNavigator}
      />
      <Tab.Screen name="settings" options={{ title: t('button.settings') }} component={Settings} />
    </Tab.Navigator>
  )
}

export default LoggedInNavigator
