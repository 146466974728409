import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useEffect } from 'react'
import { useUserStore } from '../../state/user'
import type { LoggedOutNavigationProp } from '../../types'
import TouchProvider from '../TouchProvider'

export type ScreenProps = {
  children: JSX.Element
  requiresLoggedIn?: boolean
  requiresLoggedOut?: boolean
}

const Screen = ({ children, requiresLoggedIn, requiresLoggedOut }: ScreenProps) => {
  const userStore = useUserStore()
  const navigation = useNavigation<LoggedOutNavigationProp>()

  useEffect(() => {
    setTimeout(() => {
      if (
        userStore.user &&
        userStore.user.is_email_verified === 0 &&
        navigation.getState().routes[0].name !== 'verify-email'
      )
        return navigation.navigate('verify-email')
      if (!userStore.user && requiresLoggedIn) return navigation.navigate('login')
      if (userStore.user && requiresLoggedOut) return navigation.navigate('user')
    })
  }, [userStore.user, requiresLoggedIn, requiresLoggedOut])

  return <TouchProvider>{children}</TouchProvider>
}

export default Screen
