import { Selectable } from 'kysely'
import { CardTable } from './tables'

export type AnkiCard = Selectable<CardTable>

export enum CardQueue {
  New = 0,
  Learn = 1,
  Review = 2,
  DayLearn = 3,
  PreviewRepeat = 4,
  Suspended = -1,
  SchedBuried = -2,
  UserBuried = -3
}
