import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Text } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useToast } from 'react-native-toast-notifications'
import { Button, Screen } from '../../components'
import { useInitialUrl } from '../../state/initialUrl'
import { useTheme } from '../../state/theme'
import { useUserStore } from '../../state/user'
import type { LoggedOutNavigationProp } from '../../types'
import { api } from '../../util'
import { useRequest } from '../../util/api.util'

const VerifyEmail = () => {
  const { fonts, colors } = useTheme()
  const { t } = useTranslation(['verify-email', 'common'])
  const { user, setUser, logout, updateUser } = useUserStore()
  const { initialUrl } = useInitialUrl()
  const toast = useToast()
  const navigation = useNavigation<LoggedOutNavigationProp>()

  const [_sendVerificationEmail, sendVerificationEmailRequest] = useRequest(
    api.sendVerificationEmail
  )
  const [_sendVerify, verificationRequest] = useRequest(api.verifyEmail)

  const [getUser, getUserRequest] = useRequest(api.getUser)

  const verifyEmail = async (token: string) => {
    try {
      await _sendVerify(token)
      if (user) updateUser({ is_email_verified: 1 })
      navigation.navigate('user')
      toast.show(t('email-verified'))
    } catch (err) {
      const error = api.getApiErrorMessage(err, t('common:error.verifying-email'))
      toast.show(error)
    }
  }

  const recheckVerification = async () => {
    if (!user) return
    try {
      const res = await getUser(user.id)
      setUser(res.data)
      navigation.navigate('user')
      toast.show(t('email-verified'))
    } catch (err) {
      const error = api.getApiError(err)
      const errorMessage = api.getApiErrorMessage(err, t('common:error.checking-verification'))
      if (error.code === 403 && error.message.toLowerCase().includes('verify')) {
        toast.show(t('email-not-verified'))
      } else {
        toast.show(errorMessage)
      }
    }
  }

  const sendVerificationEmail = async () => {
    if (!user) return
    try {
      await _sendVerificationEmail()
    } catch (err) {
      const error = api.getApiErrorMessage(err, t('common:error.sending-email'))
      toast.show(error)
    }
  }

  useEffect(() => {
    if (user?.is_email_verified === 1) {
      return navigation.navigate('user')
    }
  }, [user?.is_email_verified])

  useEffect(() => {
    if (initialUrl?.pathname !== '/verify-email') return
    const verificationToken = initialUrl.searchParams.get('token')
    if (!verificationToken) return
    verifyEmail(verificationToken)
  }, [initialUrl])

  return (
    <Screen requiresLoggedIn>
      <ScrollView
        contentContainerStyle={{
          paddingVertical: 60,
          paddingHorizontal: 24,
          gap: 24,
          minHeight: '100%',
          justifyContent: 'center'
        }}
      >
        <Text style={[fonts.titleRegular, { textAlign: 'center' }]}>{t('title')}</Text>
        <Text style={[fonts.caption, { marginTop: -16, textAlign: 'center' }]}>
          {verificationRequest.isFetching ? t('verifying-email') : t('caption')}
        </Text>
        {verificationRequest.isFetching ? (
          <ActivityIndicator size={64} color={colors.primary.main} />
        ) : (
          <>
            <Button
              variant="outlined"
              onPress={sendVerificationEmail}
              loading={sendVerificationEmailRequest.isFetching}
            >
              {t('resend-email')}
            </Button>
            <Button onPress={recheckVerification} loading={getUserRequest.isFetching}>
              {t('recheck')}
            </Button>
            <Button onPress={logout}>{t('common:button.logout')}</Button>
          </>
        )}
      </ScrollView>
    </Screen>
  )
}

export default VerifyEmail
