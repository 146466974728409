import React, { useEffect, useState } from 'react'
import { View, ViewProps } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { useStyles } from '../../hooks'

import * as _styles from './Collapse.styles'

export type CollapseProps = ViewProps & {
  children?: JSX.Element | JSX.Element[]
  expanded?: boolean
  innerStyle?: ViewProps['style']
}

const Collapse: React.FC<CollapseProps> = ({
  children,
  expanded = false,
  innerStyle,
  ...others
}) => {
  const styles = useStyles(_styles)

  const [height, setHeight] = useState<number | null>(null)
  const [measuring, setMeasuring] = useState(false)

  const style = {
    maxHeight: expanded ? height : 0
  }

  useEffect(() => {
    if (height !== null) return
    setMeasuring(true)
  }, [])

  return (
    <Animatable.View
      {...others}
      transition="maxHeight"
      duration={200}
      style={[styles.base, style, others.style]}
    >
      <View
        style={[
          { flexShrink: 0 },
          innerStyle,
          measuring ? { position: 'absolute', opacity: 0 } : {}
        ]}
        onLayout={(e) => {
          const newHeight = e.nativeEvent.layout.height
          if (newHeight > 0 && newHeight !== height) {
            setHeight(newHeight)
            setMeasuring(false)
          }
        }}
      >
        {children}
      </View>
    </Animatable.View>
  )
}

export default Collapse
