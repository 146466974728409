import common from './common.json'
import deckList from './deck-list.json'
import forgotPassword from './forgot-password.json'
import importDeck from './import.json'
import intro from './intro.json'
import languages from './languages.json'
import login from './login.json'
import passwordReset from './password-reset.json'
import progress from './progress.json'
import register from './register.json'
import settings from './settings.json'
import study from './study.json'
import verifyEmail from './verify-email.json'
import welcome from './welcome.json'

export default {
  common,
  welcome,
  login,
  register,
  'verify-email': verifyEmail,
  'forgot-password': forgotPassword,
  'password-reset': passwordReset,
  languages,
  intro,
  import: importDeck,
  progress,
  'deck-list': deckList,
  study,
  settings
}
