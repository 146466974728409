import { join } from 'path'
import { FileSystemHandler } from '../../interfaces/filesystem/types'

export const convertAnkiMedia = async (
  extractedPath: string,
  mediaFolderName: string,
  fsHandler: FileSystemHandler
): Promise<string[]> => {
  let addedFiles: string[] = []
  const mediaPath = join(fsHandler.getBasePath(), mediaFolderName)
  try {
    const mediaJsonStr = await fsHandler.readFile(join(extractedPath, 'media'), 'utf8')
    const mediaJson: Record<string, string> = JSON.parse(mediaJsonStr)

    let originalMediaJsonStr = await fsHandler
      .readFile(join(mediaPath, 'media.json'), 'utf8')
      .catch(() => {})
    if (!originalMediaJsonStr) {
      await fsHandler.mkdir(mediaPath).catch(() => {})
      await fsHandler.mkdir(join(mediaPath, 'files')).catch(() => {})
      originalMediaJsonStr = '{}'
    }
    const originalMediaJson: Record<string, string> = JSON.parse(originalMediaJsonStr)
    const reverseMediaMap: Record<string, string> = {}
    for (const [key, file] of Object.entries(originalMediaJson)) {
      reverseMediaMap[file] = key
    }

    let nextNum =
      Number.parseInt(
        Object.keys(originalMediaJson).sort(
          (a, b) => Number.parseFloat(a) - Number.parseFloat(b)
        )[0] ?? '-1'
      ) + 1

    for (let [key, file] of Object.entries(mediaJson)) {
      let newKey = key
      if (!reverseMediaMap[file]) {
        if (originalMediaJson[key]) newKey = (nextNum++).toString()
        originalMediaJson[newKey] = file
        addedFiles.push(newKey)
      } else newKey = reverseMediaMap[file]
      await fsHandler.copyFile(join(extractedPath, key), join(mediaPath, 'files', newKey))
    }
    await fsHandler.writeFile(join(mediaPath, 'media.json'), JSON.stringify(originalMediaJson))
    return addedFiles
  } catch (err) {
    for (const file of addedFiles) {
      await fsHandler.delete(join(mediaPath, 'files', file)).catch(() => {})
    }
    throw err
  }
}
