import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({}: Theme): ViewStyle => ({
  display: 'flex',
  padding: 16,
  paddingBottom: 32,
  flex: 1,
  gap: 16
})

export const headerContainer = ({}: Theme): ViewStyle => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16
})

export const headerText = ({ fonts }: Theme): ViewStyle => ({
  ...fonts.screenHeader,
  flex: 1,
  paddingRight: 16
})

export const fetchingContainer = ({}: Theme): ViewStyle => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1
})

export const cardsContainer = (): ViewStyle & Record<string, unknown> => ({
  position: 'relative',
  width: '100%',
  flex: 1,
  padding: 16,
  justifyContent: 'center',
  userSelect: 'none'
})

export const cardsWrapper = (): ViewStyle => ({
  position: 'relative',
  width: '100%',
  flex: 0.8,
  justifyContent: 'center',
  maxHeight: 500
})

export const behindCard = (): ViewStyle => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1
})

export const behindCardOff = (): ViewStyle => ({
  transform: [{ scale: 0.95 }, { translateY: -18 }]
})

export const behindCardPlaceholder = ({}: Theme): ViewStyle => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -2,
  transform: [{ scale: 0.95 }, { translateY: -18 }]
})

export const behindCardPlaceholderOff = ({}: Theme): ViewStyle => ({
  transform: [{ scale: 0.9 }, { translateY: -34 }]
})

export const buttonContainer = (): ViewStyle => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  paddingHorizontal: 32
})

export const card = ({ background }: Theme): ViewStyle => ({
  backgroundColor: background.card,
  borderRadius: 16,
  flex: 1,
  overflow: 'hidden',
  borderColor: background.cardLight,
  borderWidth: 2
})

export const congratulationsContainer = (): ViewStyle => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center'
})

export const emptyText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 32,
  textAlign: 'center',
  fontWeight: 'bold'
})

export const congratulationsAnimation = (): ViewStyle => ({
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  zIndex: 100
})
