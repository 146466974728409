import { useEffect, useState } from 'react'
import { Deck } from '../../../packages/langki/deck/models'
import { useLangkiStorage } from './useLangkiStorage'

export type UseDeckResponse = {
  deck: Deck | null
  fetching: boolean
  error: unknown
}

export const useDeck = (deckId: string): UseDeckResponse => {
  const [deck, setDeck] = useState<Deck | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const { storage } = useLangkiStorage()

  const getDeck = async (unmounted: { current: boolean }) => {
    if (!storage) return
    try {
      setFetching(true)
      const deck = await storage.deck.get(deckId)
      if (unmounted.current) return
      setDeck(deck)
    } catch (err) {
      if (unmounted.current) return
      setError(err)
    }
    setFetching(false)
  }

  useEffect(() => {
    let unmounted = { current: false }
    getDeck(unmounted)
    return () => {
      unmounted.current = true
    }
  }, [deckId, storage])

  return {
    deck,
    fetching,
    error
  }
}
