import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import themes from '../themes'
import { createStorage } from './storage'

export type ThemeStore = {
  themeKey: keyof typeof themes
  setTheme: (newTheme: keyof typeof themes) => void
}

const THEME_STORE_VERSION = 2

export const useThemeStore = create<ThemeStore>()(
  persist<ThemeStore>(
    (set) => ({
      themeKey: 'dark',
      setTheme: (themeKey: keyof typeof themes) => {
        set({ themeKey })
      }
    }),
    {
      name: 'theme-storage',
      storage: createStorage<ThemeStore>(),
      version: THEME_STORE_VERSION
    }
  )
)

export const useTheme = () => {
  const themeStore = useThemeStore()

  return themes[themeStore.themeKey]
}
