import { NoteTypeType } from './types'

export class NoteType {
  public readonly id: string
  public name: string
  public css: string
  public type: number
  public questionFormat: string
  public answerFormat: string
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: NoteTypeType) {
    this.id = data.id
    this.name = data.name
    this.css = data.css
    this.type = data.type
    this.questionFormat = data.question_format
    this.answerFormat = data.answer_format
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): NoteTypeType {
    return {
      id: this.id,
      name: this.name,
      css: this.css,
      type: this.type,
      question_format: this.questionFormat,
      answer_format: this.answerFormat,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
