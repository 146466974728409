import { useNavigation } from '@react-navigation/native'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View, ViewProps } from 'react-native'
import { DeckType, DueCountsQuery } from '../../../packages/langki/deck/types'
import { useStyles } from '../../hooks'

import DropdownIcon from '../../svg/Dropdown'
import { Component, HomeNavigatorProp } from '../../types'
import { shortenString } from '../../util'
import Button from '../Button'
import DropdownMenu from '../DropdownMenu'
import IconButton from '../IconButton'
import { Loadable, LoadableParagraph, LoaderContext } from '../Loader'
import * as _styles from './DeckPreview.styles'

export type DeckPreviewProps = ViewProps & {
  deck: DeckType
  dueCounts?: DueCountsQuery
  onDelete: () => void
}

export const DeckPreview: Component<DeckPreviewProps> = ({
  deck,
  dueCounts,
  onDelete,
  ...others
}) => {
  const styles = useStyles(_styles)
  const [menuOpen, setMenuOpen] = useState(false)
  const loading = useContext(LoaderContext)
  const navigation = useNavigation<HomeNavigatorProp>()
  const [t] = useTranslation(['deck-list', 'common'])

  return (
    <View {...others} style={[styles.container, others.style]}>
      <LoadableParagraph
        minWords={2}
        maxWords={3}
        component={Text}
        minLength={8}
        maxLength={12}
        style={styles.nameLabel}
      >
        {deck.name}
      </LoadableParagraph>
      <LoadableParagraph component={Text} style={styles.description}>
        {shortenString(deck.description || t('preview.no-desc'), 80)}
      </LoadableParagraph>
      <Loadable component={Text} style={styles.cardCount}>
        {t('preview.cards', { count: dueCounts?.total ?? 0 })}
      </Loadable>
      <View style={{ flexDirection: 'row', gap: 8 }}>
        <Loadable component={Text} length={4} style={styles.cardCount}>
          {t('preview.new', { count: dueCounts?.total ?? 0 })}
        </Loadable>
        <Loadable component={Text} length={4} style={styles.cardCount}>
          {t('preview.review', { count: dueCounts?.total ?? 0 })}
        </Loadable>
      </View>
      {!loading && (
        <>
          <Button
            size="compact"
            style={styles.studyButton}
            variant="outlined"
            onPress={() => navigation.navigate('study', { deckId: deck.id })}
            disabled={dueCounts?.total === 0}
          >
            {t('common:button.study')}
          </Button>
          <View style={styles.dropdownContainer} onTouchEnd={(e) => e.stopPropagation()}>
            <IconButton
              onPress={() => setMenuOpen((open) => !open)}
              onPressOut={(e) => e.stopPropagation()}
              icon={DropdownIcon}
              style={styles.dropdownButton}
            />
            <DropdownMenu
              style={styles.dropdown}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              items={[{ text: t('common:button.delete'), onPress: onDelete }]}
            />
          </View>
        </>
      )}
    </View>
  )
}
