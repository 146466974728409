import { SvgProps } from 'react-native-svg'
import { Chinese, English, French, German, Italian, Japanese, Korean, Spanish } from '../svg/flags'

export type Language = {
  code: string
  flagIcon: (props: SvgProps) => JSX.Element
}

export const languages: Language[] = [
  { code: 'en', flagIcon: English },
  { code: 'zh', flagIcon: Chinese },
  { code: 'fr', flagIcon: French },
  { code: 'de', flagIcon: German },
  { code: 'it', flagIcon: Italian },
  { code: 'ja', flagIcon: Japanese },
  { code: 'ko', flagIcon: Korean },
  { code: 'es', flagIcon: Spanish }
]

export default languages
