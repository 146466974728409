import { Card } from '../card/models'
import { Collection } from '../collection/models'
import { DueCountsQuery } from '../deck/types'
import { NewCardsStudiedToday } from '../storage/review/types'

export class DeckLimits {
  collection: Collection

  constructor(collection: Collection) {
    this.collection = collection
  }

  private async getGlobalNewCardLimit() {
    return (await this.collection.storage.config.get()).maxNewCardsPerDay
  }

  private async calculateRemainingNewCards(
    globalLimit: number,
    newCardsStudiedToday: NewCardsStudiedToday[]
  ): Promise<number> {
    const studiedTodayTotal = newCardsStudiedToday.reduce(
      (acc, val) => acc + val.new_cards_studied_today,
      0
    )
    return globalLimit - studiedTodayTotal
  }

  private async getNewCardsStudiedToday(): Promise<NewCardsStudiedToday[]> {
    const timing = await this.collection.getSchedulerTimingToday()
    return await this.collection.storage.review.getNewCardsStudiedToday(timing.previousDayAt)
  }

  private filterAndAssignNewCards(
    newCards: Card[],
    deckLimits: Record<string, number>,
    newCardsRemaining: number
  ): Card[] {
    return newCards.filter((card) => {
      const deckLimit = deckLimits[card.deckId]
      if (newCardsRemaining > 0 && deckLimit > 0) {
        deckLimits[card.deckId]--
        newCardsRemaining--
        return true
      }
      return false
    })
  }

  private filterAndAssignNewCounts(
    counts: DueCountsQuery[],
    deckLimits: Record<string, number>,
    newCardsRemaining: number
  ) {
    return counts.map((count) => {
      const deckLimit = deckLimits[count.id]
      const newCount = Math.min(deckLimit, count.new, newCardsRemaining)
      deckLimits[count.id] -= newCount
      newCardsRemaining -= newCount
      const difference = count.new - newCount
      return { ...count, new: newCount, total: count.total - difference }
    })
  }

  private async calculateDeckLimits(newCardsStudiedToday: NewCardsStudiedToday[]) {
    const decks = await this.collection.storage.deck.getActive()
    const deckConfigs = await this.collection.storage.deck.config.getActive()
    const deckLimits: Record<string, number> = {}
    for (const deckConfig of deckConfigs) {
      const deck = decks.find((d) => d.configId === deckConfig.id)
      if (!deck) continue
      const maxNewCardsPerDay = deckConfig.maxNewCardsPerDay || 0
      const alreadyStudied =
        newCardsStudiedToday.find((row) => row.deck_id === deck.id)?.new_cards_studied_today || 0
      deckLimits[deck.id] = Math.max(0, maxNewCardsPerDay - alreadyStudied)
    }
    return deckLimits
  }

  public async applyNewCardLimitsToCards(newCards: Card[]): Promise<Card[]> {
    const globalLimit = await this.getGlobalNewCardLimit()
    if (globalLimit < 0) return newCards

    const newCardsStudiedToday = await this.getNewCardsStudiedToday()
    let newCardsRemaining = await this.calculateRemainingNewCards(globalLimit, newCardsStudiedToday)
    if (newCardsRemaining <= 0) return []

    const deckLimits = await this.calculateDeckLimits(newCardsStudiedToday)
    return this.filterAndAssignNewCards(newCards, deckLimits, newCardsRemaining)
  }

  public async applyNewCardLimitsToCounts(counts: DueCountsQuery[]): Promise<DueCountsQuery[]> {
    const globalLimit = await this.getGlobalNewCardLimit()
    if (globalLimit < 0) return counts

    const newCardsStudiedToday = await this.getNewCardsStudiedToday()
    let newCardsRemaining = await this.calculateRemainingNewCards(globalLimit, newCardsStudiedToday)
    if (newCardsRemaining <= 0) {
      return counts.map((count) => {
        return { ...count, new: 0, total: count.total - count.new }
      })
    }
    const deckLimits = await this.calculateDeckLimits(newCardsStudiedToday)
    return this.filterAndAssignNewCounts(counts, deckLimits, newCardsRemaining)
  }
}
