import { Field } from '../field/models'
import { FieldValue } from '../field-value/models'
import { LangkiRendererTransformer, LangkiRendererTransformerArgs } from './langki-renderer'
import { getHintText, rubyTextTransformer } from './ruby-text-transformer'

const fieldRegexp = /\{\{.+?\}\}/

const specialFields: Record<string, (args: LangkiRendererTransformerArgs) => string> = {
  FrontSide: (args) => args.frontSideHtml ?? ''
}

const getField = (
  key: string,
  fields: Field[],
  fieldValues: FieldValue[],
  args: LangkiRendererTransformerArgs
): string | null => {
  const specialField = specialFields[key]
  if (specialField) return specialField(args)

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    if (field.name === key)
      return fieldValues.find((val) => val.fieldId === field.id)?.value ?? null
  }
  return null
}

const modifiers: Record<
  string,
  (str: string, args: LangkiRendererTransformerArgs) => string | Promise<string>
> = {
  furigana: async (str, args) => {
    let target = rubyTextTransformer.getTransformTarget(str)
    while (target) {
      str = await rubyTextTransformer.transform(target, str, args, undefined)
      target = rubyTextTransformer.getTransformTarget(str)
    }
    return str
  },
  kanji: (str) => {
    let target = rubyTextTransformer.getTransformTarget(str)
    while (target) {
      const hintRes = getHintText(target.str, target.index, str)
      str = str.replace(hintRes.fullText, hintRes.prevText)
    }
    return str
  },
  katakana: (str) => {
    let target = rubyTextTransformer.getTransformTarget(str)
    while (target) {
      const hintRes = getHintText(target.str, target.index, str)
      str = str.replace(hintRes.fullText, hintRes.rubyTextInner)
    }
    return str
  }
}

export const applyFieldModifier = async (
  modifier: string,
  target: string,
  args: LangkiRendererTransformerArgs
): Promise<string> => {
  const modifierFunc = modifiers[modifier]
  if (!modifierFunc) return target
  target = await modifierFunc(target, args)
  return target
}

export const fieldTransformer: LangkiRendererTransformer = {
  getTransformTarget: (html) => {
    let match = fieldRegexp.exec(html)
    if (!match) return null
    return {
      index: match.index,
      str: match[0]
    }
  },
  transform: async (transformTarget, html, args) => {
    const fields = args.cardRender.fields
    const fieldValues = args.cardRender.fieldValues
    const match = transformTarget.str
    const index = transformTarget.index

    let modifier: string | null = null
    let fieldKey = transformTarget.str.replaceAll(/\{|\}/g, '')
    if (fieldKey.includes(':')) {
      const split = fieldKey.split(':')
      modifier = split[0]
      fieldKey = split[1]
    }

    const fieldRes = getField(fieldKey, fields, fieldValues, args)
    if (fieldRes === null) {
      html = html.substring(0, index) + html.substring(index + match.length, html.length)
      return html
    }
    let value = fieldRes
    if (modifier) value = await applyFieldModifier(modifier, value, args)

    html = html.substring(0, index) + value + html.substring(index + match.length, html.length)

    return html
  },
  setup: undefined
}
