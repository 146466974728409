import base64 from 'base-64'
import utf8 from 'utf8'

export const omit = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (keys.includes(key as any)) return acc
    acc[key] = value
    return acc
  }, {} as Record<string, unknown>) as Omit<T, K>
}

export const pick = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!keys.includes(key as any)) return acc
    acc[key] = value
    return acc
  }, {} as Record<string, unknown>) as Pick<T, K>
}

export const base64Encode = (str: string): string => {
  const bytes = utf8.encode(str)
  const encoded = base64.encode(bytes)
  return encoded
}

export const base64Decode = (str: string): string => {
  const bytes = base64.decode(str)
  const decoded = utf8.decode(bytes)
  return decoded
}
