import { Kysely } from 'kysely'
import { AnkiReview } from '../../../anki/review/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { ReviewFactory as LangkiReviewFactory } from '../../review/factories'
import { Review } from '../../review/models'
import { LangkiStorage } from '../../storage/models'

export class ReviewConverter {
  private langkiDb: LangkiStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(langkiDb: LangkiStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.langkiDb = langkiDb
    this.ankiDb = ankiDb
  }

  async insert(langkiReviewsBatch: Review[]) {
    await this.langkiDb.review.createMultiple(langkiReviewsBatch)
  }

  private async processBatch(
    batch: AnkiReview[],
    cardIdMap: Record<number, string>
  ): Promise<void> {
    const langkiReviewsBatch: Review[] = []
    for (const review of batch) {
      const langkiReview = LangkiReviewFactory.createFromAnkiDeck(review, cardIdMap)
      langkiReviewsBatch.push(langkiReview)
    }
    await this.insert(langkiReviewsBatch)
  }

  async convert(cardIdMap: Record<number, string>): Promise<void> {
    const batchSize = 500
    const ankiReviews = await this.ankiDb.selectFrom('revlog').selectAll().execute()
    for (let i = 0; i < ankiReviews.length; i += batchSize) {
      const batch = ankiReviews.slice(i, i + batchSize)
      await this.processBatch(batch, cardIdMap)
    }
  }
}
