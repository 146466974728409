import { DEFAULT_NEW_CARDS_PER_DAY, LEARN_AHEAD_SECONDS } from './constants'
import { ConfigType } from './types'

export class Config {
  public readonly id: string
  public maxNewCardsPerDay: number
  public maxReviewCardsPerDay: number | null
  public timezone: string
  public learnAheadSeconds: number
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: ConfigType) {
    this.id = data.id
    this.maxNewCardsPerDay = data.max_new_cards_per_day ?? DEFAULT_NEW_CARDS_PER_DAY
    this.maxReviewCardsPerDay = data.max_review_cards_per_day
    this.learnAheadSeconds = LEARN_AHEAD_SECONDS
    this.timezone = data.timezone
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): ConfigType {
    return {
      id: this.id,
      max_new_cards_per_day: this.maxNewCardsPerDay,
      max_review_cards_per_day: this.maxReviewCardsPerDay,
      timezone: this.timezone,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
