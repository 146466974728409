import { Kysely } from 'kysely'

export async function up(db: Kysely<any>) {
  await db.schema
    .createTable('collection')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('schema_updated_at', 'integer', (col) => col.notNull())
    .addColumn('schema_version', 'integer', (col) => col.notNull())
    .addColumn('last_synced_at', 'integer')
    .addColumn('scheduler_init_date', 'integer', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('deck')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('name', 'text', (col) => col.notNull())
    .addColumn('description', 'text', (col) => col.notNull())
    .addColumn('config_id', 'text', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('deck_config')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('active', 'integer', (col) => col.notNull())
    .addColumn('max_new_cards_per_day', 'integer')
    .addColumn('max_review_cards_per_day', 'integer')
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema.createIndex('deck_config_active_idx').on('deck_config').column('active').execute()

  await db.schema
    .createTable('config')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('max_new_cards_per_day', 'integer')
    .addColumn('max_review_cards_per_day', 'integer')
    .addColumn('timezone', 'text', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('note_type')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('name', 'text', (col) => col.notNull())
    .addColumn('css', 'text', (col) => col.notNull())
    .addColumn('question_format', 'text', (col) => col.notNull())
    .addColumn('answer_format', 'text', (col) => col.notNull())
    .addColumn('type', 'integer', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('template')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('note_type_id', 'text', (col) => col.notNull())
    .addColumn('name', 'text', (col) => col.notNull())
    .addColumn('question_format', 'text', (col) => col.notNull())
    .addColumn('answer_format', 'text', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('field')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('note_type_id', 'text', (col) => col.notNull())
    .addColumn('ordinal', 'integer', (col) => col.notNull())
    .addColumn('name', 'text', (col) => col.notNull())
    .addColumn('description', 'text', (col) => col.notNull())
    .addColumn('font', 'text', (col) => col.notNull())
    .addColumn('size', 'integer', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('field_value')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('note_id', 'text', (col) => col.notNull())
    .addColumn('field_id', 'text', (col) => col.notNull())
    .addColumn('value', 'text', (col) => col.notNull())
    .addColumn('value_checksum', 'integer', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('note')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('note_type_id', 'text', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createTable('card')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('note_id', 'text', (col) => col.notNull())
    .addColumn('deck_id', 'text', (col) => col.notNull())
    .addColumn('ordinal', 'integer', (col) => col.notNull())
    .addColumn('type', 'integer', (col) => col.notNull())
    .addColumn('due', 'integer', (col) => col.notNull())
    .addColumn('interval', 'integer', (col) => col.notNull())
    .addColumn('reps', 'integer', (col) => col.notNull())
    .addColumn('lapses', 'integer', (col) => col.notNull())
    .addColumn('left', 'integer', (col) => col.notNull())
    .addColumn('fsrs_stability', 'real')
    .addColumn('fsrs_difficulty', 'real')
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()

  await db.schema
    .createIndex('card_deck_id_type_idx')
    .on('card')
    .columns(['deck_id', 'type'])
    .execute()

  await db.schema.createIndex('card_due_idx').on('card').column('due').execute()

  await db.schema
    .createIndex('card_due_ordinal_idx')
    .on('card')
    .columns(['due', 'ordinal'])
    .execute()

  await db.schema
    .createTable('grave')
    .addColumn('original_id', 'text', (col) => col.notNull())
    .addColumn('type', 'integer', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .addPrimaryKeyConstraint('original_id_type_pk', ['original_id', 'type'])
    .execute()

  await db.schema
    .createTable('review')
    .addColumn('id', 'text', (col) => col.primaryKey())
    .addColumn('card_id', 'text', (col) => col.notNull())
    .addColumn('interval', 'integer', (col) => col.notNull())
    .addColumn('time_taken', 'integer', (col) => col.notNull())
    .addColumn('type', 'integer', (col) => col.notNull())
    .addColumn('platform', 'integer', (col) => col.notNull())
    .addColumn('fsrs_stability', 'real')
    .addColumn('fsrs_difficulty', 'real')
    .addColumn('rating', 'integer', (col) => col.notNull())
    .addColumn('left', 'integer', (col) => col.notNull())
    .addColumn('due', 'integer', (col) => col.notNull())
    .addColumn('created_at', 'integer', (col) => col.notNull())
    .addColumn('updated_at', 'integer', (col) => col.notNull())
    .execute()
}

export async function down(db: Kysely<any>) {
  await db.schema.dropTable('collection').execute()
  await db.schema.dropTable('deck').execute()
  await db.schema.dropTable('deck_config').execute()
  await db.schema.dropTable('config').execute()
  await db.schema.dropTable('note_type').execute()
  await db.schema.dropTable('template').execute()
  await db.schema.dropTable('field').execute()
  await db.schema.dropTable('field_value').execute()
  await db.schema.dropTable('note').execute()
  await db.schema.dropTable('card').execute()
  await db.schema.dropTable('grave').execute()
  await db.schema.dropTable('review').execute()
}
