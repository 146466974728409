import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const button = ({ colors, radius }: Theme): StyleProp<TextStyle> => ({
  display: 'flex',
  position: 'relative',
  backgroundColor: colors.primary.main,
  height: 60,
  borderRadius: radius.button,
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
})

export const pressing = ({ colors }: Theme): ViewStyle => ({
  position: 'absolute',
  backgroundColor: colors.input.active,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none'
})

export const button_size_compact = ({ radius }: Theme): StyleProp<TextStyle> => ({
  height: 40,
  padding: 0,
  borderRadius: radius.buttonCompact
})

export const buttonIconOnly = (): StyleProp<TextStyle> => ({
  paddingRight: 30,
  paddingLeft: 30
})

export const buttonText = ({ fonts }: Theme): StyleProp<TextStyle> => ({
  ...fonts.textMain,
  fontSize: 20,
  textAlign: 'center',
  fontWeight: 'bold',
  padding: 10
})

export const text_size_compact = (): StyleProp<TextStyle> => ({
  paddingHorizontal: 12,
  paddingVertical: 0,
  fontSize: 18
})

export const button_icon_contained_compact = (): StyleProp<ViewStyle> => ({
  borderRadius: 999,
  width: 48,
  height: 48
})

export const button_icon_contained_default = (): StyleProp<ViewStyle> => ({
  borderRadius: 999,
  width: 56,
  height: 56
})

export const button_icon_contained_large = (): StyleProp<ViewStyle> => ({
  borderRadius: 999,
  width: 64,
  height: 64
})

export const button_contained_primary = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.primary.main
})

export const button_contained_error = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.error.main
})

export const button_icon_contained_primary = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.primary.main
})

export const button_icon_contained_error = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.error.main
})

export const button_icon_contained_success = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.success.main
})

export const text_icon_contained_contrast = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.base
})

export const text_icon_contained_error = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.error.contrastText
})

export const text_icon_contained_success = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.success.contrastText
})

export const button_icon_contained_contrast = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.contrast
})

export const button_outlined_primary = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  borderColor: colors.primary.main
})

export const button_outlined_error = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  borderColor: colors.error.main
})

export const button_outlined_contrast = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  borderColor: colors.contrast
})

export const button_outlined_disabled = ({ colors }: Theme): ViewStyle => ({
  backgroundColor: 'transparent',
  borderColor: colors.component.disabled
})

export const text_outlined_disabled = ({ colors }: Theme): TextStyle => ({
  color: colors.component.disabled
})

export const button_contained_contrast = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.contrast
})

export const disabled = ({ colors }: Theme): StyleProp<ViewStyle> => ({
  backgroundColor: colors.component.disabled
})

export const button_outlined = (): StyleProp<ViewStyle> => ({
  borderWidth: 2,
  backgroundColor: 'transparent'
})

export const button_contained = () => ({})

export const text_primary = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.primary.contrastText
})

export const text_contained_error = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.error.contrastText
})

export const text_contained_contrast = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.base
})

export const text_outlined_primary = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.primary.main
})

export const text_outlined_error = ({ colors }: Theme): StyleProp<TextStyle> => ({
  color: colors.error.main
})

export const buttonTextWithIcon_left = (): StyleProp<TextStyle> => ({
  marginRight: 'auto'
})

export const buttonTextWithIcon_right = (): StyleProp<TextStyle> => ({
  marginLeft: 'auto'
})

export const buttonWithIcon_right = (): StyleProp<ViewStyle> => ({
  paddingLeft: 42
})

export const buttonWithIcon_left = (): StyleProp<ViewStyle> => ({
  paddingRight: 42
})

export const icon = (): StyleProp<ViewStyle & TextStyle> => ({
  height: 32,
  width: 32,
  marginVertical: -6
})

export const icon_left = (): StyleProp<ViewStyle & TextStyle> => ({
  marginRight: 'auto',
  marginLeft: 10
})

export const icon_right = (): StyleProp<ViewStyle & TextStyle> => ({
  marginRight: 10,
  marginLeft: 'auto'
})

export const iconOnly = (): StyleProp<ViewStyle & TextStyle> => ({
  margin: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto'
})

export const icon_contained_error = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: colors.error.contrastText
})

export const icon_contained_primary = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: colors.primary.contrastText
})

export const icon_outlined_error = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: colors.error.main
})

export const icon_outlined_primary = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: colors.primary.main
})

export const icon_outlined_contrast = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: colors.contrast
})
