import { Link } from '@react-navigation/native'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, ScrollView } from 'react-native'
import { Input, Button, FormLabel } from '../../components'
import Screen from '../../components/Screen'
import { useTheme } from '../../state/theme'
import { useUserStore } from '../../state/user'
import { api } from '../../util'
import { useRequest } from '../../util/api.util'

// import ScrollView from 'react-native-gesture-handler'

const Register = () => {
  const { fonts } = useTheme()
  const { t } = useTranslation(['register', 'common'])

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [sendRegister, registerRequest] = useRequest(api.register)
  const userStore = useUserStore()

  const register = async () => {
    try {
      const res = await sendRegister({
        password,
        name: username,
        email
      })
      userStore.setUserResponse(res.data)
    } catch (err) {
      console.error((err as AxiosError).response?.data)
    }
  }

  return (
    <Screen requiresLoggedOut>
      <ScrollView
        contentContainerStyle={{
          paddingVertical: 40,
          paddingHorizontal: 24,
          minHeight: '100%',
          gap: 24
        }}
      >
        <Text style={[fonts.titleRegular, { textAlign: 'center' }]}>{t('title')}</Text>
        <Text style={[fonts.caption, { marginTop: -16, textAlign: 'center' }]}>{t('caption')}</Text>
        <FormLabel label={t('common:input.email')}>
          <Input
            placeholder={t('common:placeholder.email')}
            value={email}
            onChange={(e) => setEmail(e.nativeEvent.text)}
          />
        </FormLabel>
        <FormLabel label={t('common:input.username')}>
          <Input
            placeholder={t('common:placeholder.username')}
            value={username}
            onChange={(e) => setUsername(e.nativeEvent.text)}
          />
        </FormLabel>
        <FormLabel label={t('common:input.password')} style={{ marginBottom: 'auto' }}>
          <Input
            placeholder={t('common:placeholder.password')}
            value={password}
            onChange={(e) => setPassword(e.nativeEvent.text)}
            secureTextEntry
          />
        </FormLabel>
        <Button onPress={register} loading={registerRequest.isFetching}>
          {t('common:button.create-account')}
        </Button>
        <Link
          to="/login"
          style={[fonts.textMain, { textAlign: 'center', padding: 10, marginTop: -10 }]}
        >
          {t('already-have-account')}{' '}
          <Text style={{ fontWeight: 'bold' }}>{t('login-existing')}</Text>
        </Link>
      </ScrollView>
    </Screen>
  )
}

export default Register
