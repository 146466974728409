import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const page = ({}: Theme): StyleProp<ViewStyle> => ({
  position: 'relative',
  padding: 16,
  flex: 1,
  justifyContent: 'center',
  gap: 20
})

export const stepContainer = ({}: Theme): StyleProp<TextStyle> => ({
  gap: 8
})

export const title = ({ fonts }: Theme): StyleProp<TextStyle> => ({
  ...fonts.textMain,
  fontSize: 20
})

export const bold = (): StyleProp<TextStyle> => ({
  fontWeight: 'bold'
})

export const textInfo = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  lineHeight: 22
})

export const progressText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 18,
  lineHeight: 22,
  textAlign: 'center'
})

export const progressContainer = (): ViewStyle => ({
  gap: 8,
  marginBottom: -80,
  height: 80,
  transform: [{ translateY: 30 }]
})
