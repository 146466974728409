import { ProgressHandler, TextCategory } from './types'

export const msgCategoryMap: Record<TextCategory, [string, string][]> = {
  random: [
    ['Initializing premium AI', 'random.initialize-ai'],
    ['Integrating global dictionaries', 'random.integrate-global-dict'],
    ['Generating lexicography', 'random.generate-lexicography']
  ]
}

export type ProgressHandlerTextReturn = {
  text: string
  code?: string
}

export class GenericProgressHandler implements ProgressHandler {
  private totalNum = 0
  private currentNum = 0
  private text: string | null = null
  private textCode: string | null = null
  private textCategory: TextCategory = 'random'
  private onProgressChangeCallbackFunc: ((newProgressFrac: number) => void) | null = null
  private onTextChangeCallbackFunc: ((newTextObj: ProgressHandlerTextReturn) => void) | null = null

  constructor() {}

  hasTotal() {
    return this.totalNum > 0
  }

  getFraction() {
    if (this.totalNum === 0) return 0
    return this.currentNum / this.totalNum
  }

  setTotal(total: number) {
    this.totalNum = total
  }

  addProgress(progress = 1) {
    this.currentNum = Math.max(Math.min(this.currentNum + progress, this.totalNum), 0)
    this.text = null
    this.textCode = null
    this.textCategory = 'random'
    this.onProgressChangeCallbackFunc?.(this.getFraction())
  }

  onProgressChange(func: (newProgressFrac: number) => void) {
    this.onProgressChangeCallbackFunc = func
  }

  onTextChange(func: (newTextObj: ProgressHandlerTextReturn) => void) {
    this.onTextChangeCallbackFunc = func
  }

  setText(text: string, code?: string) {
    this.text = text
    this.textCode = code ?? null
    this.onTextChangeCallbackFunc?.(this.getText())
  }

  getRandomText() {
    const randomStrings = msgCategoryMap[this.textCategory]
    const randIndex = Math.random() * randomStrings.length

    return randomStrings[randIndex]
  }

  getText(): ProgressHandlerTextReturn {
    if (this.text) {
      return {
        text: this.text,
        code: this.textCode ?? undefined
      }
    }
    let [text, code] = this.getRandomText()
    return {
      text,
      code
    }
  }

  setTextCategory(textCategory: TextCategory) {
    this.textCategory = textCategory
    this.onTextChangeCallbackFunc?.(this.getText())
  }
}
