import React from 'react'
import { Pressable, Text } from 'react-native'
import { useStyles } from '../../hooks'
import { Component } from '../../types'
import Dropdown, { DropdownProps } from '../Dropdown'
import * as _styles from './DropdownMenu.styles'

export type MenuItem = {
  text: string
  onPress: () => void
}

export type DropdownMenuProps = Omit<DropdownProps, 'children'> & {
  items: MenuItem[]
}

const DropdownMenu: Component<DropdownMenuProps> = ({ items, ...others }) => {
  const styles = useStyles(_styles)

  return (
    <Dropdown {...others} style={[styles.container, others.style]}>
      {items.map((item) => (
        <Pressable
          key={item.text}
          style={styles.button}
          onPress={() => {
            item.onPress()
            others.onClose()
          }}
        >
          <Text style={styles.buttonText}>{item.text}</Text>
        </Pressable>
      ))}
    </Dropdown>
  )
}

export default DropdownMenu
