import { FSRSParameters } from './types'

export const DEFAULT_FSRS_PARAMS: FSRSParameters = {
  // weights: Adjusts various aspects of the spaced repetition algorithm.
  // [0.4, 2.4]: Correspond to initial stability for the "again" and "good" ratings respectively.
  // [4.93, 0.94, 0.86]: Used for calculating initial difficulty after the first rating.
  // [0.01, 1.49, 0.14, 0.94, 2.18, 0.05, 0.34, 1.26, 0.29, 2.61]: Influence the new stability
  // calculation after a successful review, taking into account factors like previous difficulty,
  // stability, retrievability, and the user's rating.
  weights: [
    0.4, 0.6, 2.4, 5.8, 4.93, 0.94, 0.86, 0.01, 1.49, 0.14, 0.94, 2.18, 0.05, 0.34, 1.26, 0.29, 2.61
  ],
  // weights: [
  //   0.5614, 1.2546, 3.5878, 7.9731, 5.1043, 1.1303, 0.823, 0.0465, 1.629, 0.135, 1.0045, 2.132,
  //   0.0839, 0.3204, 1.3547, 0.219, 2.7849
  // ],
  // Target retention rate, the desired probability of recalling a fact just before the next review.
  retention: 0.87,
  // Maximum interval in days between reviews for a flashcard (default is 100 years).
  maxInterval: 36500
}

// Time in minutes for each step in the learning and relearning queues.
// A step is the time between each review until the card is considered learned.
export const DEFAULT_STEPS = {
  learning: [10, 1],
  relearning: [10]
}
