import { Theme as ThemeType } from './base.theme'
import darkTheme from './dark.theme'
import lightTheme from './light.theme'

export const themes = {
  [darkTheme.key]: darkTheme,
  [lightTheme.key]: lightTheme
}

export default themes
export type Theme = ThemeType
