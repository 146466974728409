import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'

import { useStyles } from '../../hooks'
import AddIcon from '../../svg/Add'
import HomeIcon from '../../svg/Home'
import HomeFilledIcon from '../../svg/HomeFilled'
import SettingsIcon from '../../svg/Settings'
import SettingsFilledIcon from '../../svg/SettingsFilled'

import { Component } from '../../types'
import IconButton from '../IconButton'
import * as _styles from './Navigation.styles'

const links = [
  { path: 'home', icon: HomeIcon, filledIcon: HomeFilledIcon },
  { path: 'settings', icon: SettingsIcon, filledIcon: SettingsFilledIcon }
] as const

export type NavigationProps = BottomTabBarProps

export const Navigation: Component<NavigationProps> = ({ navigation, state }) => {
  const styles = useStyles(_styles)

  const activeRoute = state.routeNames[state.index]

  return (
    <View style={[styles.container]}>
      <View style={styles.createButtonContainer}>
        <IconButton
          icon={AddIcon}
          style={styles.createButton}
          styles={{
            icon: styles.createButtonIcon
          }}
        />
      </View>

      {links.map((link) => (
        <NavigationButton
          item={link}
          key={link.path}
          active={activeRoute === link.path}
          onClick={() => navigation.navigate(link.path)}
        />
      ))}
    </View>
  )
}
export default Navigation

export type NavigationButtonProps = {
  item: (typeof links)[number]
  active: boolean
  onClick: () => void
}

export const NavigationButton: Component<NavigationButtonProps> = ({ active, item, onClick }) => {
  const styles = useStyles(_styles)
  const [t] = useTranslation('common')

  const Icon = active ? item.filledIcon : item.icon

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[styles.button, active && styles.buttonActive]}
      onPress={onClick}
    >
      <Icon style={[styles.buttonIcon, active && styles.buttonIconActive]} />
      <Text style={[styles.buttonText, active && styles.buttonTextActive]}>
        {t(('button.' + item.path) as any)}
      </Text>
    </TouchableOpacity>
  )
}
