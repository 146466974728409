// TODO: Potentially use timestamp milliseconds everywhere instead of mixture of seconds and
// milliseconds. Or make it clearer with types.
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { TimestampMilliseconds, TimestampSeconds } from '../timestamp'
import { SchedulerTiming } from './types'

dayjs.extend(timezone)

export class Scheduler {
  static getTodaysSchedule(tz: string, learnAheadSeconds: number): SchedulerTiming {
    const dayCutoffEpoch = Scheduler.getNextDayTimestampSeconds(tz)
    const now = TimestampSeconds.now()
    return {
      now,
      learnCutoff: now + learnAheadSeconds,
      nextDayAt: dayCutoffEpoch,
      previousDayAt: dayCutoffEpoch - 86400
    }
  }

  static getNextDayTimestampSeconds(tz: string): number {
    const nowInTimezone = dayjs(TimestampMilliseconds.now()).tz(tz)
    const nextMidnight = dayjs(nowInTimezone).add(1, 'day').startOf('day')
    return nextMidnight.unix()
  }
}
