import React, { useState } from 'react'
import { TouchableOpacity, TouchableOpacityProps, ViewStyle } from 'react-native'
import { useStyles } from '../../hooks'
import { CustomSvgProps } from '../../types'
import * as _styles from './IconButton.styles'

export type IconButtonProps = TouchableOpacityProps & {
  icon: (props: CustomSvgProps) => JSX.Element
  styles?: {
    icon?: ViewStyle
  }
}

const IconButton: React.FC<IconButtonProps> = ({ icon: Icon, ...others }) => {
  const styles = useStyles(_styles)
  const [pressing, setPressing] = useState(false)

  return (
    <TouchableOpacity
      {...others}
      activeOpacity={1}
      onPress={(e) => others.onPress?.(e)}
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      style={[
        styles.button,
        others.disabled && styles.disabled,
        pressing && styles.pressing,
        others.style
      ]}
    >
      <Icon style={[styles.icon, others.disabled && styles.disabledIcon, others.styles?.icon]} />
    </TouchableOpacity>
  )
}

export default IconButton
