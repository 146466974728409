/// <reference path="../web/types/worker-import.d.ts" />
// ^ Allows importing a worker by using ?worker at the end of the import
// This is the convention used by vite so may need more work to work
// with other bundlers

import { wrapWorker } from '../web/util/worker.util'
import type {
  SQLiteCreateOptions,
  SQLiteDatabase,
  SQLiteDatabaseHandler,
  SQLiteQueryResponse
} from './types'
import type { WorkerFunctions } from './web-worker'
import Worker from './web-worker?worker'

const worker = new Worker()

const executeWorkerFunction = wrapWorker<WorkerFunctions>(worker)

export class WebSQLiteDatabase implements SQLiteDatabase {
  private name: string
  constructor(name: string) {
    this.name = name
  }
  async executeSql<T>(
    sql: string,
    params?: unknown[] | undefined
  ): Promise<SQLiteQueryResponse<T>> {
    const data = await executeWorkerFunction('execute_sql', [this.name, sql, params])

    return {
      rows: data.rows as T[],
      rowsAffected: data.numUpdatedRows
    }
  }
  async close(): Promise<void> {
    await executeWorkerFunction('close_database', [this.name])
  }

  getName() {
    return this.name
  }
}

export class WebSQLiteDatabaseHandler implements SQLiteDatabaseHandler {
  constructor() {}

  async delete(name: string): Promise<void> {
    await executeWorkerFunction('delete_database', [name])
  }

  async create(name: string, opts?: SQLiteCreateOptions): Promise<WebSQLiteDatabase> {
    if (opts?.createFromLocation) {
      name = opts.createFromLocation
    }

    await executeWorkerFunction('create_database', [name])

    return new WebSQLiteDatabase(name)
  }
}
