import { useEffect, useRef, useState } from 'react'

export const useDelayed = <T>(val: T, delayMs: number): T => {
  const [state, setState] = useState(() => val)
  const prevValRef = useRef<T>(val)

  useEffect(() => {
    if (state !== prevValRef.current) setState(() => prevValRef.current)
    const timeout = setTimeout(() => {
      setState(() => val)
      prevValRef.current = val
    }, delayMs)
    return () => {
      prevValRef.current = val
      clearTimeout(timeout)
    }
  }, [val])

  return state
}
