import { Kysely, Migrator } from 'kysely'
import migrations from './migrations'
import { DatabaseSchema15 } from './types'

export const upgradeToLatestAnkiSchema = async (
  db: Kysely<any>
): Promise<Kysely<DatabaseSchema15>> => {
  const migrator = new Migrator({
    db: db,
    provider: {
      getMigrations: async () => migrations
    }
  })
  const { results, error } = await migrator.migrateToLatest()
  if (error) {
    throw error
  }
  results?.forEach((it) => {
    if (it.status === 'Error') {
      throw new Error(`Failed to execute migration ${it.migrationName}`)
    }
  })
  return db as Kysely<DatabaseSchema15>
}
