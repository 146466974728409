import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const cardContainer = (): ViewStyle => ({
  width: '100%',
  flex: 1,
  marginVertical: 'auto'
})

export const card = ({ background }: Theme): ViewStyle => ({
  backgroundColor: background.card,
  borderRadius: 16,
  flex: 1,
  overflow: 'hidden',
  borderColor: background.cardLight,
  borderWidth: 2
})

export const cardInner = (): ViewStyle => ({
  position: 'relative',
  flex: 1,
  justifyContent: 'center',
  padding: 32,
  paddingTop: 48
})

export const cardRender = (): ViewStyle => ({
  flex: 1,
  overflow: 'hidden'
})

export const webview = (): ViewStyle => ({
  flex: 1,
  transform: [{ scale: 1.025 }]
})

export const undoButton = (): ViewStyle => ({
  position: 'absolute',
  left: 8,
  top: 8,
  zIndex: 1,
  width: 40,
  height: 40
})

export const menuButton = (): ViewStyle => ({
  position: 'absolute',
  right: 8,
  top: 8,
  zIndex: 1,
  width: 40,
  height: 40
})

export const revealText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textSecondary,
  fontSize: 18,
  textAlign: 'center',
  paddingVertical: 12,
  marginTop: 'auto'
})
