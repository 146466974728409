import { Kysely } from 'kysely'
import { Field } from '../../field/models'
import { Database } from '../types'

export class FieldStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async getForNoteType(noteTypeId: string, executor: Kysely<Database> = this.db): Promise<Field[]> {
    const rows = await executor
      .selectFrom('field')
      .selectAll()
      .where('field.note_type_id', '=', noteTypeId)
      .execute()
    if (rows.length === 0) {
      throw new Error('Fields not found')
    }
    return rows.map((row) => new Field(row))
  }

  async create(field: Field): Promise<void> {
    const results = await this.db.insertInto('field').values(field.toDTO()).executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert field.')
    }
  }

  async createMultiple(fields: Field[]): Promise<void> {
    const results = await this.db
      .insertInto('field')
      .values(fields.map((field) => field.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert fields.')
    }
  }
}
