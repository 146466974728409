import { Kysely } from 'kysely'
import { DatabaseSchema15 } from '../types'

export const upgradeDecksToSchema15 = async (db: Kysely<DatabaseSchema15>): Promise<void> => {
  const collection = await db.selectFrom('col').select('decks').executeTakeFirst()
  if (!collection) {
    throw new Error('col table empty')
  }
  const decks: Record<string, Record<string, any>> = JSON.parse(collection.decks)
  const names = new Set<string>()
  for (const [deckId, deckSchema] of Object.entries(decks)) {
    while (names.has(deckSchema.name.toLowerCase())) {
      deckSchema.name += '_'
    }
    names.add(deckSchema.name.toLowerCase())
    // CBA to figure out how common and kind are set properly
    // Dyn is 0 for normal decks and 1 for filtered decks
    // Stuff deck schema into common and we can use as we wish later
    await db
      .insertInto('decks')
      .values({
        id: Number(deckId),
        name: deckSchema.name,
        mtime_secs: deckSchema.mod,
        usn: deckSchema.usn,
        common: Buffer.from(JSON.stringify(deckSchema)),
        kind: Buffer.from(JSON.stringify({}))
      })
      .onConflict((oc) =>
        oc.doUpdateSet({
          id: Number(deckId),
          name: deckSchema.name,
          mtime_secs: deckSchema.mod,
          usn: deckSchema.usn,
          common: Buffer.from(JSON.stringify(deckSchema)),
          kind: Buffer.from(JSON.stringify({}))
        })
      )
      .execute()
  }
  await db.updateTable('col').set({ decks: '' }).execute()
}
