import { DeckType } from './types'

export class Deck {
  public readonly id: string
  public name: string
  public description: string
  public configId: string
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: DeckType) {
    this.id = data.id
    this.name = data.name
    this.description = data.description
    this.configId = data.config_id
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): DeckType {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      config_id: this.configId,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
