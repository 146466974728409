import { MutableRefObject, useEffect, useRef } from 'react'

export const useIsUnmounted = (): MutableRefObject<boolean> => {
  const unmounted = useRef(false)

  useEffect(() => {
    unmounted.current = false
    return () => {
      unmounted.current = true
    }
  }, [])

  return unmounted
}
