import { NoteType } from './types'

export class Note {
  public readonly id: string
  public readonly noteTypeId: string
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: NoteType) {
    this.id = data.id
    this.noteTypeId = data.note_type_id
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): NoteType {
    return {
      id: this.id,
      note_type_id: this.noteTypeId,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
