import { Platform } from 'react-native'
import { ZipHandler } from './types'

export const getZipHandler = async (): Promise<ZipHandler> => {
  if (Platform.OS === 'web') {
    const { webZipHandler } = await import('./web')
    return webZipHandler
  } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
    const { reactNativeZipHandler } = await import('./react-native')
    return reactNativeZipHandler
  } else {
    const { nodeZipHandler } = await import('./node')
    return nodeZipHandler
  }
}
