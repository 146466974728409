import { CardTableSelectQuery } from './types'

export const cardSort = {
  new: {
    oldestFirst: (query: CardTableSelectQuery): CardTableSelectQuery => {
      return query.orderBy('card.due', 'asc').orderBy('card.ordinal', 'asc')
    }
  },
  learning: {
    dueFirst: (query: CardTableSelectQuery): CardTableSelectQuery => {
      return query.orderBy('card.due', 'asc')
    }
  },
  review: {
    dueFirst: (query: CardTableSelectQuery): CardTableSelectQuery => {
      return query.orderBy('card.due', 'asc')
    }
  }
}
