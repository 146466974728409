import { useEffect, useState } from 'react'

export type UseTimeoutArgs = {
  loop: boolean
}

export const useTimeout = (callback: () => void, timeoutMs: number, args?: UseTimeoutArgs) => {
  const [rerenderFlag, setRerenderFlag] = useState(false)

  useEffect(() => {
    let mounted = true
    let timeout = setTimeout(() => {
      if (!mounted) return
      callback()
      if (args?.loop) setRerenderFlag((flag) => !flag)
    }, timeoutMs)
    return () => {
      clearTimeout(timeout)
      mounted = false
    }
  }, [timeoutMs, args?.loop, rerenderFlag])
}
