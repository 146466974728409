import { AnkiDeck } from '../../anki/deck/types'
import { arrayBufferToUtf8 } from '../utils'
import { CreateAnkiDeckType } from './types'

export class AnkiDeckAdapter {
  private ankiDeck: AnkiDeck

  constructor(ankiDeck: AnkiDeck) {
    this.ankiDeck = ankiDeck
  }

  public adapt(deckConfigId: string): CreateAnkiDeckType {
    const common = JSON.parse(arrayBufferToUtf8(this.ankiDeck.common))
    return {
      name: this.ankiDeck.name,
      description: common.desc,
      config_id: deckConfigId
    }
  }
}
