import { StyleProp, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({}: Theme): StyleProp<ViewStyle> => ({
  padding: 24,
  flex: 1,
  gap: 12
})

export const header = ({}: Theme): StyleProp<ViewStyle> => ({
  height: 64,
  gap: 16
})

export const progressContainer = ({}: Theme): StyleProp<ViewStyle> => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16
})

export const title = ({ fonts }: Theme): StyleProp<ViewStyle> => ({
  ...fonts.screenHeader,
  flexShrink: 0
})

export const body = ({}: Theme): StyleProp<ViewStyle> => ({
  display: 'flex',
  flex: 1,
  marginTop: 32
})
