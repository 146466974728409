///<reference lib="dom" />
import { APP_API_BASE_URL } from 'env'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, Platform, View } from 'react-native'
import oauths from '../../constants/oauths'
import { ChevronDown, ChevronUp } from '../../svg'
import { Component } from '../../types'
import { base64Encode } from '../../util'
import Button from '../Button'
import Collapse from '../Collapse'

export type OAuthListProps = {
  onPress?: (provider: (typeof oauths)[number]) => void
  loadingItem?: (typeof oauths)[number]['id']
  onCancel?: () => void
}

const OAuthList = ({ onPress, loadingItem }: OAuthListProps) => {
  const others = useMemo(() => {
    return oauths.filter((oauth) => oauth.alwaysDisplay === false)
  }, [oauths])

  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation('common')

  return (
    <View style={{ gap: 16, flexDirection: 'column' }}>
      {oauths
        .filter((item) => item.alwaysDisplay)
        .map((oauth) => (
          <OAuthButton
            key={oauth.id}
            loading={oauth.id === loadingItem}
            oauth={oauth}
            onPress={onPress}
          />
        ))}
      <Button
        onPress={() => setExpanded((prevExpanded) => !prevExpanded)}
        color="contrast"
        icon={expanded ? ChevronUp : ChevronDown}
        style={{ flex: 1, width: 'auto', height: 56, paddingRight: 20 }}
        iconPosition="right"
        variant="outlined"
      >
        {t('button.other-methods')}
      </Button>
      <Collapse expanded={expanded} innerStyle={{ gap: 16 }}>
        {others.map((oauth) => (
          <OAuthButton
            key={oauth.id}
            loading={oauth.id === loadingItem}
            oauth={oauth}
            onPress={onPress}
          />
        ))}
      </Collapse>
    </View>
  )
}

export default OAuthList

export type OAuthButtonProps = {
  oauth: (typeof oauths)[number]
  loading: boolean
  onPress?: (oauth: (typeof oauths)[number]) => void
}

export const OAuthButton: Component<OAuthButtonProps> = (props) => {
  const { t } = useTranslation('common')

  return (
    <Button
      key={props.oauth.name}
      loading={props.loading}
      color="contrast"
      icon={props.oauth.icon}
      iconPosition="right"
      onPress={() => {
        props.onPress?.(props.oauth)
        const url = `${APP_API_BASE_URL}/auth/${props.oauth.id}/redirect?state=${base64Encode(
          JSON.stringify({ platform: Platform.OS })
        )}`
        if (Platform.OS === 'web') {
          return location.replace(url)
        }
        Linking.openURL(url)
      }}
      style={{ width: 'auto', height: 56, paddingRight: 20 }}
    >
      {t('button.sign-in-with', { provider: props.oauth.name })}
    </Button>
  )
}
