import { MMKV } from 'react-native-mmkv'
import { PersistStorage } from 'zustand/middleware'

const rawStorage = new MMKV()

export type CreateStorageOptions<S> = {
  onGetItemFromStorage?: (state: S) => S
  onSetItemFromStorage?: (state: S) => S
}

export const createStorage = <S>(options?: CreateStorageOptions<S>): PersistStorage<S> => {
  return {
    getItem: (key) => {
      const val = rawStorage.getString(key)
      if (!val) return null
      try {
        const parsed = JSON.parse(val) as unknown
        if (!parsed || typeof parsed !== 'object') return null
        if (!('version' in parsed) || typeof (parsed as { version: number }).version !== 'number')
          return null
        if (!('value' in parsed)) return null
        let value = (parsed as { value: unknown }).value as S
        if (options?.onGetItemFromStorage) value = options?.onGetItemFromStorage(value)
        return {
          version: (parsed as { version: number }).version,
          state: value as S
        }
      } catch (e) {
        return null
      }
    },
    setItem: (key, value) => {
      const newValue = { ...value }
      if (options?.onSetItemFromStorage) newValue.state = options?.onSetItemFromStorage(value.state)
      try {
        rawStorage.set(
          key,
          JSON.stringify({
            version: newValue.version,
            value: newValue.state
          })
        )
      } catch (err) {
        console.error(`Failed to set ${key} in storage`)
        console.error(err)
      }
    },
    removeItem: (key) => {
      rawStorage.delete(key)
    }
  }
}
