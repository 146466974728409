import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const modal = ({}: Theme): ViewStyle => ({})

export const background = ({}: Theme): ViewStyle => ({
  position: 'absolute',
  backgroundColor: 'rgba(0,0,0,0.5)',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
})

export const cardContainer = (): ViewStyle => ({
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: 8
})

export const card = ({ background, shadow, radius }: Theme): ViewStyle => ({
  backgroundColor: background.card,
  ...shadow.card,
  padding: 12,
  borderRadius: radius.card,
  gap: 8
})

export const title = ({ fonts }: Theme): TextStyle => ({
  ...fonts.titleRegular,
  fontSize: 20,
  textAlign: 'center'
})
