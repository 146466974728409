import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, ViewProps } from 'react-native'
import { useStateRef, useStyles } from '../../hooks'
import { BackIcon } from '../../svg'
import { Component } from '../../types'
import Button from '../Button'

import IconButton from '../IconButton'
import ProgressBar from '../ProgressBar'
import * as _styles from './ScreenSlider.styles'

export type ScreenSliderProps = ScreenSliderInnerProps & ScreenSliderProviderProps

export const ScreenSlider: Component<ScreenSliderProps> = ({ onFinish, ...others }) => {
  return (
    <ScreenSliderProvider onFinish={onFinish}>
      <ScreenSliderInner {...others}>{others.children}</ScreenSliderInner>
    </ScreenSliderProvider>
  )
}

export type ScreenSliderInnerProps = ViewProps

const ScreenSliderInner: Component<ScreenSliderInnerProps> = ({ children, ...others }) => {
  const styles = useStyles(_styles)
  const { t } = useTranslation()
  const { currentData, current, total, prev, next, backDisabled, continueDisabled } =
    useContext(ScreenSliderContext)

  return (
    <View {...others} style={[styles.container, others.style]}>
      <View style={styles.header}>
        <View style={styles.progressContainer}>
          <IconButton disabled={backDisabled} onPress={prev} icon={BackIcon} style={styles.back} />
          <ProgressBar progress={(current + 1) / total} />
        </View>
        <Text style={styles.title}>{currentData.title}</Text>
      </View>
      <View style={styles.body}>{children}</View>
      <View style={styles.footer}>
        <Button onPress={next} disabled={continueDisabled}>
          {t('button.continue')}
        </Button>
      </View>
    </View>
  )
}

export type ScreenSlideProps = ViewProps & {
  title: string
  disableContinue?: boolean
  disableBack?: boolean
  onBack?: () => boolean | void
  onContinue?: () => boolean | void
}

export const ScreenSlide: Component<ScreenSlideProps> = ({
  title,
  disableBack,
  disableContinue,
  onBack,
  onContinue,
  ...others
}) => {
  const styles = useStyles(_styles)

  const {
    current,
    addSlide,
    setBackDisabled,
    setContinueDisabled,
    setBackCallback,
    setContinueCallback
  } = useContext(ScreenSliderContext)
  const [index, setIndex] = useState(-1)

  const data: SlideData = {
    title
  }

  useEffect(() => {
    if (index !== -1) return
    setIndex(addSlide(data))
  }, [])

  useEffect(() => {
    if (index !== current) return
    setBackDisabled(disableBack ?? false)
    setContinueDisabled(disableContinue ?? false)
    if (onBack) setBackCallback(onBack)
    if (onContinue) setContinueCallback(onContinue)
  }, [disableBack, disableContinue, index, current, onBack, onContinue])

  if (index !== current) return <></>

  return (
    <View {...others} style={[styles.slide, others.style]}>
      {others.children}
    </View>
  )
}

export type SlideData = {
  title: string
}

export type ScreenSliderContextValue = {
  next: () => void
  prev: () => void
  current: number
  total: number
  addSlide: (data: SlideData) => number
  data: SlideData[]
  currentData: SlideData
  backDisabled: boolean
  continueDisabled: boolean
  setBackDisabled: (disabled: boolean) => void
  setContinueDisabled: (disabled: boolean) => void
  setBackCallback: (callback: () => boolean | void) => void
  setContinueCallback: (callback: () => boolean | void) => void
}

const defaultValue: ScreenSliderContextValue = {
  next: () => console.warn('no ScreenSliderContext'),
  prev: () => console.warn('no ScreenSliderContext'),
  current: 0,
  total: 0,
  addSlide: () => 0,
  data: [],
  currentData: {
    title: ''
  },
  backDisabled: false,
  continueDisabled: false,
  setBackDisabled: () => console.warn('no ScreenSliderContext'),
  setContinueDisabled: () => console.warn('no ScreenSliderContext'),
  setBackCallback: () => {},
  setContinueCallback: () => {}
}

export const ScreenSliderContext = createContext<ScreenSliderContextValue>(defaultValue)

export type ScreenSliderProviderProps = {
  onFinish?: () => void
}

export const ScreenSliderProvider: Component<ScreenSliderProviderProps> = ({
  children,
  onFinish
}) => {
  const [current, setCurrent] = useState(0)
  const [total, setTotal, totalRef] = useStateRef(0)
  const [data, setData] = useState<SlideData[]>([])
  const [currentData, setCurrentData] = useState<SlideData>({ title: '' })
  const [backDisabled, setBackDisabled] = useState(false)
  const [continueDisabled, setContinueDisabled] = useState(false)
  const [backCallback, _setBackCallback] = useState<(() => boolean | void) | null>(null)
  const [continueCallback, _setContinueCallback] = useState<(() => boolean | void) | null>(null)

  const setBackCallback = (callback: (() => boolean | void) | null) =>
    _setBackCallback(() => callback)
  const setContinueCallback = (callback: (() => boolean | void) | null) =>
    _setContinueCallback(() => callback)

  const next = () => {
    if (continueCallback) {
      const ret = continueCallback()
      if (ret === false) return
    }
    if (current < total - 1) {
      setCurrent(current + 1)
      setCurrentData(data[current + 1])
      setBackDisabled(false)
      setContinueDisabled(false)
      setContinueCallback(null)
      setBackCallback(null)
    } else {
      onFinish?.()
    }
  }

  const prev = () => {
    if (backCallback) {
      const ret = backCallback()
      if (ret === false) return
    }
    if (current > 0) {
      setCurrent(current - 1)
      setCurrentData(data[current - 1])
      setBackDisabled(false)
      setContinueDisabled(false)
    }
  }

  const addSlide = (newData: SlideData) => {
    let index = totalRef.current
    if (index === 0) {
      setCurrent(0)
      setCurrentData(newData)
    }
    setData((prevData) => [...prevData, newData])
    setTotal(index + 1)
    return index
  }

  const value: ScreenSliderContextValue = {
    next,
    prev,
    current,
    total,
    addSlide,
    data,
    currentData,
    backDisabled,
    continueDisabled,
    setBackDisabled,
    setContinueDisabled,
    setBackCallback,
    setContinueCallback
  }

  return <ScreenSliderContext.Provider value={value}>{children}</ScreenSliderContext.Provider>
}
