import type { FSRSState } from '../scheduler/fsrs/types'
import { CardQueue, CardType } from './types'

export class Card {
  public readonly id: string
  public readonly noteId: string
  public readonly deckId: string
  public ordinal: number
  public type: number
  public due: number
  public interval: number
  public reps: number
  public lapses: number
  public left: number
  public fsrsState: FSRSState
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: CardType) {
    this.id = data.id
    this.noteId = data.note_id
    this.deckId = data.deck_id
    this.ordinal = data.ordinal
    this.type = data.type
    this.due = data.due
    this.interval = data.interval
    this.reps = data.reps
    this.lapses = data.lapses
    this.left = data.left
    this.fsrsState = {
      stability: data.fsrs_stability,
      difficulty: data.fsrs_difficulty
    }
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  isNew(): boolean {
    return this.type === CardQueue.New
  }

  isLearning(): boolean {
    return this.type === CardQueue.Learning
  }

  isReview(): boolean {
    return this.type === CardQueue.Review
  }

  toDTO(): CardType {
    return {
      id: this.id,
      note_id: this.noteId,
      deck_id: this.deckId,
      ordinal: this.ordinal,
      type: this.type,
      due: this.due,
      interval: this.interval,
      reps: this.reps,
      lapses: this.lapses,
      left: this.left,
      fsrs_stability: this.fsrsState?.stability || null,
      fsrs_difficulty: this.fsrsState?.difficulty || null,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
