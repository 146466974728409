import { LangkiRendererTransformer } from './langki-renderer'

const rubyTextRegexp = /(?<!\[)\[(?!\[).+?(?<!\[(?!\[))\]/ //Only matches single pairs of square brackets
const charBreakArr = [' ', '<', '>', '"', "'"]

export const getHintText = (match: string, index: number, html: string) => {
  let rubyText = match
  let prevText = ''
  while (index >= 0) {
    const currentChar = html[index]
    if (charBreakArr.includes(currentChar)) break
    prevText += currentChar
    index--
  }
  prevText = prevText.split('').reverse().join('')
  return {
    rubyText: rubyText,
    rubyTextInner: rubyText.replaceAll(/\[|\]/g, ''),
    prevText,
    fullText: prevText + rubyText
  }
}

export const rubyTextTransformer: LangkiRendererTransformer = {
  getTransformTarget(html) {
    const match = rubyTextRegexp.exec(html)
    if (!match) return null
    return {
      index: match.index,
      str: match[0]
    }
  },
  transform(transformTarget, html) {
    const hintRes = getHintText(transformTarget.str, transformTarget.index - 1, html)
    html = html.replace(
      hintRes.fullText,
      `<span class="ruby-text-container">${hintRes.prevText}<span class="ruby-text">${hintRes.rubyTextInner}</span></span>`
    )
    return html
  },
  setup: undefined
}
