import pathModule, { join } from 'path'
import { readAnkiApkgFile } from '../../anki/apkg-reader'
import { getAnkiCollectionPath } from '../../anki/collection-reader'
import { initAnkiDatabase } from '../../anki/database'
import { upgradeToLatestAnkiSchema } from '../../anki/migrator'
import { FileSystemHandler } from '../../interfaces/filesystem/types'
import { ProgressHandler } from '../../interfaces/progress-handler/types'
import { SQLiteDatabaseHandler } from '../../interfaces/sqlite/types'
import { ZipHandler } from '../../interfaces/zip-handler/types'
import { LangkiStorage } from '../storage/models'
import { AnkiConverter } from './anki/convert'
import { convertAnkiMedia } from './media'

export const CONVERT_ANKI_DECK_TOTAL_PROGRESS = AnkiConverter.CONVERT_TOTAL_PROGRESS + 5

export const convertAnkiDeck = async (
  apkgPath: string,
  langkiDb: LangkiStorage,
  mediaFolderName: string,
  handlers: {
    dbHandler: SQLiteDatabaseHandler
    zipHandler: ZipHandler
    fsHandler: FileSystemHandler
    progressHandler?: ProgressHandler
  }
) => {
  if (!handlers.progressHandler?.hasTotal())
    handlers.progressHandler?.setTotal(CONVERT_ANKI_DECK_TOTAL_PROGRESS)

  handlers.progressHandler?.setText('Reading anki file', 'import.reading-file')
  const { files, path } = await readAnkiApkgFile(apkgPath, handlers)
  handlers.progressHandler?.addProgress()

  handlers.progressHandler?.setText('Initializing anki database', 'import.initializing-database')
  const ankiCollectionPath = await getAnkiCollectionPath(files)
  const [ankiDb11, sqliteDatabase] = await initAnkiDatabase(
    pathModule.join(path, ankiCollectionPath),
    handlers.dbHandler
  )
  handlers.progressHandler?.addProgress()
  let addedFiles: string[] = []
  try {
    // Convert media
    handlers.progressHandler?.setText('Converting media', 'converting.media')
    addedFiles = await convertAnkiMedia(path, mediaFolderName, handlers.fsHandler)

    // Convert the Database
    handlers.progressHandler?.setText('Upgrading anki database', 'import.upgrading-database')
    const ankiDbLatest = await upgradeToLatestAnkiSchema(ankiDb11)
    handlers.progressHandler?.addProgress()

    handlers.progressHandler?.setText('Converting anki deck', 'converting.anki-deck')
    const ankiConverter = new AnkiConverter(ankiDbLatest, langkiDb)
    await ankiConverter.convert(handlers.progressHandler)
    handlers.progressHandler?.addProgress()

    handlers.progressHandler?.setText('Cleaning up', 'general.cleaning-up')
    ankiDbLatest.destroy()
    handlers.progressHandler?.addProgress()
  } catch (err) {
    handlers.progressHandler?.setText('Cleaning up', 'general.cleaning-up')
    await handlers.dbHandler.delete(sqliteDatabase.getName()).catch(() => {})
    for (const file of addedFiles) {
      await handlers.fsHandler
        .delete(join(handlers.fsHandler.getBasePath(), mediaFolderName, 'files', file))
        .catch(() => {})
    }
    throw err
  } finally {
    await handlers.fsHandler.deleteDir(path).catch(() => {})
  }
}
