import { StackScreenProps } from '@react-navigation/stack'
import LottieView from 'lottie-react-native'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Text, View, ViewProps } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { Button, Screen } from '../../components'
import IconButton from '../../components/IconButton'
import StudyCard from '../../components/StudyCard'
import { useDeck, useStudyQueue, useStyles, useTimeout } from '../../hooks'
import { useTheme } from '../../state/theme'
import BackIcon from '../../svg/BackIcon'
import ThumbsDownIcon from '../../svg/ThumbsDown'
import ThumbsUpIcon from '../../svg/ThumbsUp'
import { Component, LoggedOutParamsList } from '../../types'
import { getDurationFromSecs } from '../../util'
import * as _styles from './Study.styles'

export type StudyProps = ViewProps & StackScreenProps<LoggedOutParamsList, 'study'>

const Study: Component<StudyProps> = ({ route, navigation }) => {
  const styles = useStyles(_styles)
  const {
    fetching,
    currentCardRender,
    nextCardRender,
    queue,
    undo: _undo,
    markCorrect,
    markWrong,
    todaysStats
  } = useStudyQueue({
    deckId: route.params?.deckId ?? undefined
  })

  const [direction, setDirection] = useState<'front' | 'back'>('front')
  const [initialLoad, setInitialLoad] = useState(true)
  const [cardPosition, setCardPosition] = useState<'middle' | 'left' | 'right'>('middle')

  const { deck } = useDeck(route.params?.deckId ?? '')

  useEffect(() => {
    if (!route.params?.deckId) {
      navigation.setOptions({ title: 'All Decks' })
      return
    }

    if (!deck) return
    navigation.setOptions({
      title: deck.name
    })
  }, [deck, route.params?.deckId])

  useEffect(() => {
    setTimeout(() => setInitialLoad(false), 300)
  }, [])

  const [goingOut, setGoingOut] = useState(false)

  const toggleDirection = () => {
    setDirection((dir) => (dir === 'front' ? 'back' : 'front'))
  }

  const correct = async () => {
    setCardPosition('right')
    await markCorrect()
    setGoingOut(true)
    setTimeout(() => setGoingOut(false), 50)
    setDirection('front')
    setCardPosition('middle')
  }

  const wrong = async () => {
    setCardPosition('left')
    await markWrong()
    setGoingOut(true)
    setTimeout(() => setGoingOut(false), 50)
    setDirection('front')
    setCardPosition('middle')
  }

  const [cardDoesAnimate, setCardDoesAnimate] = useState(true)

  const undo = async () => {
    setCardDoesAnimate(false)
    setCardPosition('left')
    await _undo()
    await new Promise<void>((resolve) => setTimeout(resolve, 30))
    setCardDoesAnimate(true)
    setCardPosition('middle')
  }

  const theme = useTheme()

  return (
    <Screen requiresLoggedIn>
      <View style={[styles.container, fetching && styles.fetchingContainer]}>
        {fetching ? (
          <ActivityIndicator size={32} />
        ) : (
          <>
            <View style={styles.headerContainer}>
              <IconButton
                icon={BackIcon}
                onPress={() => {
                  return navigation.getState().routes.find((route) => route.name === 'user')
                    ? navigation.popToTop()
                    : navigation.replace('user')
                }}
              />
              <Text style={styles.headerText} numberOfLines={1}>
                {deck?.name}
              </Text>
            </View>
            <View style={styles.cardsContainer}>
              {fetching && <ActivityIndicator />}
              {!currentCardRender && !fetching && todaysStats && (
                <FinishedStudyScreen todaysStats={todaysStats} />
              )}
              {currentCardRender && (
                <View style={styles.cardsWrapper}>
                  {[
                    <StudyCard
                      key={currentCardRender?.card.id ?? 0}
                      cardRender={currentCardRender}
                      onPress={toggleDirection}
                      direction={direction}
                      initialLoad={initialLoad}
                      position={cardPosition}
                      onDragRight={correct}
                      onDragLeft={wrong}
                      onUndo={undo}
                      canUndo={queue?.canUndo()}
                      doesAnimate={cardDoesAnimate}
                    />,
                    nextCardRender && (
                      <StudyCard
                        key={nextCardRender?.card.id ?? 1}
                        style={{
                          ...styles.behindCard,
                          ...(cardPosition === 'middle' ? styles.behindCardOff : {})
                        }}
                        cardRender={nextCardRender}
                        direction="front"
                        initialLoad={initialLoad}
                        position="middle"
                        draggable={false}
                        canUndo={true}
                        behind={true}
                      />
                    ),
                    (queue?.learningQueue.length ?? 0) > 1 && (
                      <Animatable.View
                        key={2}
                        duration={300}
                        transition={['translateY', 'scale', 'opacity']}
                        style={[
                          styles.card,
                          styles.behindCardPlaceholder,
                          cardPosition === 'middle' && styles.behindCardPlaceholderOff,
                          goingOut && { opacity: 0 }
                        ]}
                      />
                    )
                  ]}
                </View>
              )}
            </View>
            {currentCardRender && (
              <>
                <View style={styles.buttonContainer}>
                  <Button
                    variant="icon_contained"
                    color="contrast"
                    size="compact"
                    icon={ThumbsDownIcon}
                    styles={{ icon: { color: theme.colors.error.main, height: 28, width: 28 } }}
                    onPress={wrong}
                  />
                  <Button
                    variant="icon_contained"
                    color="contrast"
                    size="compact"
                    icon={ThumbsUpIcon}
                    styles={{ icon: { color: theme.colors.success.main, height: 28, width: 28 } }}
                    onPress={correct}
                  />
                </View>
              </>
            )}
          </>
        )}
      </View>
    </Screen>
  )
}

export default Study

export type FinishedStudyScreenProps = ViewProps & {
  todaysStats: { totalReviews: number; timeTaken: number }
}

function easeOutElastic(x: number): number {
  const c4 = (2 * Math.PI) / 3
  if (x === 0) return 0
  if (x === 1) return 1
  return Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1
}

export const FinishedStudyScreen: Component<FinishedStudyScreenProps> = ({ todaysStats }) => {
  const styles = useStyles(_styles)
  const [t] = useTranslation('study')
  const [animationHidden, setAnimationHidden] = useState(false)

  const animation: Animatable.CustomAnimation = {
    from: {
      transform: [{ scale: 0.5 }, { rotate: '20deg' }],
      opacity: 0
    },
    to: {
      transform: [{ scale: 1 }, { rotate: '0deg' }],
      opacity: 1
    },
    easing: easeOutElastic
  }

  useTimeout(() => {
    setAnimationHidden(true)
  }, 1850)

  const duration = getDurationFromSecs(todaysStats.timeTaken / 1000)
  const durationSplit = duration.split(' ')

  return (
    <View style={styles.congratulationsContainer}>
      <View style={[styles.congratulationsAnimation, animationHidden && { opacity: 0 }]}>
        <LottieView source={require('../../assets/congratulations.json')} autoPlay loop={false} />
      </View>
      <Animatable.Text animation={animation} style={styles.emptyText} duration={1500}>
        {t(('congratulations.' + durationSplit[1]) as any, {
          numCards: todaysStats.totalReviews,
          duration: durationSplit[0]
        })}
      </Animatable.Text>
    </View>
  )
}
