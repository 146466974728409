import React from 'react'
import {
  View,
  Modal as NativeModal,
  ModalProps as NativeModalProps,
  Text,
  Pressable
} from 'react-native'
import { useStyles } from '../../hooks'
import { Component } from '../../types'
import * as _styles from './Modal.styles'

export type ModalProps = Omit<NativeModalProps, 'visible' | 'onRequestClose'> & {
  open: boolean
  onClose: () => void
  title?: string
}

const Modal: Component<ModalProps> = ({ title, ...others }) => {
  const styles = useStyles(_styles)

  return (
    <NativeModal
      {...others}
      visible={others.open}
      onRequestClose={others.onClose}
      style={styles.container}
      transparent
      animationType="fade"
    >
      <View style={[styles.cardContainer]}>
        <Pressable onPress={others.onClose} style={styles.background} />
        <View style={[styles.card, others.style]}>
          {title && <Text style={styles.title}>{title}</Text>}
          {others.children}
        </View>
      </View>
    </NativeModal>
  )
}

export default Modal
