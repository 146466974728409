import { TextStyle, ViewStyle } from 'react-native'

const baseTheme = {
  key: 'base',
  label: 'Base',
  dark: true,
  colors: {
    primary: {
      light: '#00B4DB',
      main: '#0090b0',
      dark: '#0083B0',
      contrastText: '#000'
    },
    success: {
      light: '#86cb53',
      main: '#6da544',
      dark: '#64983d',
      contrastText: '#000000'
    },
    error: {
      light: '#ff7e75',
      main: '#de6666',
      dark: '#ab4e4e',
      contrastText: '#000'
    },
    base: '#000',
    contrast: '#fff',
    input: {
      border: '#666',
      background: '#333',
      placeholder: '#999',
      active: 'rgba(0,0,0,0.2)'
    },
    component: {
      background: '#555',
      disabled: '#666',
      loadFrom: '#999',
      loadTo: '#777'
    }
  },
  radius: {
    input: 16,
    card: 16,
    button: 16,
    buttonCompact: 12
  },
  shadow: {
    input: {
      shadowColor: '#000',
      shadowOpacity: 0.5,
      shadowRadius: 4,
      elevation: 3,
      shadowOffset: { width: 0, height: 0 }
    } as ViewStyle,
    card: {
      shadowColor: '#000',
      shadowOpacity: 0.5,
      shadowRadius: 2,
      elevation: 3,
      shadowOffset: { width: 0, height: 0 }
    }
  },
  fonts: {
    label: {
      fontSize: 16,
      color: '#fff',
      fontWeight: 'bold'
    } as TextStyle & object,
    screenHeader: {
      fontSize: 20,
      color: '#fff',
      fontWeight: 'bold'
    } as TextStyle & object,
    textMain: {
      fontSize: 16,
      color: '#fff'
    },
    textSecondary: {
      fontSize: 16,
      color: '#aaa'
    },
    titleRegular: {
      fontSize: 32,
      color: '#fff',
      fontWeight: '700'
    } as TextStyle & object,
    caption: {
      fontSize: 16,
      color: '#999',
      fontWeight: 'bold'
    } as TextStyle
  },
  navigationTheme: {
    dark: true,
    colors: {
      primary: '#0090b0',
      background: '#111',
      card: '#333',
      text: '#fff',
      border: '#444',
      notification: '#454545'
    }
  },
  background: {
    card: '#1c1c1c',
    cardLight: '#222',
    navbar: '#000',
    contrast: '#171717',
    default: '#111',
    web: '#181818',
    overlay: 'rgba(0,0,0,0.65)',
    cover: '#fff'
  },
  zindex: {
    sidebar: 9000,
    navbar: 10000,
    modal: 11000,
    snackbar: 12000,
    toast: 13000,
    tooltip: 14000
  },
  images: {
    logo: require('../images/tom_dark.png')
  }
}

export type Theme = typeof baseTheme

export default baseTheme
