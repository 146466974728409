import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { CustomSvgProps } from '../types'
const SvgComponent = (props: CustomSvgProps) => (
  <Svg
    strokeWidth={0}
    style={{
      overflow: 'visible'
    }}
    viewBox="0 0 512 512"
    {...props}
  >
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
      d="M244 400 100 256l144-144M120 256h292"
    />
  </Svg>
)
export default SvgComponent
