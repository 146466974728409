import { AnkiDeck } from '../../anki/deck/types'
import { BaseFactory } from '../models'
import { AnkiDeckAdapter } from './adapters'
import { Deck } from './models'
import { CreateDeckType } from './types'

export class DeckFactory extends BaseFactory {
  static create = (data: CreateDeckType) => {
    return new Deck(this.createBase(data))
  }

  static createFromAnkiDeck(data: AnkiDeck, deckConfigId: string): Deck {
    const adapter = new AnkiDeckAdapter(data)
    const adaptedData = adapter.adapt(deckConfigId)
    return new Deck(this.createBase(adaptedData))
  }
}
