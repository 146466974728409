import { TemplateType } from './types'

export class Template {
  public readonly id: string
  public readonly noteTypeId: string
  public name: string
  public questionFormat: string
  public answerFormat: string
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: TemplateType) {
    this.id = data.id
    this.noteTypeId = data.note_type_id
    this.name = data.name
    this.questionFormat = data.question_format
    this.answerFormat = data.answer_format
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): TemplateType {
    return {
      id: this.id,
      note_type_id: this.noteTypeId,
      name: this.name,
      question_format: this.questionFormat,
      answer_format: this.answerFormat,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
