import { TimestampMilliseconds } from './timestamp'
import { generateUniqueId } from './utils'

export abstract class BaseFactory {
  static createBase(data: any): any {
    const epochMilliseconds = TimestampMilliseconds.now()
    return {
      ...data,
      id: generateUniqueId(),
      created_at: epochMilliseconds,
      updated_at: epochMilliseconds
    }
  }
}
