import { FieldValueType } from './types'

export class FieldValue {
  public readonly id: string
  public readonly noteId: string
  public readonly fieldId: string
  public value: string
  public valueChecksum: number
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: FieldValueType) {
    this.id = data.id
    this.noteId = data.note_id
    this.fieldId = data.field_id
    this.value = data.value
    this.valueChecksum = data.value_checksum
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): FieldValueType {
    return {
      id: this.id,
      note_id: this.noteId,
      field_id: this.fieldId,
      value: this.value,
      value_checksum: this.valueChecksum,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
