import { create } from 'zustand'

export type OAuthStore = {
  isLoaded: boolean
  setLoaded: (newLoaded: boolean) => void
}

export const useIsLoaded = create<OAuthStore>((set) => ({
  isLoaded: false,
  setLoaded: (newLoaded) => set({ isLoaded: newLoaded })
}))
