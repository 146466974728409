import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const button = ({}: Theme): StyleProp<ViewStyle> => ({
  width: 48,
  height: 48,
  borderRadius: 9999,
  padding: 8,
  alignItems: 'center',
  justifyContent: 'center'
})

export const icon = ({ fonts }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: fonts.textMain.color,
  textAlign: 'center',
  fontSize: 24
})

export const disabledIcon = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  color: colors.component.disabled
})

export const pressing = ({ colors }: Theme): StyleProp<ViewStyle & TextStyle> => ({
  backgroundColor: colors.input.active
})
