export const transparentize = (col: string, opacity: number): string => {
  if (col.startsWith('#')) {
    let originalOpacity = 1
    if (col.length === 4) col = col + col.substring(1, 4)
    if (col.length === 9) originalOpacity = Number.parseInt(col.substring(7, 9), 16) / 255
    return `${col}${Math.floor(opacity * originalOpacity * 255).toString(16)}`
  }

  if (col.startsWith('rgba')) {
    const numRegex = /-?\d+(.\d+)?/g
    const numMatches = numRegex.exec(col)
    if (!numMatches) return col
    let nums = Array.from(numMatches)
    let originalOpacity = 1
    if (nums.length === 4) originalOpacity = Number.parseFloat(nums[3])
    return `rgba(${nums[0]},${nums[1]},${nums[2]},${originalOpacity * opacity})`
  }

  return col
}
