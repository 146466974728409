import { GestureResponderEvent } from 'react-native'
import { create } from 'zustand'

export type TouchStore = {
  listeners: ((e: GestureResponderEvent) => void)[]
  addListener: (listener: (e: GestureResponderEvent) => void) => void
  removeListener: (listener: (e: GestureResponderEvent) => void) => void
}

export const useTouchStore = create<TouchStore>((set) => ({
  listeners: [],
  addListener: (listener) => {
    set((prev) => ({
      ...prev,
      listeners: [...prev.listeners, listener]
    }))
  },
  removeListener: (listener) => {
    set((prev) => ({
      ...prev,
      listeners: prev.listeners.filter((currListener) => currListener !== listener)
    }))
  }
}))
