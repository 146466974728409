import { FieldType } from './types'

export class Field {
  public readonly id: string
  public readonly noteTypeId: string
  public ordinal: number
  public name: string
  public description: string
  public font: string
  public size: number
  public readonly createdAt: number
  public updatedAt: number

  constructor(data: FieldType) {
    this.id = data.id
    this.noteTypeId = data.note_type_id
    this.ordinal = data.ordinal
    this.name = data.name
    this.description = data.description
    this.font = data.font
    this.size = data.size
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  toDTO(): FieldType {
    return {
      id: this.id,
      note_type_id: this.noteTypeId,
      ordinal: this.ordinal,
      name: this.name,
      description: this.description,
      font: this.font,
      size: this.size,
      created_at: this.createdAt,
      updated_at: this.updatedAt
    }
  }
}
