import React from 'react'
import { GestureResponderEvent, View, ViewProps } from 'react-native'
import { useStyles } from '../../hooks'
import { useTouchStore } from '../../state/touch'
import { Component } from '../../types'
import * as _styles from './TouchProvider.styles'

export type TouchProviderProps = ViewProps & {}

const TouchProvider: Component<TouchProviderProps> = ({ ...others }) => {
  const styles = useStyles(_styles)

  const store = useTouchStore()

  const onPressAway = (e: GestureResponderEvent) => {
    store.listeners.forEach((listener) => listener(e))
  }

  return (
    <View
      {...others}
      style={[styles.container, { flex: 1 }, others.style]}
      onTouchEnd={onPressAway}
      // Add onClick for working with web
      // @ts-ignore
      onClick={onPressAway}
    >
      {others.children}
    </View>
  )
}

export default TouchProvider
