import * as React from 'react'
import Svg, { Mask, Circle, G, Path, SvgProps } from 'react-native-svg'
const SVGComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 512 512" {...props}>
    <Mask id="a">
      <Circle cx={256} cy={256} r={256} fill="#fff" />
    </Mask>
    <G mask="url(#a)">
      <Path fill="#eee" d="M0 0h512v512H0z" />
      <Circle cx={256} cy={256} r={111.3} fill="#d80027" />
    </G>
  </Svg>
)
export default SVGComponent
