import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
const SvgComponent = (props: SvgProps) => (
  <Svg
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    style={{
      overflow: 'visible'
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <Path d="m6 9 6 6 6-6" />
  </Svg>
)
export default SvgComponent
