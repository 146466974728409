import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { BaseFactory } from '../models'
import { DEFAULT_NEW_CARDS_PER_DAY } from './constants'
import { Config } from './models'

dayjs.extend(utc)
dayjs.extend(timezone)

export class ConfigFactory extends BaseFactory {
  static createDefault(): Config {
    return new Config({
      max_new_cards_per_day: DEFAULT_NEW_CARDS_PER_DAY,
      max_review_cards_per_day: null,
      timezone: dayjs.tz.guess(),
      ...this.createBase({})
    })
  }
}
