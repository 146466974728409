import { Kysely } from 'kysely'
import { DatabaseSchema14 } from '../types'

export async function upgradeTagsToSchema14(db: Kysely<DatabaseSchema14>): Promise<void> {
  const collection = await db.selectFrom('col').select('tags').executeTakeFirst()
  if (!collection) {
    throw new Error('Error fetching collection tags')
  }
  const tags: Record<string, number> = JSON.parse(collection.tags)
  for await (const [tag, usn] of Object.entries(tags)) {
    await db
      .insertInto('tags')
      .values({
        tag: tag,
        usn: usn
      })
      .ignore()
      .execute()
  }
  await db.updateTable('col').set({ tags: '' }).execute()
}
