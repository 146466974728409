import { AnkiFieldValue } from '../../anki/field-value/types'
import { BaseFactory } from '../models'
import { stringToSHA1EightDigitInteger } from '../utils'
import { AnkiFieldValueAdapter } from './adapters'
import { FieldValue } from './models'
import { CreateFieldValueType } from './types'

export class FieldValueFactory extends BaseFactory {
  static create(data: CreateFieldValueType): FieldValue {
    const checksum = stringToSHA1EightDigitInteger(data.value)
    return new FieldValue({ value_checksum: checksum, ...this.createBase(data) })
  }

  static createFromAnkiDeck(data: AnkiFieldValue): FieldValue {
    const adapter = new AnkiFieldValueAdapter(data)
    const adaptedData = adapter.adapt()
    return new FieldValue(this.createBase(adaptedData))
  }
}
