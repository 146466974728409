import { useEffect, useState } from 'react'
import { CardRenderData } from '../../../packages/langki/collection/types'
import { StudyQueue } from '../../../packages/langki/study-queue/models'
import { useTimeout } from '../useTimeout'
import { useLangkiStorage } from './useLangkiStorage'

export type StudyOptions = {
  deckId?: string
}

export type StudyResponse = {
  queue: StudyQueue | null
  fetching: boolean
  error: unknown
  currentCardRender: CardRenderData | null
  nextCardRender: CardRenderData | null
  markCorrect: () => void
  markWrong: () => void
  undo: () => void
  todaysStats: { totalReviews: number; timeTaken: number } | null
}

const getMsToMidnight = () => {
  const now = new Date()
  const then = new Date(now)
  then.setHours(24, 0, 0, 0)
  return then.getTime() - now.getTime()
}

export const useStudyQueue = (args?: StudyOptions): StudyResponse => {
  const { collection } = useLangkiStorage()
  const [queue, setQueue] = useState<StudyQueue | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const [currentCardRender, setCurrentCardRender] = useState<CardRenderData | null>(null)
  const [nextCardRender, setNextCardRender] = useState<CardRenderData | null>(null)
  const [currentCardStartTime, setCurrentCardStartTime] = useState(Date.now())
  const [todaysStats, setTodaysStats] = useState<{
    totalReviews: number
    timeTaken: number
  } | null>(null)
  const [allDeckIds, setAllDeckIds] = useState<string[]>()

  const getQueue = async () => {
    if (!collection) return
    try {
      setFetching(true)
      let res: StudyQueue
      if (args?.deckId) res = await collection.getStudyQueueForDeck(args.deckId)
      else res = await collection.getStudyQueueForAllDecks()
      setQueue(res)
    } catch (err) {
      setError(err)
    }
  }

  const getAllDeckIds = async () => {
    if (!collection) return
    const deckIds = await collection.storage.deck.getAllDeckIds()
    setAllDeckIds(deckIds)
  }

  const getCurrentCard = async (): Promise<CardRenderData | undefined> => {
    if (!queue) return
    let current = queue.getCurrentCard()
    if (!current) return
    const currentCardRender = await queue.getCardRenderData(current)
    return currentCardRender
  }

  const getNextCard = async (): Promise<CardRenderData | undefined> => {
    if (!queue) return
    const nextCard = queue.getNextCard()
    if (!nextCard) return
    const nextCardRender = await queue.getCardRenderData(nextCard)
    return nextCardRender
  }

  const getStats = async (): Promise<{ totalReviews: number; timeTaken: number } | undefined> => {
    if (!collection) return
    const stats = collection.getTodaysStats(args?.deckId ? [args.deckId] : allDeckIds ?? [])
    return stats
  }

  const getData = async (): Promise<
    [
      CardRenderData | undefined,
      CardRenderData | undefined,
      { totalReviews: number; timeTaken: number } | undefined
    ]
  > => {
    const res = await Promise.all([getCurrentCard(), getNextCard(), getStats()])
    return res
  }

  const setData = (
    data: [
      CardRenderData | undefined,
      CardRenderData | undefined,
      { totalReviews: number; timeTaken: number } | undefined
    ]
  ) => {
    setCurrentCardStartTime(Date.now())
    setCurrentCardRender(data[0] ?? null)
    setNextCardRender(data[1] ?? null)
    setTodaysStats(data[2] ?? null)
  }

  useEffect(() => {
    getQueue()
    getAllDeckIds()
  }, [args?.deckId, collection])

  useEffect(() => {
    if (!queue) return
    getData().then((data) => {
      setData(data)
      setFetching(false)
    })
  }, [queue])

  const duration = 100
  const markCorrect = async () => {
    if (!queue) return
    const timeTaken = Date.now() - currentCardStartTime
    await queue.correct(timeTaken)

    const data = await getData()
    await new Promise((resolve) => setTimeout(resolve, duration))
    await setData(data)
  }

  const markWrong = async () => {
    if (!queue) return
    await queue.incorrect(Date.now() - currentCardStartTime)

    const data = await getData()
    await new Promise((resolve) => setTimeout(resolve, duration))
    await setData(data)
  }

  const undo = async () => {
    if (!queue) return
    await queue.undo()

    const data = await getData()
    await new Promise((resolve) => setTimeout(resolve, duration))
    await setData(data)
  }

  useTimeout(
    () => {
      getData().then(setData)
    },
    getMsToMidnight(),
    { loop: true }
  )

  return {
    fetching,
    error,
    queue,
    currentCardRender,
    nextCardRender,
    markCorrect,
    markWrong,
    undo,
    todaysStats
  }
}
