import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import IntroSlider from '../../components/IntroSlider'
import Screen from '../../components/Screen'
import { useUserStore } from '../../state/user'
import { LoggedOutNavigationProp } from '../../types'

const AppIntro: React.FC = () => {
  const userState = useUserStore()
  const navigation = useNavigation<LoggedOutNavigationProp>()

  useEffect(() => {
    if (!userState.isNewUser) {
      setTimeout(() => {
        navigation.navigate('user')
      })
    }
  }, [userState.isNewUser])

  if (!userState.isNewUser) return <></>

  return (
    <Screen requiresLoggedIn>
      <IntroSlider />
    </Screen>
  )
}

export default AppIntro
