import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { Button, Input, Screen } from '../../components'
import DeckList from '../../components/DeckList'
import IconButton from '../../components/IconButton'
import { useDecks } from '../../hooks/storage'
import { useUserStore } from '../../state/user'

import StudyIcon from '../../svg/School'
import SearchIcon from '../../svg/Search'
import { LoggedInNavigationProp } from '../../types'

const DeckListScreen = () => {
  const userState = useUserStore()
  const navigation = useNavigation<LoggedInNavigationProp>()
  const { combinedDecks, mutateDecks, fetching } = useDecks()

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (userState.isNewUser) {
      setTimeout(() => {
        navigation.navigate('intro')
      })
    }
  }, [userState.isNewUser])

  const filteredDecks = useMemo(() => {
    return combinedDecks.filter((deck) => {
      if (deck.name.toLowerCase().includes(search.toLowerCase())) return true
      if (deck.description.toLowerCase().includes(search.toLowerCase())) return true
      return false
    })
  }, [combinedDecks, search])

  if (userState.isNewUser) return <></>

  return (
    <Screen requiresLoggedIn>
      <View style={{ padding: 16, flex: 1, gap: 16 }}>
        <Input
          placeholder="Search for a deck"
          value={search}
          onChangeText={(newText) => setSearch(newText)}
        >
          <IconButton icon={SearchIcon} />
        </Input>
        <DeckList
          combinedDecks={filteredDecks}
          onDelete={(deck) =>
            mutateDecks((oldDecks) => oldDecks.filter((currDeck) => currDeck.id !== deck.id))
          }
          loading={fetching}
        />
        <Button
          variant="icon_contained"
          icon={StudyIcon}
          size="large"
          onPress={() => navigation.navigate('study')}
          style={{ position: 'absolute', bottom: 12, right: 12 }}
        />
      </View>
    </Screen>
  )
}

export default DeckListScreen
