import React, { useEffect } from 'react'
import { Linking, View } from 'react-native'
import { Brand } from '../../components'

import { useInitialUrl } from '../../state/initialUrl'
import { useIsLoaded } from '../../state/loaded'
import { useUserStore } from '../../state/user'

import { api } from '../../util'
import { useRequest } from '../../util/api.util'

const Startup = () => {
  const initialUrlStore = useInitialUrl()
  const { user, setUser, tokens, logout } = useUserStore()
  const [getUser] = useRequest(api.getUser)
  const { setLoaded } = useIsLoaded()

  const init = async () => {
    const initialUrl = await Linking.getInitialURL()
    if (initialUrl) initialUrlStore.setInitialUrl(initialUrl)
    if (user && tokens) {
      try {
        const res = await getUser(user.id)
        setUser(res.data)
      } catch (err) {}
    } else logout()
    setLoaded(true)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Brand />
      {/* <ActivityIndicator size={'large'} style={{ paddingVertical: 40 }} /> */}
    </View>
  )
}

export default Startup
