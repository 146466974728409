// TODO: Add tests for preview cards and spaced_learning cards
import { Kysely } from 'kysely'
import { AnkiCard } from '../../../anki/card/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { CardFactory as LangkiCardFactory } from '../../card/factories'
import { Card } from '../../card/models'
import { LangkiStorage } from '../../storage/models'

export class CardConverter {
  private langkiDb: LangkiStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(langkiDb: LangkiStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.langkiDb = langkiDb
    this.ankiDb = ankiDb
  }

  async insert(langkiCardsBatch: Card[]) {
    await this.langkiDb.card.createMultiple(langkiCardsBatch)
  }

  private async processBatch(
    batch: AnkiCard[],
    schedulerInitDateSeconds: number,
    deckIdMap: Record<number, string>,
    noteIdMap: Record<number, string>
  ): Promise<Record<number, string>> {
    const cardIdMap: Record<number, string> = {}
    const langkiCardsBatch: Card[] = []
    for (const card of batch) {
      const deckId = deckIdMap[card.did]
      const noteId = noteIdMap[card.nid]
      const langkiCard = await LangkiCardFactory.createFromAnkiCard(
        card,
        deckId,
        noteId,
        schedulerInitDateSeconds
      )
      langkiCardsBatch.push(langkiCard)
      cardIdMap[card.id] = langkiCard.id
    }
    await this.insert(langkiCardsBatch)
    return cardIdMap
  }

  async convert(
    schedulerInitDateSeconds: number,
    deckIdMap: Record<number, string>,
    noteIdMap: Record<number, string>
  ): Promise<Record<number, string>> {
    const batchSize = 500
    const ankiCards = await this.ankiDb.selectFrom('cards').selectAll().execute()
    let cardIdMap: Record<number, string> = {}
    for (let i = 0; i < ankiCards.length; i += batchSize) {
      const batch = ankiCards.slice(i, i + batchSize)
      cardIdMap = {
        ...cardIdMap,
        ...(await this.processBatch(batch, schedulerInitDateSeconds, deckIdMap, noteIdMap))
      }
    }
    return cardIdMap
  }
}
