import * as React from 'react'
import Svg, { SvgProps, Rect } from 'react-native-svg'
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 21.167 21.167" {...props}>
    <Rect width={21.167} height={21.167} fill="#666" fillRule="evenodd" rx={2.646} ry={2.646} />
    <Rect width={21.167} height={4.233} fill="#666" rx={0.529} ry={0.529} />
    <Rect
      width={15.875}
      height={15.875}
      x={2.646}
      y={2.646}
      fill="#D9C19D"
      fillRule="evenodd"
      rx={1.984}
      ry={1.984}
    />
    <Rect width={15.875} height={3.175} x={2.646} y={2.646} fill="#D9C19D" rx={0.529} ry={0.529} />
  </Svg>
)
export default SvgComponent
