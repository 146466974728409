import { useNavigation } from '@react-navigation/native'
import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { getFilesystemHandler } from '../../../packages/interfaces/filesystem/generic'
import {
  GenericProgressHandler,
  ProgressHandlerTextReturn
} from '../../../packages/interfaces/progress-handler/generic'
import { getDbHandler } from '../../../packages/interfaces/sqlite/generic'
import { getZipHandler } from '../../../packages/interfaces/zip-handler/generic'
import { convertAnkiDeck } from '../../../packages/langki/conversion/convert'
import { getStorageInstance } from '../../../packages/langki/storage/models'
import Button from '../../components/Button'
import ProgressBar from '../../components/ProgressBar'
import Screen from '../../components/Screen'
import { DB_NAME, MEDIA_FOLDER } from '../../constants'
import { useStyles } from '../../hooks'
import { useUserStore } from '../../state/user'
import { LoggedOutNavigationProp } from '../../types'
import * as _styles from './ImportDeckScreen.styles'

const steps = [
  {
    title: 'steps.1.title',
    info: 'steps.1.description'
  },
  {
    title: 'steps.2.title',
    info: 'steps.2.description'
  }
] as const

const ImportDeckScreen: React.FC = () => {
  const styles = useStyles(_styles)
  const [fileLoading, setFileLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [progressText, setProgressText] = useState('')
  const toast = useToast()
  const { setIsNewUser } = useUserStore()
  const navigation = useNavigation<LoggedOutNavigationProp>()
  const [t] = useTranslation(['import', 'common', 'progress'])

  useLayoutEffect(() => {
    setProgress(0)
  }, [loading])

  const onProgressChange = (progressFrac: number) => {
    setProgress(progressFrac)
  }

  const onTextChange = (textObj: ProgressHandlerTextReturn) => {
    setProgressText(
      textObj.code ? t(('progress:' + textObj.code) as any) : 'NO TEXT' /*textObj.text*/
    )
  }

  const importDeck = async () => {
    try {
      setFileLoading(true)
      const fsHandler = await getFilesystemHandler()
      setFileLoading(false)
      fsHandler.pickFile(async (err, file) => {
        setLoading(true)
        try {
          if (err) return toast.show(t('error.error-importing'))
          if (!file) return toast.show(t('error.no-file'))

          const progressHandler = new GenericProgressHandler()
          progressHandler.onProgressChange(onProgressChange)
          progressHandler.onTextChange(onTextChange)

          const handlers = {
            dbHandler: await getDbHandler(),
            fsHandler: fsHandler,
            zipHandler: await getZipHandler(),
            progressHandler: progressHandler
          }
          const storage = await getStorageInstance(DB_NAME, handlers.dbHandler)
          try {
            await convertAnkiDeck(file.path, storage, MEDIA_FOLDER, handlers)
            setIsNewUser(false)
            toast.show(t('success.import'), { type: 'success' })
            navigation.popToTop()
            navigation.replace('user')
          } catch (err) {
            console.error(err)
            toast.show(t('error.error-importing'), { type: 'danger' })
          }
          await handlers.fsHandler.delete(file.path).catch(() => {})
        } catch (err) {
          toast.show(t('error.error-importing'), { type: 'danger' })
        }
        setLoading(false)
      })
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  return (
    <Screen requiresLoggedIn>
      <View style={styles.page}>
        {steps.map((step, i) => (
          <View style={styles.stepContainer} key={i}>
            <Text style={styles.title}>
              <Text style={styles.bold}>{t('import:labels.step', { step: i + 1 })}: </Text>
              <Text>{t(step.title)}</Text>
            </Text>
            <Text style={styles.textInfo}>{t(step.info)}</Text>
          </View>
        ))}
        <Button loading={loading || fileLoading} onPress={importDeck}>
          {t('buttons.upload-file')}
        </Button>
        <View style={[styles.progressContainer, { opacity: loading ? 1 : 0 }]}>
          <ProgressBar progress={progress} />
          <Text style={styles.progressText}>{progressText}</Text>
        </View>
      </View>
    </Screen>
  )
}

export default ImportDeckScreen
