/// <reference lib="dom" />
import { useEffect } from 'react'
import { Platform } from 'react-native'

export const useCSSVariable = (varName: string, value: string): void => {
  useEffect(() => {
    if (Platform.OS !== 'web') return
    window.document.documentElement.style.setProperty(`--${varName}`, value)
    return () => {
      window.document.documentElement.style.removeProperty(`--${varName}`)
    }
  }, [varName, value])
}
