import React, { useCallback, useEffect } from 'react'
import { Text, Alert, Platform, View } from 'react-native'
import { useStyles } from '../../hooks'
import { Component } from '../../types'
import Button from '../Button'
import Modal, { ModalProps } from '../Modal'
import * as _styles from './ConfirmationModal.styles'

export type ConfirmationModalProps = ModalProps & {
  confirmationText: string
  buttonText: string
  loading?: boolean
  onConfirm: () => void
}

const ConfirmationModal: Component<ConfirmationModalProps> = ({
  confirmationText,
  buttonText,
  loading = false,
  onConfirm,
  ...others
}) => {
  const styles = useStyles(_styles)

  const alert = useCallback(async () => {
    if (!others.open || Platform.OS === 'web') return
    Alert.alert(confirmationText, confirmationText, [
      { isPreferred: false, onPress: others.onClose, text: 'Cancel', style: 'cancel' },
      {
        isPreferred: false,
        onPress: () => {
          others.onClose()
          onConfirm()
        },
        text: buttonText
      }
    ])
  }, [others.open, others.onClose])

  useEffect(() => {
    alert()
  }, [alert])

  if (Platform.OS !== 'web') return <></>
  return (
    <Modal {...others} style={[styles.modal, others.style]} title="Confirmation">
      <Text style={styles.text}>{confirmationText}</Text>

      <View style={styles.buttonContainer}>
        <Button
          color="error"
          size="compact"
          loading={loading}
          onPress={onConfirm}
          style={styles.button}
        >
          {buttonText}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="compact"
          loading={loading}
          onPress={others.onClose}
          style={styles.button}
        >
          Cancel
        </Button>
      </View>
    </Modal>
  )
}

export default ConfirmationModal
