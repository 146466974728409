import { Kysely } from 'kysely'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'

export class CollectionConverter {
  private ankiDb: Kysely<AnkiDatabase>

  constructor(ankiDb: Kysely<AnkiDatabase>) {
    this.ankiDb = ankiDb
  }

  async convert(): Promise<number> {
    const ankiCollection = await this.ankiDb.selectFrom('col').selectAll().executeTakeFirst()
    if (!ankiCollection) {
      throw new Error('Collection not found.')
    }
    const schedulerInitDateSeconds = ankiCollection.crt
    return schedulerInitDateSeconds
  }
}
