import { URL } from 'react-native-url-polyfill'
import { create } from 'zustand'

export type OAuthStore = {
  initialUrl: URL | null
  setInitialUrl: (newUrl: string) => void
}

export const useInitialUrl = create<OAuthStore>((set) => ({
  initialUrl: null,
  setInitialUrl: (newUrl: string) => {
    set({ initialUrl: new URL(newUrl) })
  }
}))
