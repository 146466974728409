import { Kysely } from 'kysely'
import { Template } from '../../template/models'
import { Database } from '../types'

export class TemplateStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async create(template: Template): Promise<void> {
    const results = await this.db.insertInto('template').values(template.toDTO()).executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert template.')
    }
  }

  async createMultiple(templates: Template[]): Promise<void> {
    const results = await this.db
      .insertInto('template')
      .values(templates.map((template) => template.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert templates.')
    }
  }
}
