import { Kysely, Transaction } from 'kysely'
import { DeckConfig } from '../../deck-config/models'
import { booleanToNumber } from '../../utils'
import { Database } from '../types'

export class DeckConfigStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async get(id: string): Promise<DeckConfig> {
    const config = await this.db
      .selectFrom('deck_config')
      .selectAll()
      .where('deck_config.id', '=', id)
      .executeTakeFirst()
    if (!config) {
      throw new Error(`Could not find deck config with id ${id}.`)
    }
    return new DeckConfig(config)
  }

  async update(deckConfig: DeckConfig): Promise<void> {
    const results = await this.db
      .updateTable('deck_config')
      .set(deckConfig.toDTO())
      .where('id', '=', deckConfig.id)
      .executeTakeFirst()
    if (Number(results.numUpdatedRows) === 0) {
      throw new Error('Could not update deck config')
    }
  }

  async getMultiple(ids: string[]): Promise<DeckConfig[]> {
    const configs = await this.db
      .selectFrom('deck_config')
      .selectAll()
      .where('deck_config.id', 'in', ids)
      .execute()
    return configs.map((config) => new DeckConfig(config))
  }

  async getActive(): Promise<DeckConfig[]> {
    const configs = await this.db
      .selectFrom('deck_config')
      .selectAll()
      .where('deck_config.active', '=', booleanToNumber(true))
      .execute()
    return configs.map((config) => new DeckConfig(config))
  }

  async create(deckConfig: DeckConfig, trx: Transaction<Database>): Promise<void> {
    const results = await trx
      .insertInto('deck_config')
      .values(deckConfig.toDTO())
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert deck config.')
    }
  }

  async createMultiple(deckConfigs: DeckConfig[], trx: Transaction<Database>): Promise<void> {
    const results = await trx
      .insertInto('deck_config')
      .values(deckConfigs.map((c) => c.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert deck configs.')
    }
  }
}
