import { useEffect } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useTouchStore } from '../state/touch'

export const usePressAway = (listener: (e: GestureResponderEvent) => void) => {
  const touchStore = useTouchStore()

  useEffect(() => {
    touchStore.addListener(listener)
    return () => touchStore.removeListener(listener)
  }, [listener])
}
