import { Selectable } from 'kysely'
import { CardTable } from '../storage/card/tables'

export type CardType = Selectable<CardTable>
export type CreateCardType = Omit<CardType, 'id' | 'created_at' | 'updated_at'>
export type CreateAnkiCardType = Omit<CardType, 'id'>

export enum CardQueue {
  New = 0,
  Learning = 1,
  Review = 2,
  Relearning = 3
}
