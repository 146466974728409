import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export class TimestampMilliseconds {
  static epochSecondsToMilliseconds(epochSeconds: number) {
    return epochSeconds * 1000
  }

  static now() {
    return dayjs().valueOf()
  }
}

export class TimestampSeconds {
  static now() {
    return dayjs.utc().unix()
  }
}
