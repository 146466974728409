import React from 'react'
import { View, ViewProps } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { useStyles } from '../../hooks'
import * as _styles from './ProgressBar.styles'

export type ProgressBarProps = ViewProps & {
  progress: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress = 0, ...others }) => {
  const styles = useStyles(_styles)

  return (
    <View {...others} style={[styles.container, others.style]}>
      <Animatable.View
        transition={'flex'}
        easing={'ease-in-out'}
        duration={200}
        style={[styles.bar, { flex: Math.min(Math.max(progress, 0), 1) }]}
      />
    </View>
  )
}

export default ProgressBar
