import { ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const loadable_text = ({}: Theme): ViewStyle => ({
  borderRadius: 8
})

export const paragraph = ({}: Theme): ViewStyle => ({
  flexDirection: 'row',
  columnGap: 6,
  rowGap: 4,
  flexWrap: 'wrap'
})
