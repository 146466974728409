import { Platform } from 'react-native'
import { SQLiteDatabaseHandler } from './types'

export const getDbHandler = async (): Promise<SQLiteDatabaseHandler> => {
  if (Platform.OS === 'web') {
    const { WebSQLiteDatabaseHandler } = await import('./web')
    return new WebSQLiteDatabaseHandler()
  } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
    const { reactNativeDbHandler } = await import('./react-native')
    return reactNativeDbHandler
  } else {
    const { betterSqliteDbHandler } = await import('./node')
    return betterSqliteDbHandler
  }
}
