import { Kysely } from 'kysely'
import { Note } from '../../note/models'
import { Database } from '../types'

export class NoteStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async get(id: string, executor: Kysely<Database> = this.db): Promise<Note> {
    const row = await executor
      .selectFrom('note')
      .selectAll()
      .where('note.id', '=', id)
      .executeTakeFirstOrThrow()
    if (!row) {
      throw new Error('Note not found')
    }
    return new Note(row)
  }

  async create(note: Note): Promise<void> {
    const results = await this.db.insertInto('note').values(note.toDTO()).executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert note.')
    }
  }

  async createMultiple(notes: Note[]): Promise<void> {
    const results = await this.db
      .insertInto('note')
      .values(notes.map((n) => n.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert notes.')
    }
  }
}
