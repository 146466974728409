import { Kysely, Migrator } from 'kysely'
import { SQLiteDatabaseHandler } from '../../interfaces/sqlite/types'
import { SqliteDialect } from '../../kysely-sqlite-generic'
import migrations from './migrations'
import { Database } from './types'

export const openOrCreateCollectionDb = async (
  dbName: string,
  databaseHandler: SQLiteDatabaseHandler
): Promise<Kysely<Database>> => {
  const sqliteDatabase = await databaseHandler.create(dbName)
  await sqliteDatabase.executeSql('PRAGMA journal_mode = WAL')
  await sqliteDatabase.executeSql('PRAGMA legacy_file_format = false')
  // await sqliteDatabase.executeSql('PRAGMA locking_mode = exclusive')
  await sqliteDatabase.executeSql('PRAGMA page_size = 4096')
  await sqliteDatabase.executeSql(`PRAGMA cache_size = ${-40 * 1024}`)
  if (process.platform === 'android') {
    await sqliteDatabase.executeSql('PRAGMA temp_store = memory')
  }
  const kyselyWrapper = new Kysely<Database>({
    dialect: new SqliteDialect({
      database: sqliteDatabase
    })
  })
  return kyselyWrapper
}

export const migrate = async (database: Kysely<Database>) => {
  const migrator = new Migrator({
    db: database,
    provider: {
      getMigrations: async () => migrations
    }
  })
  const { results } = await migrator.migrateToLatest()
  results?.forEach((it) => {
    if (it.status === 'Error') {
      throw new Error(`Failed to execute migration ${it.migrationName}`)
    }
  })
}
