/**
 * Intersperser class to evenly mix elements from two arrays.
 * It interleaves elements from the two provided arrays based on their relative lengths.
 */
export function* intersperse<T>(arrayOne: T[], arrayTwo: T[]): Generator<T, void, undefined> {
  const lengthOne = arrayOne.length
  const lengthTwo = arrayTwo.length
  const mixRatio = (lengthOne + 1) / (lengthTwo + 1)
  let indexOne = 0
  let indexTwo = 0

  while (indexOne < lengthOne || indexTwo < lengthTwo) {
    const relativeindexTwo = (indexTwo + 1) * mixRatio

    if (relativeindexTwo < indexOne + 1 && indexTwo < lengthTwo) {
      yield arrayTwo[indexTwo++]
    } else if (indexOne < lengthOne) {
      yield arrayOne[indexOne++]
    }
  }
}
