import { BaseFactory } from '../models'
import { booleanToNumber } from '../utils'
import { DeckConfig } from './models'
import { CreateDeckConfigType } from './types'

export class DeckConfigFactory extends BaseFactory {
  static createDefault(): DeckConfig {
    const defaultData = {
      active: booleanToNumber(true),
      max_new_cards_per_day: null,
      max_review_cards_per_day: null
    }
    return this.create(defaultData)
  }
  static create(data: CreateDeckConfigType): DeckConfig {
    return new DeckConfig(this.createBase(data))
  }
}
