import { AnkiFieldValue } from '../../anki/field-value/types'
import { stringToSHA1EightDigitInteger } from '../utils'
import { CreateAnkiFieldValueType } from './types'

export class AnkiFieldValueAdapter {
  private ankiFieldValue: AnkiFieldValue

  constructor(ankiFieldValue: AnkiFieldValue) {
    this.ankiFieldValue = ankiFieldValue
  }

  public adapt(): CreateAnkiFieldValueType {
    return {
      value_checksum: stringToSHA1EightDigitInteger(this.ankiFieldValue.value),
      note_id: this.ankiFieldValue.note_id,
      field_id: this.ankiFieldValue.field_id,
      value: this.ankiFieldValue.value
    }
  }
}
