import { Kysely } from 'kysely'
import { AnkiField } from '../../../anki/field/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { FieldFactory as LangkiFieldFactory } from '../../field/factories'
import { Field } from '../../field/models'
import { LangkiStorage } from '../../storage/models'

export class FieldConverter {
  private langkiDb: LangkiStorage
  private ankiDb: Kysely<AnkiDatabase>
  private noteTypeOrdMap: Record<string, Record<number, string>>

  constructor(langkiDb: LangkiStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.langkiDb = langkiDb
    this.ankiDb = ankiDb
    this.noteTypeOrdMap = {}
  }

  async insert(langkiFieldsBatch: Field[]) {
    await this.langkiDb.field.createMultiple(langkiFieldsBatch)
  }

  private async processBatch(
    batch: AnkiField[],
    noteTypeIdMap: Record<number, string>
  ): Promise<void> {
    const langkiFieldsBatch: Field[] = []
    for (const field of batch) {
      const langkiField = LangkiFieldFactory.createFromAnkiDeck(field, noteTypeIdMap)
      if (!this.noteTypeOrdMap[langkiField.noteTypeId]) {
        this.noteTypeOrdMap[langkiField.noteTypeId] = {}
      }
      this.noteTypeOrdMap[langkiField.noteTypeId][field.ord] = langkiField.id
      langkiFieldsBatch.push(langkiField)
    }
    await this.insert(langkiFieldsBatch)
  }

  async convert(
    noteTypeIdMap: Record<number, string>
  ): Promise<Record<string, Record<number, string>>> {
    const batchSize = 500
    const ankiFields = await this.ankiDb.selectFrom('fields').selectAll().execute()
    for (let i = 0; i < ankiFields.length; i += batchSize) {
      const batch = ankiFields.slice(i, i + batchSize)
      await this.processBatch(batch, noteTypeIdMap)
    }
    return this.noteTypeOrdMap
  }
}
